@import (reference) "_global.less";

.empari {
     
    .float_menu {
        position: absolute;
        margin-left: 249px;
        padding: 0;
        overflow: visible;
        width: 123px;
        z-index: 50;

        .float_menu_header_shadow {
            margin: 0;
            padding: 0;
            margin-top: 1px;
            overflow: visible;
            position: relative;
        }

        .float_menu_header {
            margin: 0 0 0 0;
            padding: 0;
            border: 1px solid @COLOR-TNL-GRAY1;
        }

        .float_menu_header .float_menu_mask {
            border-left: 0;
            margin: 0;
            float: right;
        }

        .float_menu_header .float_menu_title {
            color: @COLOR-TNL-GRAY1;
            text-align: left;
            float: right;
            background-color: #fff;
        }

        .float_menu_content_shadow {
            float: left;
            clear: left;
            margin: 0;
            padding: 0;
            position: relative;
            margin-top: -1px;
        }

    }

    .float_menu_left {
        
        .float_menu_content_shadow {
            float: right;
        }

        .float_menu_header .float_menu_mask {
            float: left;
        }

    }

    .float_menu_right {

        .float_menu_content_shadow {
            float: left;
        }

        .float_menu_header .float_menu_mask {
            float: right;
        }

    }

    .float_menu_18 {
        width: 123px;

        .float_menu_header .float_menu_title {
            padding: 5px 15px 1px 1px;
            height: 18px;
            width: 81px;
        }

        .float_menu_header .float_menu_mask {
            width: 18px;
            height: 18px;
        }

        .float_menu_content {
            width: 100px;
        }
    }
    
    .float_menu_manage_ab {
        margin-top: 48px;
        margin-left: 82px;
    }
   
    .float_menu_browse_questions {
        margin-top: 190px;
        margin-left: 248px;
    }

    .float_menu_manage_project {
        margin-top: 238px;
        margin-left: 254px;
    }

    .float_menu_content {
        width: 90px;
        padding: 0;
        border: 1px solid @COLOR-TNL-GRAY1;
        border-top: 0;
        background: @COLOR-TNL-GRAY5;

        label {
            font-weight: normal;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            border-top: 1px solid @COLOR-TNL-GRAY4;
        }

        li {
            margin: 0;
            border: 0;
        }

        li a {
            padding: 5px 10px 5px 5px;
            display: block;
            background-color: @COLOR-TNL-GRAY5;
            color: @COLOR-TNL-GRAY1;
        }

        li a:hover {
            color: #fff;
            background-color: @COLOR-TNL-GRAY1;
            text-decoration: none;
        }

        li .delete-img {
            background: transparent url(/images/x_to_hide.gif) no-repeat right;
        }

        li .delete-img:hover {
            background: @COLOR-TNL-GRAY1 url(/images/x_to_hide_hover.gif) no-repeat right;
        }
    }

}