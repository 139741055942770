@import (reference) "_global.less";

.empari {

    .tnl-picker-selections > div, .tnl-picker-selector > div,
    .tnl-picker-shortcuts > div {
        padding: 10px 25px 10px 0;
    }

    .tnl-picker-selections-head h1,
    .tnl-picker-selector-head h1,
    .tnl-picker-shortcuts-head h1 {
        font-size: 16px;
    }

    .tnl-picker-selections-section-head h1,
    .tnl-picker-selector-section-head h1,
    .tnl-picker-shortcuts-section-head h1 {
        font-size: 14px;
    }

    .tnl-picker-selector-section {
        margin: 0;
    }

    .tnl-picker-selections > div {
        padding-left: 25px;
        border-left: 1px solid @COLOR-TNL-GRAY4;
    }

    .tnl-picker-selections-btn {
        margin: 25px 0;
        text-align: center;
    }

    .tnl-picker-selections-btn a {
        margin-right: 10px;
    }

}