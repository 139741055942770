@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari .tnl-my-pd {

    .course {
        border-bottom: 1px solid @COLOR-TNL-GRAY4;
        padding: 20px;
        
        .tnl-course-number,
        .tnl-course-title {
            font-size: 16px;
            .COS-OPEN-SANS-600();
        }
        
        .tnl-course-details {
            margin-top: 1em;
            
            span {
                .COS-OPEN-SANS-600();
            }
        }
            
       .tnl-viewsurvey {
            margin-top: 1em;
        }

        .tnl-certification {
            text-align: right;
            
            a {
                font-weight: 700;
            }
        }

        .tnl-course-credits,
        .tnl-course-grade {  
            margin-top: 1em;
            text-align: right;
            
            span {
                font-weight: 700;
            }
        }
    }
        
}