/* TODO: For simplicity, refactor out the use of scratch.css */
/* Do not add CSS rules to this file. */
 
/* Rubric table */

table.rubric {
    width: 100%;
}

table.rubric > tbody > tr > td,
table.rubric > tbody > tr > th,
table.rubric > thead > tr > th {
    border-width: 1px;
    border-style: solid;
    text-align: left;
    vertical-align: top;
}

table.rubric > tbody > tr > td > div.tnl-clickable {
    text-align: left;
}

.competency-depth-1 {
    font-size: 18px;
    padding-left: 10px;
}

.competency-depth-2 {
    font-size: 16px;
    padding-left: 31px;
}

.competency-depth-3 {
    font-size: 14px;
    padding-left: 52px;
}

/* Reorder controls */

ol.tnl-reorder-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ol.tnl-reorder-list > li {
    margin: 6px;
    padding: 10px 0 10px 10px;
    cursor: move;
}

ol.tnl-reorder-list > li > i {
    padding-right: 10px;
}


/* Dialog Message */

div.tnl-dialog-message {
    margin-bottom: 1em;
    margin-top: 1em;
}

/* DataTable Display Template */

table.tnl-datatable {
    border-collapse: collapse;
    margin-top: 10px;
    border: 1px solid #000;
}

table.tnl-datatable th {
    white-space: nowrap;
    border: 1px solid #000;
}

table.tnl-datatable td {
    vertical-align: top;
    white-space: pre-wrap;
    border: 1px solid #000;
}

/* Answer History */

.tnl-answer-history.tnl-answer-hist-single .tnl-hist-before {
    width: 49%;
    float: left;
}

.tnl-answer-history.tnl-answer-hist-single .tnl-hist-after {
    width: 49%;
    float: right;
}

.tnl-answer-history .tnl-hist-before td.tnl-highlight,
.tnl-answer-history .tnl-hist-before td.tnl-highlight div {
    background-color: lightgray;
}

.tnl-answer-history .tnl-hist-after td.tnl-highlight,
.tnl-answer-history .tnl-hist-after td.tnl-highlight div {
    background-color: yellow;
}

.tnl-answer-history .tnl-hist-before td div,
.tnl-answer-history .tnl-hist-after td div {
    border: 0;
}

.tnl-answer-history div.tnl-history-rev-selection {
    text-align: center;
    padding-bottom: 10px;
    white-space: nowrap;
}

.tnl-answer-history div.tnl-hist-disclaimer {
    clear: both;
    padding-top: 10px;
}

.tnl-answer-history h2 {
    text-align: center;
}

.tnl-answer-history .tnl-row2 {
    background-color: white;
}

.tnl-answer-history td.tnl-form-label {
    white-space: nowrap;
}

/* Help */

.help-dialog.document-content > div {
    display: table-cell;
    padding-right: 10px;
}

.tnl-help-edit {
    display: table;
    min-width: 320px;
}

.tnl-help-edit-section {
    border: #ccc;
    margin-bottom: 7px;
}

.tnl-help-edit-section > div {
    padding: 7px 0;
    display: table-cell;
    white-space: nowrap;
}

.tnl-help-edit-title {
    width: 170px;
    max-width: 170px;
    overflow: hidden;
}


/* PictureAdjuster */

.tnl-picture-adjuster .preview-pane .preview-container {
    width: 56px;
    height: 56px;
    overflow: hidden;
    float: right;
}

.tnl-picture-adjuster .jcrop-holder .preview-pane {
    display: block;
    position: absolute;
    padding: 6px;
    border: 1px grey solid;
    background-color: white;
    margin-left: -77px;
}

.tnl-picture-adjuster .jcrop-holder {
    margin-left: 70px;
}

/* Display Tab Management */

.displayTabSelection {
    margin-top: 10px;
}

.displayTabSelection > nav {
    display: inline;
}

.sortDisplayTabs {
    margin: 0;
    padding: 0;
}

.sortDisplayTabs li {
    margin: 6px;
    padding: 6px;
    font-size: 11px;
    cursor: move;
}