@import (reference) "_global.less";

.empari {

    .tnl-focused-icon-lg {
        display: inline-block;
        width: 16px;
        border: 2px solid @COLOR-DANGER;
        background: #FFF3BB;
        margin-right: 5px;
        padding: 1px;
        font-size: 14px;
        text-align: center;
        font-weight: bold;
    }

    .tnl-focused-icon-sm {
        float: right;
        font-weight: normal;
        margin-right: 5px;
        padding: 0;
        font-size: 13px;
        text-align: center;
        width: 16px;
        border: 1px solid @COLOR-DANGER;
        background: #FFF3BB;
        .LESS-ROUNDED-CORNERS();
    }

    .td-tnl-pre-note-block {
        padding: 10px;
        vertical-align: top;
        width: 60%;
        border-right: 1px dotted #ccc;
    }

    .cos-obs-note-block,
    .tnl-pre-note-block,
    .tnl-tagged-note {
        font-size: @COS-FONT-SIZE-DEFAULT;
        -ms-word-wrap: break-word;
        word-wrap: break-word;

        ul, ol {
            margin: 0 0 10px 17px;
        }

        ul > li,
        ol > li {
            list-style-position: inside;
            float: none;
        }

        ul > li {
            list-style-type: initial;
        }

        ol > li {
            list-style-type: decimal;
        }
    }

    .tnl-obv-box {
        margin-top: 0;
        margin-bottom: 15px;
        padding: 14px 5px 5px 5px;
        background-color: #fff;

        /*form > table > tbody > tr {
            td {
                vertical-align: top;
            }
        }*/
    }

    .tnl-note-tag-ul {
        margin: 0 0 0 5px;
    }

    .tnl-note-tag-ul li {
        margin-left: 5px;
        padding-left: 3px;
        padding-top: 3px;
        font-size: 14px;
        float: none !important;
    }

    .tnl-note-note-section {
        width: 65%;
        padding: 5px;
        font-size: 14px;
        vertical-align: top;
    }

    .tnl-note-tag-section {
        width: 35%;
        padding: 5px;
        border-right: 1px dotted @COLOR-TNL-GRAY4;
    }

    .tnl-note-added {
        background: @COLOR-TNL-GRAY6;
        color: @COLOR-TNL-GRAY4;
    }

    .tnl-add-note-body {
        background: #fff;
    }

    .tnl-add-note-hdr {
        background: @COLOR-TNL-GRAY4;
    }

    .tnl-list-note-div {
        padding: 15px 10px;
        border-bottom: 1px solid @COLOR-TNL-GRAY4;
        background: #fff;
    }

    .tnl-list-note-div {
        .tnl-pre-note-block {

            ul > li,
            ol > li {
                list-style-position: outside;
                margin-left: 18px;
            }

            ul > li {
                list-style-type: initial;
            }

            ol > li {
                list-style-type: decimal;
            }
        }
    }

    .tnl-deselect-all-add-tag-btn {
        float: right;
    }

    .tnl-note-new-note-div {
        display: inline;
    }

    .tnl-note-remove {
        float: left;
        margin-left: -2px;
    }

    .tnl-note-dropped {
        background:  @COLOR-TNL-GRAY6;
        border: 1px solid @COLOR-TNL-GRAY4;
        margin-bottom: 3px;
        padding: 2px;
    }

    .tnl-split-note-added {
        border: 1px solid @COLOR-TNL-GRAY4;
        background-color:  @COLOR-TNL-GRAY6;
        color: @COLOR-TNL-GRAY2;
    }

    .tnl-note-text-div {
        border: 1px dashed @COLOR-TNL-GRAY4;
        padding: 5px;
        cursor: move;
        margin: 5px 0;
    }

    .tnl-note-text-div-hot {
        background:  @COLOR-TNL-GRAY6;
    }

    .tnl-note-text-div-hover {
        background: none repeat scroll 0 0 @COLOR-TNL-GRAY4 !important;
    }

    .tnl-view-notes-panel {
        background-color:  @COLOR-TNL-GRAY6;
        overflow: auto;
        padding: 10px;
    }

    #tnl-notes-library-tabs {
        border: 0 none;

        li {
            float: right;
        }

        ul {
            background: none repeat scroll 0 0 transparent;
            border: 0 none;
            .LESS-ROUNDED-CORNERS-RESET();
        }

        > .ui-tabs-panel {
            border: 1px solid @COLOR-TNL-BLUE2;
            .LESS-ROUNDED-CORNERS();
        }

        li a {
            padding: 4px 8px;
        }

    }

    .tnl-summary-notes-div {
        display: block;
        margin-left: 23px;
        padding: 2px 10px;
    }

    .tnl-quick-tag-panel {
        background-color: #F2F2F2;
        border: 1px solid #CC0000;
        display: none;
        padding: 10px;
        position: absolute;
        right: 25px;
        width: 550px;
        z-index: 100;
    }

    .tnl-tag-draggable {
        cursor: move;
        padding: 5px;
        min-height: 16px;
    }

    .tnl-tag-draggable > .drag-text {
        vertical-align: top;
    }

    .tnl-tag-draggable-hover {
        background-color: #FFE36F !important;
    }

    .tnl-tag-draggable-hot {
        background-color: #FFE36F;
        color: #000;
    }

    .tnl-tag-droppable {
        border: 1px solid  @COLOR-TNL-GRAY4;
        .LESS-ROUNDED-CORNERS();
        padding: 5px;
        min-height: 20px;
        margin-top: 10px;
    }

    .tnl-tag-add-active {
        background-color:  @COLOR-TNL-GRAY6;
        border: 2px solid #c00;
    }

    .tnl-tag-droppable-hot {
        background-color:  @COLOR-TNL-GRAY6;
        border: 1px solid @COLOR-TNL-BLUE1;
    }

    .tnl-tag-droppable-hover {
        border: 1px solid #FFE36F;
    }

    .tnl-note-droppable-hot {
        border: 1px solid @COLOR-TNL-BLUE1;
        padding: 4px 5px 5px 5px;
        margin: 0 -1px -1px -1px;
    }

    .tnl-note-droppable-hover {
        background-color: @COLOR-TNL-GRAY6 !important;
    }

    .tnl-tag-dropped {
        background-color: @COLOR-TNL-GRAY6;
        padding: 3px 0;
    }

    .tnl-tag-remove {
        float: left;
        position: relative;
        z-index: 1;
        padding: 0 4px;
        cursor: pointer;
    }

    .tnl-add-tag-link-wrapper {
        display: inline-block;
    }

    .tnl-add-tag-link {
        display: none;
        cursor: pointer;
    }

    .tnl-drag-note {
        float: right;
        padding: 3px;
        cursor: move;
    }

    .tnl-section-heading {
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        padding: 5px;
    }

    .tnl-tag-table-test {
        border-collapse: collapse;

        td {
            text-align: center;
            vertical-align: top;
        }

    }

    .tnl-tag-table {
        border-collapse: collapse;

        td {
            padding: 3px;
            border: 1px solid #000;
            text-align: center;
            vertical-align: top;
        }

    }

    .tnl-question-tag-empty {
        background: none repeat scroll 0 0 @COLOR-TNL-GRAY4;
    }

    .tnl-question-tag {
        white-space: nowrap;
    }

    .tnl-standard-tag {
        font-weight: bold;
        text-align: left !important;
        white-space: nowrap;
    }

    .tnl-tag-hover {
        background: none repeat scroll 0 0 #FFE36F !important;
    }

    .tnl-tag-filter-sort-btn {
        -moz-border-bottom-colors: none;
        -moz-border-image: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        background: none repeat scroll 0 0 #F7F7F7;
        border-color: @COLOR-TNL-GRAY1 -moz-use-text-color;
        border-style: solid solid none;
        border-width: 1px 1px 0;
        cursor: pointer;
        display: inline-block;
        margin-bottom: -1px;
        margin-right: -2px;
        padding: 2px 5px 7px;
        position: relative;
        z-index: 110;
    }

    .tnl-tag-filter-sort-btn-on {
        -moz-border-bottom-colors: none;
        -moz-border-image: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        background: none repeat scroll 0 0 @COLOR-TNL-GRAY2;
        border-color: @COLOR-TNL-GRAY6 -moz-use-text-color;
        border-style: solid solid none;
        border-width: 1px 1px 0;
        color: #FFFFFF;
        display: inline-block;
        padding: 2px 5px 7px;
        position: relative;
        z-index: 110;
    }

    .tnl-tag-filter-panel {
        background: none repeat scroll 0 0 @COLOR-TNL-GRAY4;
        border: 1px solid @COLOR-TNL-GRAY6;
        display: none;
        padding: 10px;
        position: absolute;
        z-index: 100;
    }

    .tnl-tagged-note {
        border: 1px solid @COLOR-PM-GREY5;
        border-radius: 4px;
        margin: 4px 0;
        padding: 10px 10px 0 10px;
        cursor: pointer;
        clear: both;
    }

    .tnl-tagged-note-on {
        border: 1px solid @COLOR-TNL-GRAY2;
        background: @COLOR-TNL-GRAY6;
    }

    .tnl-tagged-note-control {
        background: #FFE9B9;
        margin: -5px 0;
        padding: 3px;
        white-space: nowrap;
        display: none;
    }

    .tnl-tagged-note-control-on {
        border: 1px solid @COLOR-TNL-GRAY2;
        border-top: 1px dotted @COLOR-TNL-GRAY2;
    }

    .tnl-tagged-note-print {
        border: 1px dotted @COLOR-TNL-GRAY2;
        margin: 4px 0;
    }

    .tnl-tagged-note-hot {
        background: @COLOR-TNL-GRAY6;
        border: 1px solid @COLOR-TNL-GRAY2;
    }

    .tnl-tagged-note-hover {
        background: #FDEBB5;
        border: 1px solid @COLOR-TNL-GRAY2;
    }

    .tnl-filter-remove {
        float: left;
        position: relative;
        padding: 0 4px;
        cursor: pointer;
    }

    .tnl-selected-master{
        padding: 15px 10px;
        border-bottom: 1px solid @COLOR-TNL-GRAY4;
        background-color:#F5F9FA;
    }

}