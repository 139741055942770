@import (reference) "_global.less";

.empari {
    .tnlf-file-tree {
        min-height:20px;
        padding:19px;
        margin-bottom:20px;
        background-color:#fbfbfb;
        border:1px solid #999;
        -webkit-border-radius:4px;
        -moz-border-radius:4px;
        border-radius:4px;
        -webkit-box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05);
        -moz-box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05);
        box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05)
    }
    .tnlf-file-tree li {
        list-style-type:none;
        margin:0;
        padding:10px 5px 0 5px;
        position:relative
    }
    .tnlf-file-tree li::before, .tnlf-file-tree li::after {
        content:'';
        left:-20px;
        position:absolute;
        right:auto
    }
    .tnlf-file-tree li::before {
        border-left:1px solid #999;
        bottom:50px;
        height:100%;
        top:0;
        width:1px
    }
    .tnlf-file-tree li::after {
        border-top:1px solid #999;
        height:20px;
        top:25px;
        width:25px
    }
    .tnlf-file-tree li span {
        -moz-border-radius:5px;
        -webkit-border-radius:5px;
        border:1px solid #999;
        border-radius:5px;
        display:inline-block;
        padding:3px 8px;
        text-decoration:none
    }
    .tnlf-file-tree li.parent_li>span {
        cursor:pointer
    }
    .tnlf-file-tree>ul>li::before, .tnlf-file-tree>ul>li::after {
        border:0
    }
    .tnlf-file-tree li:last-child::before {
        height:30px
    }
    .tnlf-file-tree li.parent_li>span:hover, .tnlf-file-tree li.parent_li>span:hover+ul li span {
        background:#eee;
        border:1px solid #94a0b4;
        color:#000
    }   
}