@import (reference) "_global.less";
@import (reference) "../_pm-global.less";


.empari {

    .tnlif {
        &.fa,
        &.im {
	        display: inline-block;
	        height: 20px;
	        width: 18px;
	        color: @COLOR-TNL-GRAY2;
	        font-size: 14px;
	        text-align: center;
        }

        &.success {
            color: @COLOR-SUCCESS !important;
        }

        &.alert {
            color: @COLOR-DANGER !important;
        }

        &.warning {
            color: @COLOR-WARNING !important;
        }

    }

    .active-icon {
	    cursor: pointer;
    }

    // TNL Icon Sprites
    .tnl-icon-wrapper {
	    display: inline-block;
	    .LESS-FIX-IE7-INLINE-BLOCK();

        span.fa {
            .PM-TRANSITION();

            &:hover {
                color: @COLOR-PM-GREEN1;
                .PM-TRANSITION();
            }
        }
    }

    .tnl-icon-sm, .tnl-icon-md, .tnl-icon-lg {
        overflow: hidden;
        display: inline-block;
        .LESS-FIX-IE7-INLINE-BLOCK();
    }

    .tnl-icon-sm {
        width: 16px;
        height: 16px;
        background: url(/images/sprites/tnl-icons-small.png) no-repeat 0 16px;
    }

    .tnl-icon-md {
        width: 24px;
        height: 24px;
        background: url(/images/sprites/tnl-icons-medium.png) no-repeat 0 24px;
    }

    .tnl-icon-lg {
        width: 32px;
        height: 32px;
        background: url(/images/sprites/tnl-icons-large.png) no-repeat 0 32px;
    }

    .tnl-icon {
        padding: 2px 6px;
        position: relative;
        text-decoration: none !important;
    }

    .tnl-icon .ui-icon {
        position: absolute;
    }

    .btn.tnl-icon .ui-icon {
        position: relative;
    }

    span.tnl-icon-text {
        color: inherit;
        margin-left: 20px;
    }

    .tnl-icon-text-link .ui-icon {
      display: inline-block;
      top:3px;
      left: 3px;
      position: relative;
    }

    a.tnl-icon-text-link span {
      margin-right: 4px;
      display: inline-block;
    }

    span.tnlif.un-read {
	    color: @COLOR-TNL-GREEN1;
    }

    span.tnlif.change-better {
	    color: @COLOR-SUCCESS;
    }

    span.tnlif.change-worse {
	    color: @COLOR-DANGER;
    }

    span.tnlif.fa.met-expectations-yes {
        color: @COLOR-SUCCESS;
    }

    span.tnlif.fa.met-expectations-no {
        color: @COLOR-DANGER;
    }

    // sm row on
    .tnl-icon-status-complete-sm {
        background-position: 0 0;
    }
    .tnl-icon-status-in-progress-sm {
        background-position: -16px 0;
    }
    .tnl-icon-status-not-started-sm {
        background-position: -32px 0;
    }
    .tnl-icon-status-complete-locked-sm {
        background-position: -144px -97px;
    }
   .tnl-icon-status-in-progress-locked-sm {
        background-position: -146px -113px;
    }
   .tnl-icon-status-not-started-locked-sm {
        background-position: -129px -115px;
    }
    .tnl-icon-maximize-01-sm {
        background-position: -48px 0;
    }
    .tnl-icon-minimize-01-sm {
        background-position: -64px 0;
    }
    .tnl-icon-mark-as-complete-01-sm {
        background-position: -80px 0;
    }
    .tnl-icon-info-01-sm {
        background-position: -96px 0;
    }
    .tnl-icon-email-01-sm {
        background-position: -112px 0;
    }
    .tnl-icon-print-01-sm {
        background-position: -128px 0;
    }
    .tnl-icon-delete-01-sm {
        background-position: -144px 0;
    }

    // sm row two
    .tnl-icon-help-01-sm {
        background-position: 0 -16px;
	    cursor: pointer;
    }
    .tnl-icon-help-02-sm {
        background-position: -16px -16px;
	    cursor: pointer;
    }
    .tnl-icon-calendar-sm {
        background-position: -32px -16px;
	    cursor: pointer;
    }
    .tnl-icon-maximize-02-sm {
        background-position: -48px -16px;
	    cursor: pointer;
    }
    .tnl-icon-minimize-02-sm {
        background-position: -64px -16px;
	    cursor: pointer;
    }
    .tnl-icon-mark-as-complete-02-sm {
        background-position: -80px -16px;
	    cursor: pointer;
    }
    .tnl-icon-info-02-sm {
        background-position: -96px -16px;
	    cursor: pointer;
    }
    .tnl-icon-email-02-sm {
        background-position: -112px -16px;
	    cursor: pointer;
    }
    .tnl-icon-print-02-sm {
        background-position: -128px -16px;
	    cursor: pointer;
    }
    .tnl-icon-delete-02-sm {
        background-position: -144px -16px;
	    cursor: pointer;
    }

    // sm row three
    .tnl-icon-sort-asc-01-sm {
	    background-position: 0 -32px;
	    cursor: pointer;
    }
    .tnl-icon-sort-dsc-01-sm {
        background-position: -16px -32px;
	    cursor: pointer;
    }
    .tnl-icon-pdf-01-sm {
        background-position: -32px -32px;
	    cursor: pointer;
    }
    .tnl-icon-status-reopened-sm {
        background-position: -48px -32px;
    }
    .tnl-icon-report-01-sm {
        background-position: -64px -32px;
	    cursor: pointer;
    }
    .tnl-icon-report-02-sm {
        background-position: -80px -32px;
	    cursor: pointer;
    }
    .tnl-icon-comments-01-sm {
        background-position: -96px -32px;
    }
    .tnl-icon-comments-02-sm {
        background-position: -112px -32px;
    }
    .tnl-icon-alert-01-sm {
        background-position: -128px -32px;
    }
    .tnl-icon-alert-02-sm {
        background-position: -144px -32px;
    }

    // sm row four
    .tnl-icon-sort-asc-02-sm {
        background-position: 0px -48px;
	    cursor: pointer;
    }
    .tnl-icon-sort-dsc-02-sm {
        background-position: -16px -48px;
	    cursor: pointer;
    }
    .tnl-icon-pdf-02-sm {
        background-position: -32px -48px;
	    cursor: pointer;
    }
    .tnl-icon-status-pending-sign-sm {
	    background-position: -48px -48px;
	    cursor: pointer;
    }
    .tnl-icon-status-pending-complete-sm {
	    background-position: -64px -48px;
	    cursor: pointer;
    }
    .tnl-icon-next-01-sm {
	    background-position: -80px -48px;
	    cursor: pointer;
    }
    .tnl-icon-next-02-sm {
	    background-position: -96px -48px;
	    cursor: pointer;
    }
    .tnl-icon-previous-01-sm {
	    background-position: -112px -48px;
	    cursor: pointer;
    }
    .tnl-icon-previous-02-sm {
	    background-position: -128px -48px;
	    cursor: pointer;
    }
    .tnl-icon-unlock-item-02-sm {
	    background-position: -144px -48px;
	    cursor: pointer;
    }

    // sm row five
    .tnl-icon-rate-off-sm {
        background-position: 0px -64px;
    }
    .tnl-icon-rate-empty-sm {
        background-position: -16px -64px;
	    cursor: pointer;
    }
    .tnl-icon-rate-half-sm {
        background-position: -32px -64px;
	    cursor: pointer;
    }
    .tnl-icon-rate-full-sm {
	    background-position: -48px -64px;
	    cursor: pointer;
    }
    .tnl-icon-rate-fill-sm { // Not for display purposes. Only use for ratings functionality.
	    background-position: -48px -64px !important;
	    cursor: pointer;
    }
    .tnl-icon-rate-ready-sm {
	    background-position: -64px -64px;
	    cursor: pointer;
    }
    .tnl-icon-rate-make-ready-sm { // Not for display purposes. Only use for ratings functionality.
	    background-position: -64px -64px !important;
	    cursor: pointer;
    }
    .tnl-icon-open-item-02-sm {
	    background-position: -80px -64px;
	    cursor: pointer;
    }
    .tnl-icon-open-item-01-sm {
	    background-position: -96px -64px;
	    cursor: pointer;
    }
    .tnl-icon-lock-item-02-sm {
	    background-position: -112px -64px;
	    cursor: pointer;
    }
    .tnl-icon-lock-item-01-sm {
	    background-position: -128px -64px;
	    cursor: pointer;
    }
    .tnl-icon-unlock-item-01-sm {
	    background-position: -144px -64px;
	    cursor: pointer;
    }

    // sm row six
    .tnl-icon-new-02-sm {
        background-position: 0 -80px;
	    cursor: pointer;
    }
    .tnl-icon-new-01-sm {
        background-position: -16px -80px;
	    cursor: pointer;
    }
    .tnl-icon-close-02-sm {
        background-position: -32px -80px;
	    cursor: pointer;
    }
    .tnl-icon-close-01-sm {
        background-position: -48px -80px;
	    cursor: pointer;
    }
    .tnl-icon-archive-02-sm {
        background-position: -64px -80px;
	    cursor: pointer;
    }
    .tnl-icon-archive-01-sm {
        background-position: -80px -80px;
	    cursor: pointer;
    }
    .tnl-icon-un-archive-02-sm {
        background-position: -96px -80px;
	    cursor: pointer;
    }
    .tnl-icon-un-archive-01-sm {
        background-position: -112px -80px;
	    cursor: pointer;
    }
    .tnl-icon-back-02-sm {
        background-position: -144px -80px;
	    cursor: pointer;
    }
    .tnl-icon-back-01-sm {
        background-position: -144px -80px;
	    cursor: pointer;
    }

    // sm row seven
    .tnl-icon-action-menu-sm {
        background-position: 0 -96px;
    }
    .tnl-icon-approved-sm {
        background-position: 0 -96px;
    }
    .tnl-icon-approve-02-sm {
        background-position: -16px -96px;
    }
    .tnl-icon-approve-01-sm {
        background-position: -32px -96px;
    }
    .tnl-icon-declined-sm {
        background-position: -48px -96px;
    }
    .tnl-icon-decline-02-sm {
        background-position: -64px -96px;
    }
    .tnl-icon-decline-01-sm {
        background-position: -80px -96px;
    }
    .tnl-icon-pending-sm {
        background-position: -96px -96px;
    }
    .tnl-icon-compare-02-sm {
        background-position: -112px -96px;
	    cursor: pointer;
    }
    .tnl-icon-compare-01-sm {
        background-position: -128px -96px;
	    cursor: pointer;
    }
    .tnl-icon-share-02-sm {
        background-position: 0 -112px;
	    cursor: pointer;
    }
    .tnl-icon-share-01-sm {
        background-position: -16px -112px;
	    cursor: pointer;
    }
    .tnl-icon-credit-02-sm {
        background-position: -32px -112px;
	    cursor: pointer;
    }
    .tnl-icon-credit-01-sm {
        background-position: -48px -112px;
	    cursor: pointer;
    }
    .tnl-icon-archived-02-sm {
        background-position: -64px -112px;
    }
    .tnl-icon-archived-01-sm {
        background-position: -80px -112px;
    }
    .tnl-icon-recommend-02-sm {
        background-position: -96px -112px;
	    cursor: pointer;
    }
    .tnl-icon-recommend-01-sm {
        background-position: -112px -112px;
	    cursor: pointer;
    }

    // md row one
    .tnl-icon-status-complete-md {
        background-position: 0 0;
    }
    .tnl-icon-status-in-progress-md {
        background-position: -24px 0;
    }
    .tnl-icon-status-not-started-md {
        background-position: -48px 0;
    }
    .tnl-icon-status-pending-complete-md {
	    background-position: -72px 0;
    }
    .tnl-icon-status-complete-locked-md {
        background-position: -170px -74px;
    }
    .tnl-icon-status-in-progress-locked-md {
        background-position: -219px -75px;
    }
    .tnl-icon-status-not-started-locked-md {
        background-position: -197px -77px;
    }
    .tnl-icon-form-md {
        background-position: -96px 0;
    }
    .tnl-icon-course-md {
        background-position: -120px 0;
    }
    .tnl-icon-membership-md {
        background-position: -144px 0;
    }
    .tnl-icon-sign-off-md {
        background-position: -168px 0;
    }
    .tnl-icon-learning-opportunity-md {
        background-position: -192px 0;
    }
    .tnl-icon-link-md {
        background-position: -216px 0;
    }


    // md row two
    .tnl-icon-action-menu-md {
        background-position: 0 -24px;
    }

    .tnl-icon-status-reopened-md {
        background-position: -48px -24px;
    }
    .tnl-icon-status-pending-sign-md {
	    background-position: -72px -24px;
    }
    .tnl-icon-effectiveness-rating-md {
        background-position: -96px -24px;
    }
    .tnl-icon-notes-library-md {
        background-position: -120px -24px;
    }
    .tnl-icon-calibration-md {
        background-position: -144px -24px;
    }
    .tnl-icon-observation-md {
        background-position: -168px -24px;
    }
    .tnl-icon-rubric-dashboard-md {
        background-position: -192px -24px;
    }
    .tnl-icon-form-dashboard-md {
        background-position: -216px -24px;
    }

    // md row three
    .tnl-icon-calendar-md {
        background-position: 0 -48px;
    }
    .tnl-icon-display-assessment-md {
        background-position: -72px -48px;
    }
    .tnl-icon-self-assessment-md {
        background-position: -96px -48px;
    }
    .tnl-icon-assessment-summary-md {
        background-position: -120px -48px;
    }
    .tnl-icon-assessment-feedback-md {
        background-position: -144px -48px;
    }
    .tnl-icon-assessment-360-md {
        background-position: -168px -48px;
    }
    .tnl-icon-assessment-observation-md {
        background-position: -192px -48px;
    }
    .tnl-icon-teacher-menu-md {
        background-position: -216px -48px;
    }

    // md row four
    .tnl-icon-reports-dashboard-menu-md {
	    background-position: 0 -72px;
    }
    .tnl-icon-archived-01-md {
	    background-position: -24px -72px;
    }
    .tnl-icon-archived-02-md {
	    background-position: -48px -72px;
    }
    .tnl-icon-validate-md {
	    background-position: -72px -72px;
    }
    .tnl-icon-choose-md {
	    background-position: -96px -72px;
    }
    .tnl-icon-evidence-md {
	    background-position: -120px -72px;
    }
    .tnl-icon-schedule-md {
	    background-position: -144px -72px;
    }

    // lg row one
    .tnl-icon-status-complete-lg {
        background-position: 0 0;
    }
    .tnl-icon-status-in-progress-lg {
        background-position: -32px 0;
    }
    .tnl-icon-status-not-started-lg {
        background-position: -64px 0;
    }
    .tnl-icon-status-pending-complete-large {
	    background-position: -96px 0;
    }
    .tnl-icon-status-complete-locked-lg {
        background-position: -4px -63px;
    }
    .tnl-icon-status-in-progress-locked-lg {
        background-position: -33px -64px;
    }
    .tnl-icon-status-not-started-locked-lg {
        background-position: -69px -67px;
    }
    .tnl-icon-form-lg {
        background-position: -128px 0;
    }
    .tnl-icon-course-lg {
        background-position: -160px 0;
    }
    .tnl-icon-membership-lg {
        background-position: -192px 0;
    }
    .tnl-icon-sign-off-lg {
        background-position: -224px 0;
    }
    .tnl-icon-learning-opportunity-lg {
        background-position: -256px 0;
    }
    .tnl-icon-link-lg {
        background-position: -288px 0;
    }
    .tnl-icon-display-assessment-lg {
        background-position: -96px -64px;
    }

    // lg row two
    .tnl-icon-instructor-lead-lg {
	    background-position: 0 -32px;
    }
    .tnl-icon-self-paced-lg {
	    background-position: -32px -32px;
    }
    .tnl-icon-status-pending-sign-large {
	    background-position: -96px -32px;
    }
    .tnl-icon-effectiveness-rating-lg {
        background-position: -128px -32px;
    }
    .tnl-icon-notes-library-lg {
        background-position: -160px -32px;
    }
    .tnl-icon-calibration-lg {
        background-position: -192px -32px;
    }
    .tnl-icon-observation-lg {
        background-position: -224px -32px;
    }
    .tnl-icon-rubric-dashboard-lg {
        background-position: -256px -32px;
    }
    .tnl-icon-form-dashboard-lg {
        background-position: -288px -32px;
    }

    // row three
    .tnl-icon-self-assessment-lg {
        background-position: -128px -64px;
    }
    .tnl-icon-assessment-summary-lg {
        background-position: -160px -64px;
    }
    .tnl-icon-assessment-feedback-lg {
        background-position: -192px -64px;
    }
    .tnl-icon-assessment-360-lg {
        background-position: -224px -64px;
    }
    .tnl-icon-assessment-observation-lg {
        background-position: -256px -64px;
    }

    // row four
    .tnl-icon-validate-lg {
	    background-position: -96px -96px;
    }
    .tnl-icon-choose-lg {
	    background-position: -128px -96px;
    }
    .tnl-icon-evidence-lg {
	    background-position: -160px -96px;
    }
    .tnl-icon-schedule-lg {
	    background-position: -192px -96px;
    }


    .tnl-icon-pinned {
        .tnlif.fa {
             color: @COLOR-TNL-GREEN1;
             margin-right:5px;
             float: left;
         }
    }


    .tnl-icon-unpinned {
        .tnlif.fa {
             color: #000000;
             opacity: .2;
             margin-right:5px;
             float: left;
         }
    }
}
