@import (reference) "_global.less";

.empari {

    // iPad
    .SMALL-DEVICE() {
	
	    .obs-textarea-ipd {
		    height: 400px; 
	    }
	
	    #obv-nav-panel {
		    overflow: scroll;
		    height: 400px;
	    }
	
	    #obv-nav-panel #categoryTable {
		    min-width: 150px;
	    }
	
	    #obv-nav-panel table td {
		    padding: 0 0 20px 0;
	    }
	
	    #obv-nav-panel input {
		    margin-right: 10px;
		    width: 20px;
		    height: 20px;
	    }
	
	    ul.tnl-action-menu ul li a,
	    ul.tnl-action-menu ul li .ui-state-hover,
	    .ui-widget ul.tnl-action-menu ul li .ui-state-hover {
		    padding: 10px;
	    }
	
	    .tnl-popup-menu-body ul li {
		    padding: 10px 0;
	    }
	
	    .tnl-menu li.tnl-menu-li a,
	    .tnl-menu li.tnl-menu-li .ui-state-hover {
		    padding: 10px 0;
	    }
	
	    .obs-nav-ipd {
		    min-width: 300px;
	    }
	
	    //item list adjustments
	
	    .tnl-item-list > li {
		    padding: 20px 10px;
	    }
	
	
	    //table adjustments
        table td.intitle {
            display: block;
            width: auto;
            padding-bottom: 0;
            text-align: left;
            background: #fff;
        }
        table td.infield {
            display: block;
            width: auto;
            padding-top: 4px;
        }
    }

    @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px)
	and (orientation: portrait)
	{
	    .SMALL-DEVICE();
    }

    @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : landscape) {
	    .SMALL-DEVICE();
    }
}