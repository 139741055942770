@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    td {

        &.menuheader {
            color: black;
            .COS-OPEN-SANS-600();
        }

        &.menunormal {
            color: @COLOR-TNL-GRAY3;
            font-size: 13px;
            padding: 0 5px;
            vertical-align: top;
        }

        &.menuhover {
            color: #333;
            .COS-OPEN-SANS-600();
            padding: 0 5px;
            vertical-align: top;
        }

    }

    div {

        &.menunormal {
            display: none;
            position: static;
        }

        &.menuhover {
            border: 1px solid #000;
            background-color: @COLOR-TNL-GRAY6;
            display: inline;
            position: absolute;
            z-index: 90;
        }

        &.menuhover i {
            color: #000;
            font-size: 12px;
            padding: 4px;
        }

    }

    .menuitem {
        color:@COLOR-TNL-GRAY2 !important;
	    display: block;
	    padding: 3px 15px 3px 10px;
	    text-decoration: none;

        &:link {
            display: block;
            text-decoration: none;
        }

        &:visited {
            display: block;
            text-decoration: none;
        }

        &:hover {
            background-color: #fff;
            display: block;
            text-decoration: none;
        }

        &:active {
            background-color: #fff;
            display: block;
            text-decoration: none;
        }

        &:link,
        &:visited {
	        display: block;
	        padding: 3px 15px 3px 10px;
	        color: #000;
	        text-decoration: none;
        }

        &:active,
        &hover {
	        color: @COLOR-TNL-ORANGE1;
	        text-decoration: underline;
        }

    }

    table.menunormal,
    table.menuhover {
	    position: relative; 
	    color: @COLOR-TNL-GRAY3;
	    font-size: 14px;
	    cursor: pointer;

        tr td.menuheader,
        tr td.menuheader  {
	        width: 79px;
	        height: 20px;
	        margin: 0;
	        padding: 0;
        }

    }

    table.menunormal div.menunormal {
	    display: none;
    }

    table.menuhover {

        div.menuhover {
	        z-index: 90;
	        position: absolute;
	        top: 18px;
	        left: 0;
	        background-color: #fff;
	        border: 1px solid #ccc;
	        .LESS-SHADOW-POPUP-MENU;
        }

        div.menuhover table {
	        width: 100%;
        }

        div.menuhover table td {
	        width: 100%;
	        margin: 0;
	        padding: 0;
        }

    }

    html.lt-ie8 {

        table.menuhover {
	        z-index: 3000; //Fix IE7 z-index bug
        }

        div.menuhover,
        div.menunormal {
	        width: 250px;
	        z-index: 2000; //Fix IE7 z-index bug
        }

    }

    .action-menu {

        > ul {
            padding:0;
            margin:0;
        }
        > ul > li {
           padding:0;
        }

    }

    .tnl-menu,
    .ui-widget-content .tnl-menu {
        z-index:200;
        width: 200px;
        padding: 5px;

        ul {
            margin:0;
            padding:0;
	        list-style-type: none;
        }

        li.tnl-menu-li a,
        li.tnl-menu-li .ui-state-hover {
            display: block;
            padding: 0 0 5px 0;
            font-size: 14px;
        }

        li.tnl-menu-li a,
        li.tnl-menu-li a:active,
        li.tnl-menu-li a:visited,
        li.tnl-menu-li a,
        li.tnl-menu-li a:active,
        li.tnl-menu-li a:visited {
            color: #000;
            text-decoration: none;
        }
        li.tnl-menu-li a:hover,
        li.tnl-menu-li a:hover {
	        color: @COLOR-TNL-ORANGE1;
	        text-decoration: underline;
	        cursor: pointer;
        }

        li.tnl-menu-li span.ui-icon {
	        position: relative;
	        display: inline-block;
	        top: 2px;
	        margin: 0 5px 0 -6px;
	        padding: 0;
            .PM-ROUNDED-CORNERS();
        }

        ul.ui-state-default {
	        background: #fff;
	        color: #000;
	        border:none;
        }

    }

    .tnl-menu-div {
        display:inline-block;
	    .LESS-FIX-IE7-INLINE-BLOCK();
        text-align:left;

        &.open {
	        position: relative;
        }

        .tnl-btn-icon {
            color: #fff;
	        background: @COLOR-TNL-GRAY3;
	        margin: 1px;
        }

        .tnl-btn-icon:hover {
            background: @COLOR-TNL-GRAY4;
            .LESS-BOX-SHADOW-RESET();
            border: none;
            margin: 1px;
        }

    }

    .tnl-menu-box {
        display: none;
        position: absolute;
        z-index: 100;
        top: 18px;
        left: 0;
        margin-top: 0;
        margin-left: auto;
        min-width: 100%;
        background: #fff;
        border: 1px solid @COLOR-TNL-GRAY3;
        .LESS-SHADOW-POPUP-MENU();

        &.left {
	        right: 0;
	        left: auto;
        }
    }

    .tnl-menu-li {
	    padding: 0 10px;
    }
  
    html.lt-ie8 .tnl-menu li.tnl-menu-li span.ui-icon {
	    .LESS-FIX-IE7-INLINE-BLOCK;
    }

    div.tnl-menu-div,
    div.ui-widget div.tnl-menu-div {
        
        .tnl-btn-icon-text,
        .tnl-btn-icon-text {
	        position: relative;
	        margin: 0;
	        padding: 4px 34px 4px 10px;
	        color: @COLOR-TNL-GRAY1;
	        font-size: 14px;
	        font-weight: normal;
	        .LESS-BACKGROUND-MENU();
	        border: 1px solid @COLOR-TNL-GRAY3;
            .PM-ROUNDED-CORNERS();
	        .LESS-BOX-SHADOW-RESET();
	        cursor: pointer;
        }

        .tnl-btn-icon-text:Hover,
        .tnl-btn-icon-text:Hover {
	        margin: 0;
	        padding: 4px 34px 4px 10px;
	        color: @COLOR-TNL-GRAY2;
	        font-weight: normal;
	        .LESS-BACKGROUND-MENU();
	        border: 1px solid @COLOR-TNL-GRAY3;
	        .LESS-BOX-SHADOW-RESET();
        }

        .tnl-btn-icon-text span.ui-icon,
        .tnl-btn-icon-text span.ui-icon {
	        position: absolute;
	        top: 0;
	        right: 0;
	        margin: 3px;
	        background-image: url(/images/smoothness/images/ui-icons_888888_256x240.png);
            border-radius: 0 4px 4px 0;
        }

        .tnl-btn-icon-text .tnl-menu-box,
        .tnl-btn-icon-text .tnl-menu-box {
	        top: 21px;
        }

    }

    html.lt-ie9 div.tnl-menu-div .tnl-btn-icon-text {
	    border: 1px solid @COLOR-TNL-GRAY3 !important;
    }

    html.lt-ie8 li.tnl-menu-li span.ui-icon {
	    display: inline;
	    zoom: 1;
	    position: absolute;
	    margin-left: -18px;
    }

    // TNL Action Menu
    th ul.tnl-action-menu,
    .tnl-table-side-th ul.tnl-action-menu {
	    text-align: left;
    }
    ul.tnl-action-menu {
	    position: relative;
	    display: inline-block;
	    list-style-type: none;
        color: @COLOR-TNL-GRAY2;
        font-size: 14px;
	    font-weight: normal;
	    text-align: left;
	    line-height: 14px;
	    .LESS-BACKGROUND-MENU;
	    border: 1px solid @COLOR-TNL-GRAY3;
        .PM-ROUNDED-CORNERS();
    }
    ul.tnl-action-menu .tnl-icon-text-link {
	    padding-left: 0;
    }
    ul.tnl-action-menu.tnl-action-icon {
	    width: 46px;
    }
    ul.tnl-action-menu.tnl-action-icon .display-icon {
	    margin: 1px 0 0 2px;
    }
    ul.tnl-action-menu, ul.tnl-action-menu li {
	    margin: 0;
	    padding: 0;
    }
    ul.tnl-action-menu .display-text {
	    padding: 4px 10px;
    }
    ul.tnl-action-menu > li {
	    float: left;
        border-radius: 4px 0 0 4px;
    }
    ul.tnl-action-menu .action {
	    width: 16px;
	    height: 16px;
	    background-image: url(/images/smoothness/images/ui-icons_888888_256x240.png);
	    margin: 3px;
	    cursor: pointer;
    }
    ul.tnl-action-menu ul {
	    position: absolute;
	    z-index: 100;
	    top: 19px;
	    width: 200px;
	    list-style-type: none;
	    padding: 6px;
	    background: #fff;
	    border: 1px solid @COLOR-TNL-GRAY3;
        .LESS-SHADOW-POPUP-MENU;
    }
    ul.tnl-action-menu.to-right ul {
	    left: -1px;
    }
    ul.tnl-action-menu.to-left ul {
	    right: -1px;
    }
    ul.tnl-action-menu ul li a,
    ul.tnl-action-menu ul li a.tnl-text-link,
    ul.tnl-action-menu ul li a.tnl-icon-text-link,
    .ui-widget ul.tnl-action-menu ul li a.tnl-text-link,
    .ui-widget ul.tnl-action-menu ul li a.tnl-icon-text-link,
    ul.tnl-action-menu ul li .ui-state-hover,
    .ui-widget ul.tnl-action-menu ul li .ui-state-hover {
        display: block;
	    color: #333;
        padding: 6px 10px;
    }
    ul.tnl-action-menu li ul a:hover,
    .ui-widget ul.tnl-action-menu li ul a:hover {
	    color: @COLOR-TNL-ORANGE1;
    }
    ul.tnl-action-menu > .display-icon .tnlif {
	    margin-top: 2px;
    }
    ul.tnl-action-menu span.ui-icon {
	    position: relative;
	    display: inline-block;
	    top: 2px;
	    margin: 0 5px 0 -6px;
	    padding: 0;
    }
    ul.tnl-action-menu.tnl-action-icon .tnl-icon-text-link span {
	    margin-left: 0;
    }
    html.lt-ie8 ul.tnl-action-menu span.ui-icon {
	    .LESS-FIX-IE7-INLINE-BLOCK();
	    position: absolute;
    }
    ul.tnl-action-icon .tnl-icon-text-link span {
	    position: relative;
	    display: inline-block;
	    top: 2px;
	    margin: 0 5px 0 -6px;
	    padding: 0;
    }
    html.lt-ie8 ul.tnl-action-icon span.tnl-icon-sm {
	    .LESS-FIX-IE7-INLINE-BLOCK();
    }
    html.lt-ie8 ul.tnl-action-menu {
	    display: inline;
	    zoom: 1;
	    z-index: 3000; // Fix IE7 z-index bug
    }
    html.lt-ie8 ul.tnl-action-menu .tnl-icon-text-link {
        color: @COLOR-TNL-GRAY2;
    }
    html.lt-ie8 ul.tnl-action-menu li {
	    z-index: 2000; // Fix IE7 z-index bug
    }

    .tnl-button-menu {
        &.btn-group {
            a.boot-btn:hover,
            button.boot-btn:hover {
                border-color: transparent;
            }
        }
    }
}