@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    // #navigation is used in Empari as additional navigation on the left of pages
    // #navigation link destinations are often void of any logical context
    div#navigation {
        min-width: 150px;
        max-width: 200px;
        margin: 0 30px 0 0;
        
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        ul li a {
            display: block;
            padding: 15px 0 0 0;
            font-size: @COS-FONT-SIZE-12;
        }

        // For some reason .menu is used to mean header
        li.menu {
            margin: 0;
            padding: 10px 0 0;
            color: @COLOR-COS-DEFAULT;
            .COS-OPEN-SANS-600();
            .LESS-ELLIPSIS-ONE-LINE();
            border-bottom: 4px double #ccc;
        }
    }
}