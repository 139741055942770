@import (reference) "_global.less";
@import (reference) "../_pm-global.less";


.empari {

    // Fix for Google Chrome font rendering issue.
    // More info http://stackoverflow.com/a/22025804/44852
    body {
        -webkit-animation-delay: 0.1s;
        -webkit-animation-name: fontfix;
        -webkit-animation-duration: 0.1s;
        -webkit-animation-iteration-count: 1;
        -webkit-animation-timing-function: linear;
    }
    // end fix

    div.ui-widget,
    div.ui-widget button {
        .COS-OPEN-SANS-600();
    }

    .ui-widget-content {

        a,
        a:visited,
        a.tnl-text-link,
        a.tnl-text-link:visited,
        .ui-tabs-panel a,
        .ui-tabs-panel a:visited {
	        color: @COLOR-PM-BLUE1;
	        text-decoration: none !important;
        }

        a:hover,
        a.tnl-text-link:hover,
        .ui-tabs-panel a:hover {
	        color: @COLOR-PM-BLUE2;
	        text-decoration: none;
        }

        a:active,
        a.tnl-text-link:active,
        .ui-tabs-panel a:active {
            color: @COLOR-PM-GREEN1;
        }

        .disabled,
        .disableda:hover,
        .disabled,
        .disableda:hover {
	        text-decoration: none;
	        opacity: 0.40;
	        filter: Alpha(Opacity=40);
	        cursor: text;
        }
    }

    .disabled,
    .disabled a:hover {
	    text-decoration: none;
	    opacity: 0.40;
	    filter: Alpha(Opacity=40);
	    cursor: text;
    }

    ul.tnl-checkbox-list,
    ul.tnl-radio-list {
	    margin: 0;
	    padding: 0;
	    list-style-type: none;
    }

    ul.tnl-checkbox-list.vertical > li,
    ul.tnl-radio-list.vertical > li {
	    margin: 0 0 5px 0;
	    padding: 0;
    }

    ul.tnl-checkbox-list.horizontal > li,
    ul.tnl-radio-list.horizontal > li {
	    display: inline-block;
	    .LESS-FIX-IE7-INLINE-BLOCK();
	    margin: 0 15px 0 0;
	    padding: 0;
    }

    html.lt-ie8 ul.tnl-checkbox-list.horizontal > html.lt-ie8 li,
    ul.tnl-radio-list.horizontal > li {
	    .LESS-FIX-IE7-INLINE-BLOCK();
    }

    ul.tnl-checkbox-list label,
    ul.tnl-radio-list label {
	    margin: 0 5px 0 0;
    }

    .tnl-text-link,
    .tnl-text-link:visited,
    .tnl-text-link:hover,
    .tnl-icon-text-link,
    .tnl-icon-text-link:visited,
    .tnl-icon-link,
    .tnl-icon-link:visited,
    div.ui-widget-content .tnl-text-link:hover {
      display: inline-block;
      border: none;
      background: none;
      position: relative;
      top: 0;
      left: 0;
      cursor: pointer;
      zoom: 1;
      .LESS-FIX-IE7-INLINE-BLOCK();
    }

    .tnl-dashboard2 .tnl-pdf-link span,
    .tnl-dashboard2 .tnl-pdf-link:visited span,
    .tnl-dashboard2 .tnl-pdf-link:hover span,
    .tnl-dashboard2 .tnl-pdf-link:active span  {
	    font-size: 110%;
	    color: @COLOR-PM-BLUE1;
    }

    // ui-state-hover tries to add a border. We don't want that for our links.
    .tnl-icon-text-link.ui-state-hover,
    .tnl-text-link.ui-state-hover,
    .tnl-icon-link.ui-state-hover,
    .tnl-icon-link-active.ui-state-hover {
        border:none;
        background: none;
    }

    .tnl-icon-link {
        margin: 3px 0 0 2px;
    }

    .tnl-icon-text-link > .ui-icon,
    .tnl-icon-link > .ui-icon {
        position: absolute;
        top: 0;
        left: 1px;
        margin: 0;
    }

    // The above tnl-styled links mess with some previous styles. Until these styles are no longer
    // in use, we need to override them manually.
    span.tnl-icon-text {
        margin-left: 0
    }

}
