@import (reference) "_global.less";
// This class was created to support an LAUSD custom channel

.empari {

    div.tnl-progress-circle {
       position: relative;
        width: 450px;
        height: 450px;
    }

    div.tnl-progress-circle span {
        position: absolute;
    }

    .tnl-progress-circle-1 {
        top: 33px;
        left: 213px;
    }

    .tnl-progress-circle-2 {
        top: 106px;
        left: 357px;
    }

    .tnl-progress-circle-3 {
        top: 259px;
        left: 387px;
    }

    .tnl-progress-circle-4 {
        top: 376px;
        left: 289px;
    }

    .tnl-progress-circle-5 {
        top: 376px;
        left: 137px;
    }

    .tnl-progress-circle-6 {
        top: 259px;
        left: 39px;
    }

    .tnl-progress-circle-7 {
        top: 106px;
        left: 68px;
    }

}