@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .filled-drop-box {
        width: 100%;
        *width: 96%;
        border: 0 solid @COLOR-TNL-GRAY3;
        float: left;
    }

    .msg-box {
        border: 5px solid #000;
        background: @COLOR-TNL-GRAY6;
        margin: 30px;
        padding: 30px 50px;
    }

    .warning-msg {
        border-color: @COLOR-WARNING;
        background: @COLOR-WARNING-LT;
    }

    .completed-msg {
        border-color: @COLOR-COMPLETE;
        background: @COLOR-TNL-GREEN2;
    }

    .section-header {
        font-size: 16px;
        padding: 0 5px 1px;
        .COS-OPEN-SANS-600();
    }

    .section-box {
        padding: 0;
        margin: 0 0 10px 0;
        border: 1px solid @COLOR-TNL-GRAY4;
        color: @COLOR-COS-DEFAULT;
    }

    .section-box .inputborder {
        width: 100%;
    }

    .goal-module-title {
        font-size: 12px;
        .COS-OPEN-SANS-600();
    }

    .view-hide {
        font-size: 14px;
    }

    .act-desc-box {
        display: none;
        margin-top: -25px;
        position: absolute;
    }

    .info-text {
        margin: 5px 0 20px 20px;
    }

    .info-box {
        background-color: #FFFFFF;
        border: 1px solid @COLOR-TNL-GRAY3;
        color: #000;
        margin: 5px 5px 10px 0;
        padding: 2px 6px;
    }

    .step-box {
        background-color: @COLOR-TNL-GRAY6;
        border-color: @COLOR-TNL-GRAY2;
        border-style: solid;
        border-width: 1px 3px 3px 1px;
        color: #000;
        margin: 0 5px 4px 0;
        padding: 7px 0 7px 7px;
        position: relative;
        z-index: 1;
    }

    .step-box-tab {
        background-color: @COLOR-TNL-GRAY6;
        border-color: @COLOR-TNL-GRAY2;
        border-style: solid;
        border-width: 1px 1px 0;
        color: @COLOR-COS-DEFAULT;
        margin: 4px 0 0;
        padding: 2px;
        position: relative;
        top: 1px;
        width: 320px;
        z-index: 10;
    }

}