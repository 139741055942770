@import (reference) "_global.less";

.empari {

    .tnl-legend {
	    padding: 5px;
	    font-size: 12px;
	    color: @COLOR-TNL-GRAY2;
    }

    .tnl-legend.well {
        padding: 5px;
    }

    .tnl-legend > div {
	    display: inline-block;
	    padding: 0 10px;
	    border-right: 1px solid @COLOR-TNL-GRAY4;
    }

    .tnl-legend > div:first-child {
	    padding-left: 5px;
    }

    .tnl-legend > div:last-child {
	    padding-right: 5px;
	    border-right: none;
    }

    .tnl-legend > span {
	    display: inline-block;
	    .LESS-FIX-IE7-INLINE-BLOCK();
    }

    .tnl-channel-content2 .tnl-container-content > .tnl-legend {
        margin-top: 15px;
        .LESS-ROUNDED-CORNERS();
    }

    .legend {
        min-width: 100px;

	    ul li {
 		    margin: 5px 0;
	    }
     }

}