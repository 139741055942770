@import (reference) "_global.less";

.empari {
    .tnl-admin-icon,.tnl-arrows,.tnl-double-arrow,.tnl-system-admin,.tnl-round-icons-lg,.tnl-round-icons-md,.tnl-round-icons-sm,.tnl-boxes-sm,.tnl-subject-icons,.tnl-action-btn,.tnl-ta-read,.tnl-ta-new,.tnl-channel-icon,.tnl-red-box-left,.tnl-red-box-right,.tnl-paperclip-sm { background-image: url("/images/sprites/tnl-mega-sprite.png"); background-repeat: no-repeat; display: block; text-indent: -99999px; overflow: hidden;}


.tnl-admin-icon { width: 20px; height: 16px; }
.tnl-admin-box { background-position: 0 0; }
.tnl-admin-e-arrow-paper  { background-position: -20px 0; }
.tnl-admin-pencil  { background-position: -40px 0; }
.tnl-admin-webbing  { background-position: -60px 0; }
.tnl-admin-key  { background-position: -80px 0; }
.tnl-admin-horn  { background-position: -100px 0; }
.tnl-admin-scantron-pencil  { background-position: 0 -16px; }
.tnl-admin-scantron-hat  { background-position: -20px -16px; }
.tnl-admin-scantron-glasses  { background-position: -40px -16px; }
.tnl-admin-people  { background-position: -60px -16px; }
.tnl-admin-login  { background-position: -80px -16px; }
.tnl-admin-blank  { background-position: -100px -16px; }
.tnl-admin-3-boxes  { background-position: 0 -32px; }
.tnl-admin-lightbulb  { background-position: -20px -32px; }
.tnl-admin-house  { background-position: -40px -32px; }
.tnl-admin-target-arrow  { background-position: -60px -32px; }
.tnl-admin-dollar-sign  { background-position: -80px -32px; }
.tnl-admin-present  { background-position: -100px -32px; }
.tnl-admin-treasure-map  { background-position: 0 -48px; }
.tnl-admin-calendar-day  { background-position: -20px -48px; }
.tnl-admin-calendar-arrow  { background-position: -40px -48px; }
.tnl-admin-calendar-date  { background-position: -60px -48px; }
.tnl-admin-ruler  { background-position: -80px -48px; }
.tnl-admin-scales  { background-position: -100px -48px; }
.tnl-admin-checkbox  { background-position: 0 -64px; }
.tnl-admin-green-check  { background-position: -20px -64px; }
.tnl-admin-green-plus  { background-position: -40px -64px; }
.tnl-admin-lightning  { background-position: -60px -64px; }
.tnl-admin-speaker  { background-position: -60px -80px; }
.tnl-admin-books  { background-position: 0px -80px; }
.tnl-admin-open-book  { background-position: -20px -80px; }
.tnl-admin-notepad  { background-position: -40px -80px; }
.tnl-admin-pie-chart  { background-position: -60px -80px; }
.tnl-admin-globe  { background-position: -80px -80px; }
.tnl-admin-stairs  { background-position: -100px -80px; }

.tnl-arrows { width: 13px; height: 13px; }
.tnl-arrows-boxed-right { background-position: -120px 0; }
.tnl-arrows-boxed-left { background-position: -133px 0; }
.tnl-arrows-arrow-down { background-position: -146px 0; }
.tnl-arrows-arrow-right { background-position: -159px 0; }
.tnl-arrows-boxed-right-dis { background-position: -120px -13px; }
.tnl-arrows-boxed-left-dis { background-position: -133px -13px; }
.tnl-arrows-arrow-down-dis { background-position: -146px -13px; }
.tnl-arrows-arrow-right-dis { background-position: -159px -13px; }

.tnl-double-arrow { width: 26px; height: 13px; }
.tnl-double-arrow-right { background-position: -120px -26px; }
.tnl-double-arrow-left { background-position: -146px -26px; }
.tnl-double-arrow-right-dis { background-position:  -120px -39px; }
.tnl-double-arrow-left-dis { background-position: -146px -39px; }

.tnl-system-admin { width: 32px; height: 32px; }
.tnl-gears { background-position: -120px -52px; }
.tnl-cube { background-position: -152px -52px; }
.tnl-alert-box { background-position: -120px -84px; }
.tnl-graph { background-position: -152px -84px; }

.tnl-round-icons-lg { width: 32px; height: 32px; }
.tnl-round-lg-green-check { background-position: 0 -96px; }
.tnl-round-lg-yellow-minus { background-position: -32px -96px; }
.tnl-round-lg-orange-circle { background-position: -64px -96px; }
.tnl-round-lg-blue-circle { background-position: 0 -128px; }
.tnl-round-lg-dis-x { background-position: -32px -128px; }
.tnl-round-lg-red-x { background-position: -64px -128px; }

.tnl-round-icons-md { width: 18px; height: 18px; }
.tnl-round-md-green-check { background-position: -96px -116px; }
.tnl-round-md-yellow-minus { background-position: -114px -116px; }
.tnl-round-md-orange-circle { background-position: -132px -116px; }
.tnl-round-md-red-x { background-position: -150px -116px; }
.tnl-round-md-red-1 { background-position: -96px -134px; }
.tnl-round-md-red-2 { background-position: -114px -134px; }
.tnl-round-md-red-3 { background-position: -132px -134px; }
.tnl-round-md-blue-1 { background-position: -96px -152px; }
.tnl-round-md-blue-2 { background-position: -114px -152px; }
.tnl-round-md-blue-3 { background-position: -132px -152px; }
.tnl-round-md-blue-circle { background-position: -150px -152px; }
.tnl-round-md-blue-check { background-position: -168px -152px; }
.tnl-round-md-purple-question { background-position: -96px -170px; }
.tnl-round-md-orange-n { background-position: -114px -170px; }
.tnl-round-md-dis-circle { background-position: -96px -188px; }
.tnl-round-md-dis-plus { background-position: -114px -188px; }
.tnl-round-md-dis-x { background-position: -132px -188px; }
.tnl-round-md-grey-x { background-position: -150px -188px; }

.tnl-round-icons-sm { width: 14px; height: 14px; }
.tnl-round-sm-green-circle { background-position: 0 -160px; }
.tnl-round-sm-orange-circle { background-position: -14px -160px; }
.tnl-round-sm-yellow-circle { background-position: 0 -174px; }
.tnl-round-sm-red-circle { background-position: -14px -174px; }

.tnl-boxes-sm { width: 12px; height: 12px; }
.tnl-boxes-sm-green { background-position: -28px -160px; }
.tnl-boxes-sm-orange { background-position: -40px -160px; }
.tnl-boxes-sm-yellow { background-position: -52px -160px; }
.tnl-boxes-sm-red { background-position: -28px -172px; }
.tnl-boxes-sm-blue { background-position: -40px -172px; }
.tnl-boxes-sm-grey { background-position: -52px -172px; }

.tnl-subject-icons { width: 38px; height: 38px; }
.tnl-subject-art { background-position: 0 -206px; }
.tnl-subject-desk { background-position: -38px -206px; }
.tnl-subject-apple { background-position: -76px -206px; }
.tnl-subject-brain { background-position: -114px -206px; }
.tnl-subject-instruction { background-position: 0 -244px; }
.tnl-subject-language { background-position: -38px -244px; }
.tnl-subject-literacy { background-position: -76px -244px; }
.tnl-subject-math { background-position: -114px -244px; }
.tnl-subject-science { background-position: 0 -282px; }
.tnl-subject-globe { background-position: -38px -282px; }
.tnl-subject-lightbulb { background-position: -76px -282px; }
.tnl-subject-computer { background-position: -114px -282px; }

.tnl-action-btn { width: 79px; height: 20px; }
.tnl-action-btn-on { background-position: 0px -322px; }
.tnl-action-btn-off { background-position: -79px -322px; }

.tnl-action-btn { width: 79px; height: 20px; }
.tnl-action-btn-on { background-position: 0px -320px; }
.tnl-action-btn-off { background-position: -79px -320px; }

.tnl-ta-read { width: 8px; height: 8px; background-position: -96px -96px; }
.tnl-ta-new { width: 8px; height: 8px; background-position: -96px -104px; }

.tnl-channel-icon { width: 12px; height: 12px; background-position: -104px -96px; }

.tnl-red-box-left { width: 3px; height: 18px; background-position: -132px -170px; }
.tnl-red-box-right { width: 22px; height: 18px; background-position: -135px -170px; }

.tnl-paperclip-sm { width: 16px; height: 16px; background-position: -64px -160px; }

}