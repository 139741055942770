@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .observation-message-text {
        font-size: 16px;
        padding-top: 50px;
    }

    .tnl-obs-point-summary {
        float: right;
        text-align: right;
    }

    .tnl-obs-total-points {
        float: left;
        display: inline-block;
        margin-right: 4px;
    }

    .tnl-obs-point-total {
        text-align: left;
    }

    .tnl-hr-radio-td {
	    padding: 5px 15px;
	    border: 1px solid #ccc;
	    cursor: pointer;
    }

    .tnl-hr-radio-td input {
	    margin-right: 5px;
	    vertical-align: middle;
    }

    .tnl-score-icon {
	    padding: 2px 4px;
	    color: #fff;
	    border: 1px solid @COLOR-TNL-GRAY1;
	    display: inline-block;
	    font-size: 13px;
	    margin: 1px 5px 1px 1px;
    }

    .tnl-score-td {
	    text-align: center;
	    vertical-align: top;
    }

    .tnl-table-category-score {
	    background: @COLOR-TNL-GRAY3;
	    color: #fff;
        .COS-OPEN-SANS-600();
    }

    .tnl-cat-td {
	    padding: 5px;
	    font-size: @COS-FONT-SIZE-DEFAULT;
	    width: 100%;
        .COS-OPEN-SANS-600();
    }

    .tnl-subcat-td {
	    padding: 6px 0 6px 5px;
        .COS-OPEN-SANS-600();
    }

    .tnl-question-td {
	    padding: 6px 0 6px 20px;
    }

    .tnl-view-hide-link {
	    cursor: pointer;
	    color: @COLOR-TNL-BLUE1;
	    font-size: 14px;
    }

    .tnl-obv-sec-heading {
        padding: 4px; 
        background: @COLOR-TNL-GRAY4; 
        border: 1px solid @COLOR-TNL-GRAY3; 
        border-bottom: 2px solid @COLOR-TNL-GRAY3;
	    color: @COLOR-TNL-GRAY1;
    }

    .tnl-obv-container {
	    width: 100%;
	    display: table;
	    border-collapse: collapse;
    }

    .tnl-obv-row {
	    clear: both;
	    width: 100%;
	    display: table-row;
    }

    .tnl-obv-nav-on,
    .tnl-obv-nav-left {
	    width: 300px;
	    vertical-align: top;
	    padding: 0;
	    position: absolute;
	    overflow: auto;
	    max-height: 400px;
	    height: auto;
	    z-index: 1;
        top: 136px!important;
    }
    @media (max-width: @BOOTSTRAP-MD) {
        .tnl-obv-nav-on,
        .tnl-obv-nav-left {
            left: 10px;
        }
    }

    .tnl-obv-nav-right {
	    vertical-align: top;
	    margin-left: 305px;
    }

    .tnl-obv-nav-off {
	    width: 0;
	    padding: 0;
    }

    .tnl-obv-sec-text {
	    font-size: 14px;
        .COS-OPEN-SANS-600();
    }

    .tnl-obv-note-edit {
	    padding: 5px;
	    background: #FDEBB5;
	    border: 1px solid @COLOR-TNL-GRAY4;
    }

    .tnl-obv-note-view {
	    white-space: pre-wrap; /* css-3 */
	    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
	    white-space: -o-pre-wrap; /* Opera 7 */
	    word-wrap: break-word;
	    font-size: 14px;
	    *white-space: normal;
	    display: inline-block;
	    margin-left: 5px;
    }

    .tnl-obv-note-authorship {
	    color: @COLOR-TNL-GRAY1;
    }

    @media print{
	    .tnl-obv-note-authorship {
		    color: black;
	    }
    }

    .tnl-obv-save-message {
	    display: inline-block;
	    padding: 0 3px;
	    margin-bottom: -2px;
	    vertical-align: text-bottom;
	    cursor: default;
    }

    .tnl-obv-note-save-bar-top {
	    float: right;
	    margin: 5px 1px 5px 5px;
    }

    .tnl-obv-note-save-bar {
	    text-align: center;
	    margin: 5px;
    }

    .tnl-obv-enter-note-button-bar {
	    padding: 8px;
	    margin-bottom: -1px;
    }

    .tnl-focus-toggler {
	    display: inline-block;
	    height: 16px;
	    padding: 3px 2px 0 5px;
	    width: 57px;
    }

    .tnl-focus-toggler-on {
	    border: 1px solid @COLOR-TNL-GRAY1;
	    color: #fff;
	    background: @COLOR-TNL-GREEN1;
    }

    .tnl-focus-toggler-off {
	    border: 1px solid @COLOR-TNL-GRAY4;
	    color: @COLOR-TNL-GRAY4;
	    cursor: pointer;
    }

    .tnl-focus-toggler-hover {
        background: #FFF3BD;
        border: 1px solid @COLOR-TNL-GRAY2;
        color: @COLOR-TNL-GRAY1;
    }

    .tnl-obv-focus-marker {
	    border: 1px solid @COLOR-TNL-GRAY4;
	    position: absolute;
	    z-index: 1;
	    height: 20px;
	    width: 450px;
	    float: left;
	    background: #FFF3BB;
    }

    .tnl-obv-title {
	    font-size: @COS-FONT-SIZE-DEFAULT;
	    padding: 5px 0;
	    display: inline-block;
        .COS-OPEN-SANS-600();
    }

    .tnl-focus-icon {
	    padding: 2px 8px;
	    display: inline-block;
	    background: #FFF3BB;
	    border: 1px solid @COLOR-TNL-GRAY4;
	    font-size: @COS-FONT-SIZE-DEFAULT;
	    color: @COLOR-TNL-GRAY1;
	    margin: 10px 10px 0 0;
	    border-bottom: 0;
    }

    .tnl-obv-for-by-heading {
	    padding-bottom: 3px;
	    font-size: 16px;
	    margin-bottom: 5px;
        .COS-OPEN-SANS-600();
    }

    .tnl-obv-score-table {
	    width: 100%;
	    border-collapse: collapse;
    }

    .tnl-obv-score-table td {
	    vertical-align: top;
    }

    .tnl-obv-aform-title {
	    width: 15%;
	    vertical-align: top;
	    background-color: @COLOR-TNL-GRAY6;
	    text-align: right;
	    padding: 4px;
    }

    .tnl-obv-aform-field {
	    width: 85%;
	    vertical-align: top;
	    background-color: #fff;
	    padding: 5px;
    }

    .tnl-obv-aform-table {
	    width: 100%;
	    border-collapse: collapse;
	    margin-bottom: 5px;
    }

    .tnl-obv-status-sm {
	    border: 1px solid @COLOR-TNL-GRAY2;
	    padding: 1px;
	    text-align: center;
	    float: left;
	    width: 13px;
    }

    .tnl-bov-status {
	    border: 1px solid @COLOR-TNL-GRAY2;
	    padding: 2px;
	    text-align: center;
	    float: left;
	    width: 15px;
    }

    .tnl-obv-status-text {
	    padding: 2px;
	    margin-left: 26px;
	    font-size: @COS-FONT-SIZE-DEFAULT;
        .COS-OPEN-SANS-600();
    }

    .tnl-obv-status-not {
	    background: #FEE17A;
    }

    .tnl-obv-status-progress {
	    background: #3B7AA9;
	    color: #fff;
    }

    .tnl-obv-status-complete {
	    background: @COLOR-COMPLETE;
	    color: #fff;
    }

    .tnl-obv-status-locked {
	    background: @COLOR-DANGER;
	    color: #fff;
    }

    .tnl-rubric-header {
        .COS-OPEN-SANS-600();
	    background: @COLOR-TNL-GRAY4;
	    font-size: @COS-FONT-SIZE-DEFAULT;
	    border-left: 1px solid @COLOR-TNL-GRAY4;
	    border-bottom: 1px solid @COLOR-TNL-GRAY4;
	    text-align: center;
	    padding: 2px;
    }

    .tnl-rubric-cell {
	    background: #fff;
	    border-left: 1px solid @COLOR-TNL-GRAY4;
	    vertical-align: top;
	    font-size: @COS-FONT-SIZE-DEFAULT;
	    padding: 2px;
    }

    .tnl-view-rubric-link {
	    cursor: pointer;
	    color: @COLOR-TNL-BLUE1;
	    margin-bottom: 3px;
	    width: 75px;
    }

    .tnl-view-sc-rubric-link {
	    cursor: pointer;
	    color: @COLOR-TNL-BLUE1;
	    margin-bottom: 3px;
	    width: 75px;
    } 

    .tnl-observation-required-answer-row {
	    background-color: @COLOR-WARNING !important;
	    font-weight: bold; 	
    }

    .tnl-observation-display .sub-category {
        margin: 20px 0 0 20px;
    }

    .tnl-observation-display .sub-category .tnl-container-header h1 {
        font-size: @COS-FONT-SIZE-16;
        .COS-OPEN-SANS-600();
    }

    .tnl-observation-display .question .tnl-container-header h1 {
	    font-size: @COS-FONT-SIZE-DEFAULT;
	    font-weight: normal;
    }

    .tnl-observation-display .question {
        margin: 0 0 0 20px;
    }

    .tnl-observation-take {
        .LESS-BOX;
	    display: inline-block;
        margin: 0 0 10px 10px;
        width: 160px;
        padding: 10px;
        .PM-ROUNDED-CORNERS();

        &:first-child {
            margin-left: 0;
        }

        .tnl-container-header {
            position: relative;
            margin-bottom: 10px;
        }

        .tnl-container-header .actions {
            display: inline-block;
            position: absolute;
            top: 4px;
            left: 0;
        }

        .tnl-container-header h1 {
            margin: 0 0 0 20px;
            padding: 0;
            .LESS-ELLIPSIS-ONE-LINE();
        }

        .tnl-link-list {
	        margin-left: 5px;
        }

        .tnl-link-list a {
	        display: block;
            .LESS-ELLIPSIS-ONE-LINE();
        }

        .reviewer {
            margin: 0 0 10px;
            padding: 0;
            list-style-type: none;
            line-height: 15px;
            .LESS-ELLIPSIS-ONE-LINE();
        }

        .reviewer .name {
            .LESS-ELLIPSIS-ONE-LINE();
        }

        .reviewer .date {
            color: @COLOR-TNL-GRAY3;
            font-size: @COS-FONT-SIZE-DEFAULT;
            .LESS-ELLIPSIS-ONE-LINE();
        }

        .reviewer > li,
        .reviewer a {
            margin: 0;
            padding: 0;
            .LESS-ELLIPSIS-ONE-LINE();
        }

    }

    .tnl-obs-sum {
	    min-width: 1009px;
    }

    .ui-dialog .tnl-obs-sum {
	    min-width: 1015px;
    }

    .tnl-obs-sum-head {
	    font-size: 20px;
	    font-weight: normal;
    }

    .tnl-obs-sum-contain {
	    margin-bottom: 25px;
	    padding: 15px;
	    border: 1px solid @COLOR-TNL-GRAY3;

        h2 {
	        margin: 30px 0 0;
	        padding: 0;
	        color: @COLOR-TNL-GRAY1;
	        font-size: @COS-FONT-SIZE-18;
	        font-weight: normal;
	        line-height: 18px;
	        border: none;
        }

        h2:first-child {
	        margin: 0;
        }

        h2 span {
	        color: @COLOR-COMPLETE;
	        font-size: @COS-FONT-SIZE-12;
        }

        > p {
	        font-size: @COS-FONT-SIZE-DEFAULT;
	        line-height: 18px;
        }

    }

    .tnl-obs-sum-item {
	    width: 100%;
	    margin: 0 auto;
    }
    
    .tnl-obs {
	    display: inline-block;
	    .LESS-FIX-IE7-INLINE-BLOCK();
	    position: relative;
	    margin: 5px 1px 0;
	    padding: 8px;
	    min-width: 140px;
	    font-size: @COS-FONT-SIZE-DEFAULT;
	    text-align: left;
	    background: #fff;
	    border: 1px solid @COLOR-TNL-GRAY3;
	    vertical-align: top;

        .tnl-obs-head {
	        margin: 5px 0 10px;
	        color: @COLOR-TNL-GRAY1;
	        font-size: @COS-FONT-SIZE-DEFAULT;
            .COS-OPEN-SANS-600();
        }

        .tnl-obs-item {
	        margin-bottom: 10px;
	        padding: 0;
	        line-height: 15px;
        }
    
        .tnl-obs-item > span {
	        display: inline-block;
	        position: relative;
	        left: 25px;
	        margin-right: 30px;
            .LESS-FIX-IE7-INLINE-BLOCK();
        }

        .tnl-obs-item >span > textarea {
	        width: 80%;
	        margin-top: 10px;
	        resize: vertical;
	        font-size: @COS-FONT-SIZE-12;
        }

    }

    .tnl-observation-total {
	    padding: 4px 6px;
	    color: #fff;
	    font-size: @COS-FONT-SIZE-12;
	    line-height: 12px;
	    border: 1px solid #fff;	
	    position: absolute;
    }

    .tnl-check {
	    left: 15px;
	    margin-top: 2px;
    }

    .tnl-obs-observable {
	    font-size: @COS-FONT-SIZE-12;
	    color: @COLOR-COMPLETE;
    }

    .tnl-obs-sum-contain-comments {
        margin: 10px 0;
        text-align: left;
        resize: vertical;
        min-height: 100px;
        -ms-word-wrap: break-word;  
        word-wrap: break-word;     
       -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        display: block;
	    width: 100%;
	    font-size: @COS-FONT-SIZE-12;
	    color: @COLOR-TNL-GRAY1;
    }

    .tnl-obs-sum-contain-comments-head {
	    display: block;
	    color: @COLOR-TNL-GRAY2;
	    font-size: @COS-FONT-SIZE-DEFAULT;
        .COS-OPEN-SANS-600();
    }

    #scoreCell {
	    margin: 0;
	    padding: 0 20px 0 0;

	    #scoringTable > tbody > tr:first-child th {
	    	padding-right: 11px;
	    }

    }

    @media (max-width: 1024px) and (min-width: 1001px) {
	    .tnl-obs-sum {
		    min-width: 995px;
	    }
	    .tnl-observations {
		    padding: 10px;
	    }
	    .tnl-obs {
		    display: inline-block;
		    padding: 5px;
		    margin: 2px;
		    min-width: 140px;
		    font-size: @COS-FONT-SIZE-12;
            .LESS-FIX-IE7-INLINE-BLOCK();
	    }
	    .tnl-obs .tnl-obs-item {
		    line-height: 13px;
	    }
	    .tnl-obs .tnl-obs-item > span {
		    left: 24px;
		    margin-right: 25px;
	    }
	    .tnl-obs-item .tnl-check {
		    left: 6px;
		    margin-top: 0;
	    }
    }

    .tnl-observation-standard-score span {
	    display: inline-block;
	    margin: 2px;
	    padding: 5px 0;
	    width: 30px;
	    color: #fff;
	    font-size: @COS-FONT-SIZE-DEFAULT;
	    border-radius: 2px;
	    border: 2px solid #fff;
        .LESS-FIX-IE7-INLINE-BLOCK();
        .PM-ROUNDED-CORNERS();
	    .LESS-BOX-SHADOW();
    }

    .tnl-observation-article-header h1 {
	    margin: 0 0 15px;
	    padding: 0;
	    font-size: @COS-FONT-SIZE-12;
    }

    .tnl-table.observation {

        .category {
            padding: 20px 10px 4px;
            color: @COLOR-TNL-GRAY2;
            font-size: @COS-FONT-SIZE-DEFAULT;
            background-color: #fff;
            border: none;
            border-bottom: 4px double @COLOR-TNL-GRAY4 !important;
            vertical-align: bottom;
        }

        .header {
	        color: #fff;
	        font-size: @COS-FONT-SIZE-12;
	        text-align: center;
	        vertical-align: bottom;
            .COS-OPEN-SANS-600();
        }

        .category,
        .header {
	        border-color: #fff !important;
        }

        .category:first-child,
        .header:first-child {
	        border-top: none;
        }

        .subcategory {
	        font-weight: normal;
            font-size: @FONT-SIZE-DEFAULT;
            line-height: 18px;
            padding: 10px;
        }

        .question {
	        font-size: @COS-FONT-SIZE-DEFAULT;
        }

        > tbody > tr > td,
        > tr > td {
            border-bottom: none;
        }
    }
}