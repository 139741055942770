@import (reference) "_global.less";

.empari {

    .tnl-tree-node {
        list-style-type:none;
    }

    .tnl-tree-node > li {
        background-repeat: no-repeat;
        margin-left: 0;
        padding-left: 20px;
        padding-bottom: 5px;
        cursor: pointer;
    }

    .tnl-tree-node-expand {
        background-image: url(/images/plus-big.gif);
    }

    .tnl-tree-node-collapse {
        background-image: url(/images/minus-big.gif);
    }

}