@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .tnl-calendar {
        display: inline-block;
        position: relative;
        margin-bottom: -5px;

        .content {
            display: inline-block;
            position: relative;
            top: 0;
            margin-bottom: 10px;
            border: 1px solid #bbb;
            z-index: 1;
            border-radius: 7px;
            overflow: hidden;
            -webkit-box-shadow: 0 1px 1px 0 rgba(0,0,0,.6);
            -moz-box-shadow:    0 1px 1px 0 rgba(0,0,0,.6);
            box-shadow:         0 1px 1px 0 rgba(0,0,0,.6);
            .PM-ROUNDED-CORNERS();
        }
    }

    .calendar > .tnl-container-content > .calendar {
        float: left;
        width: 224px;
    }

    .item-overdue {
        margin-bottom: 5px;
        color: @COLOR-PM-WHITE1;
        background: @COLOR-REQUIRED;

        &:hover {
            background: @COLOR-REQUIRED;
            cursor: pointer;
        }

        a {
            color: @COLOR-PM-WHITE1;
            font-weight: bold;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .tnl-cal-sm {
        margin: 0;
        height: 210px;
        width: 216px;
        line-height: 15px;
        background: none;

        tr {
            background: @COLOR-PM-WHITE1;
        }

        th {
            width: 14%;
            color: @COLOR-TNL-GRAY2 !important;
            font-size: 11px !important;
            font-weight: normal;
            text-align: center;
            .LESS-BACKGROUND;
            border-bottom: 1px solid @COLOR-TNL-GRAY4;
        }

        td {
            text-align: center;
        }

        td,
        td a {
            font-size: 13px;
        }

    }

    .tnl-cal-header {
        background-color: @COLOR-PM-GREY7;
        height: 30px;

        .cos-cal-header-text {
            display: block;
            text-align: center;
            height: 30px;
            line-height: 30px;

            a,
            a:visited {
                font-size: @COS-FONT-SIZE-DEFAULT;
                color: @COLOR-PM-WHITE1;
                .COS-OPEN-SANS-600();
            }

            a:hover {
                background-color: transparent;
                text-decoration: underline;
            }
        }
    }

    .tnl-cal-end,
    .tnl-cal-empty {
        background-color: @COLOR-PM-WHITE1;
    }

    .tnl-cal-today,
    .tnl-cal-event {
        background-color: @COLOR-PM-WHITE1;

        .cos-cal-date {
            position: relative;
            left: 50%;
            margin-left: -14px;
            display: block;
            width: 28px;
            height: 28px;
            border-radius: 100%;
            line-height: 28px;
        }
    }

    .tnl-cal-today {
        .cos-cal-date {
            background-color: @COLOR-PS-BLUE6;
            .COS-OPEN-SANS-600();
            color: @COLOR-PM-WHITE1;
        }

        a,
        a:visited {
            span {
                color: @COLOR-PM-WHITE1;
                .COS-OPEN-SANS-600();
            }
        }

        a:hover {
            span {
                color: @COLOR-PM-WHITE1;
            }
        }
    }

    .tnl-cal-day {
        background: @COLOR-PM-WHITE1;
    }

    .tnl-row-on {
        background: @COLOR-TNL-GRAY6;
        padding-bottom: 4px;
        padding-top: 4px;
        border-bottom: 1px dotted @COLOR-TNL-GRAY4;
    }

    .tnl-cal-event {
        .cos-cal-date {
            background: @COLOR-TNL-GRAY5 !important;
            color: @COLOR-TNL-BLUE1 !important;

            a {
                span {
                    .COS-OPEN-SANS-600();
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .tnl-row-off {
        background: @COLOR-PM-WHITE1;
        padding-bottom: 4px;
        padding-top: 4px;
        border-bottom: 1px dotted @COLOR-TNL-GRAY4;
    }

    .tnl-event-detail {
        font-size: 14px;
        margin-left: 20px;
    }

    .tnl-event-heading {
        padding: 4px;
        border-bottom: 1px solid @COLOR-TNL-BLUE1;
        .COS-OPEN-SANS-600();
    }

    .tnl-cal-key td {
        padding: 2px;
    }

    .tnl-cal-display-start-date {
        font-size: 13px;
        color: @COLOR-TNL-GRAY2;
    }

    .item-overdue .tnl-cal-display-start-date {
        color: @COLOR-PM-WHITE1;
    }

    .tnl-calendar-teaser .tnl-pre-event {
        margin: 5px 0 0;
    }

    .tnl-cal-legend-item {
	    margin: 8px 0;
    }

    .tnl-cal-legend-color {
	    height: 20px; 
	    width: 20px;
	    margin-right: 10px;
    }


    .calendarBorderTable,
    .calendarMonthYearTable,
    .calendarBlankDates,
    .calendarNormalDay,
    .calendarHoverDay,
    .calendarDisabledDay {
        background-color: @COLOR-PM-WHITE1;
    }

    .calendarMonthYearTable {
        height: 35px;
        margin:0;
        padding:0;

        .cos-cal-popup-arrow {
            display: inline-block;
            cursor: pointer;
            font-size: 26px;
            height: 26px;
            width: 20px;

            &:hover {
                color: @COLOR-PS-BLUE6;
            }

            &.prev-year {
                padding-left: 10px;
            }

            &.next-year {
                padding-right: 10px;
            }
        }

        .cos-cal-popup-month-year {
            text-align: center;
            font-size: @COS-FONT-SIZE-18;
            .COS-OPEN-SANS-700();
        }
    }

    .calendarNormalDay,
    .calendarHoverDay,
    .calendarDisabledDay {
        cursor: pointer;

        span {
            position: relative;
            left: 50%;
            margin-left: -20px;
            display: block;
            text-align: center;
            height: 40px;
            width: 40px;
            line-height: 40px;
            border-radius: 100%;
            font-size: @COS-FONT-SIZE-18;
            .COS-OPEN-SANS-600();
        }
    }

    .calendarNormalDay,
    .calendarHoverDay {

        &:active,
        &:focus {
            span {
                outline: none;
                -webkit-box-shadow: 0 0 0 1px #00F5FF;
                -moz-box-shadow:    0 0 0 1px #00F5FF;
                -ms-box-shadow:     0 0 0 1px #00F5FF;
                -o-box-shadow:      0 0 0 1px #00F5FF;
                box-shadow:         0 0 0 1px #00F5FF;
            }
        }
    }

    .calendarNormalDay {
        span {
            background-color: @COLOR-PM-WHITE1;
        }
    }

    .calendarHoverDay {
        span {
            background-color: #f1f1f1;
        }
    }

    .calendarSelectedDay {
	    cursor: pointer;

        span {
            background-color: @COLOR-PS-BLUE6;
            color: @COLOR-PM-WHITE1;
            position: relative;
            left: 50%;
            margin-left: -20px;
            display: block;
            text-align: center;
            height: 40px;
            width: 40px;
            line-height: 40px;
            border-radius: 100%;
            font-size: @COS-FONT-SIZE-18;
            .COS-OPEN-SANS-600();
        }

        &:hover {
            span {
                background-color: @COLOR-PS-BLUE6;
            }
        }
    }

    .calendarDisabledDay {
	    cursor: default;

        span {
            color: #999;
            background-color: @COLOR-PM-WHITE1;
            .COS-OPEN-SANS-REGULAR();
        }
    }

    .cos-cal-calendar-title-row {
        background-color: @COLOR-PM-GREY7;

        .calendarTitle {
            cursor: default;

            h3 {
                margin: 0;
                padding: 10px;
                color: @COLOR-PM-WHITE1;
            }
        }

        .cos-cal-popup-close-icon {
            cursor:pointer;
            padding: 8px 10px;

            .pm-icon-sm {
                position: relative;
                float: right;

                [class^="pm-icon-"] {
                    color: @COLOR-PM-WHITE1;

                    &:hover {
                        opacity: .75;
                        .PM-TRANSITION();
                    }
                }
            }
        }
    }

    .calendarMainTable {
        width: 380px;
        height: 390px;
        z-index: 9000;
        background-color: @COLOR-PM-WHITE1;
    }

    .calendarMainTable tbody > tr > td,
    .calendarMainTable > tr > td {
	    padding: 0;
    }

    .calendarBorderTable {
        width: 100%;
        height: 100%;

        .cos-cal-datepicker-dow {
            height: 20px;
            cursor: default;

            td {
                .COS-OPEN-SANS-600();
                background-color: @COLOR-PM-WHITE1;
                font-size: @PM-FONT-SIZE-DEFAULT;
                color: #999;
                text-align: center;
            }
        }
    }

    .cos-cal-full-icon {

        &.tnl-icon-text-link > span {
            display: inline-block;
            height: 3.0rem;
            line-height: 3.0rem;
            top: -8px;
        }
    }

    .cos-cal-teaser-icon {
        border-top: 1px solid #ccc !important;
        padding-top: 15px !important;
        margin-top: 5px;
    }
}