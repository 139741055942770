@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

html,
body {
    margin: 0;
    padding: 0;
    color: @COLOR-COS-DEFAULT;
}

html {
    background: @COLOR-TNL-GRAY5;

    &.busy,
    &.busy * { // TODO: Consider alternate to universal selector
        cursor: wait !important;
    }

    // Fix for IE9 issue described at http://stackoverflow.com/q/5788726/120955
    &.lt-ie10 .ui-dialog-content {
        margin: auto;
    }

    &.lt-ie10 {
        padding: 0;
    }
}

a {
    color: @COLOR-PM-LINK;
    text-decoration: none;
    .PM-TRANSITION();

    small {
        color: @COLOR-PM-LINK;
        text-decoration: none;
        .PM-TRANSITION();
    }

    &:link,
    &:visited {
        color:@COLOR-PM-LINK;
        text-decoration: none;
    }

    &:hover {
        .PM-TRANSITION();
        color: @COLOR-PM-LINK-H;
        text-decoration:none;

        small {
            .PM-TRANSITION();
            color: @COLOR-PM-LINK-H;
            text-decoration: none;
        }
    }

    &:active {
        .PM-TRANSITION();
        color: @COLOR-PM-LINK-A;
        text-decoration:none;

        small {
            .PM-TRANSITION();
            color: @COLOR-PM-LINK-A;
            text-decoration: none;
        }
    }

    &.disabled,
    &.disabled:hover {
        color: @COLOR-TNL-GRAY4 !important;
        text-decoration:none;
        cursor: not-allowed;

        .icon-font {
            color: @COLOR-TNL-GRAY4 !important;
        }
    }

    &.text-decoration-none {
        text-decoration: none;
    }

}

blockquote {
    margin: 40px 20px;
    padding: 20px;
    color: @COLOR-TNL-GRAY2;
    font-size: @COS-FONT-SIZE-22;
    border-color: @COLOR-TNL-GREEN1;
}

// Avoid using generic ul, ol and dl to render lists.
// Use tnl-ul, tnl-ol and tnl-dl to avoid unwanted CSS on layouts that use ul, ol and dl.
dl,
ul,
ol {
    margin-top: 10px;
    margin-bottom: 20px;
}

ul, ol {
    > li {
        padding-bottom: 2px;
    }
}
ul {
    &.ul-basic {
        display: inline-block;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    ul,
    ul.ul-basic {
        padding-left: 20px;
    }
    > li > ul {
        margin-top: 0;
    }
}

ul.tnl-ul {
    &.basic {
        list-style-type: none;
    }
    li {
        margin-bottom: 5px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
}

dl {
    dt {
        margin-bottom: 2px;
        &:last {
            margin-bottom: 0;
        }
    }
    dd {
        margin-bottom: 20px;
        &:last {
            margin-bottom: 0;
        }
    }

    dt {
        .COS-OPEN-SANS-600();
    }

    ul {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &.dl-horizontal {
        dd {
            margin-bottom: 10px;
        }
        dt {
            margin-bottom: 0;

            @media (min-width: 768px) {
                width: 180px;
                padding-right: 10px;
                white-space: normal;
                overflow: visible;
            }
        }
        dt,
        dd {
            @media (min-width: 768px) {
                margin-bottom: 5px;
            }
        }
    }

}

@media (min-width: 768px) {
    dl.dl-horizontal dd,
    dl.dl-horizontal dt {
        margin-bottom: 30px;
        margin-left: 0;
    }
}

b,
strong {
    .COS-OPEN-SANS-700();
}

table {
    margin: 0;
    padding: 0;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    margin: 0;

    small {
        font-size: 86%;
    }
}


header.tnl-header {
    margin-bottom: 30px;
}

input,
textarea,
select {
	margin: 0;
	padding: 4px;
	color: @COLOR-TNL-GRAY2;
}

label {
    .COS-OPEN-SANS-600();
}

textarea {
    resize: vertical;
}

::-webkit-input-placeholder {
    color: @COLOR-TNL-GRAY3;
}

::-moz-placeholder {
    color: @COLOR-TNL-GRAY3;
}

:-ms-input-placeholder {
    color: @COLOR-TNL-GRAY3;
}

input:-moz-placeholder {
    color: @COLOR-TNL-GRAY3;
}

.tnl-strike {
    text-decoration: line-through;
}

hr,
.tnl-hr {
    border: none;
    border-bottom: 1px solid @COLOR-TNL-GRAY5;
    margin: 2em 0;
}

time {
    display: inline-block;
    cursor: pointer;
    color: @COLOR-TNL-BLUE1;

    &:hover {
        text-decoration: underline;
    }
    &>span.print-only {
        color: @COLOR-TNL-GRAY2;
    }
}

.tnl-dual-column {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
}

.pm-advanced-course-search-form {
    .pm-filter-input {
        select:first-child {
            margin-right: 10px;
        }

        input[type=date], 
        input[type=time], 
        input[type=datetime-local], 
        input[type=month] {
            height: 30px !important;
        }
    }
}

.pm-button-align .pm-outline-btn.pm-btn-lg {
    margin-left: 0;
    margin-bottom: 20px;
}

/*
    This overrides the width generated in bootstrap.css, it fixes image width problems in IE10 and below.
*/
.img-responsive,
.thumbnail > img,
.thumbnail a > img {
    width: auto;
}