@import (reference) "_global.less";

.empari {

    .tnl-corner-top {
        border-radius: 4px 4px 0 0;
    }

    .tnl-corner-bottom {
        border-radius: 0 0 4px 4px;
    }

    .corner-all,
    .corner-left {
        border-radius: 4px 0 0 0;
    }

    .corner-all,
    .corner-right {
        border-radius: 0 4px 0 0;
    }

    .tnl-div-border {
        border: 1px solid @COLOR-TNL-GRAY3;
    }

    .tnl-border-md {
        border:1px solid @COLOR-TNL-GRAY3;
    }

    .tnl-border-lt {
        border:1px solid @COLOR-TNL-GRAY4;
    }

    .tnl-border-dk {
        border:1px solid @COLOR-TNL-GRAY1;
    }

    .tnl-border {
	    border: 1px solid @COLOR-TNL-GRAY3;
    }

    .tnl-border-right {
        border-right: 1px solid @COLOR-TNL-GRAY3;
    }

    .tnl-border-bottom {
        border-bottom:1px solid @COLOR-TNL-GRAY3;
        margin: 15px 0;
        padding-bottom: 15px;
    }

    .tnl-border-top {
        border-top:1px solid @COLOR-TNL-GRAY3;
    }

}
