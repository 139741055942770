@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .tnl-less-corners {
        .PM-ROUNDED-CORNERS();
    }
    .tnl-less-corners-reset {
	    .LESS-ROUNDED-CORNERS-RESET();
    }
    .tnl-less-shadow-outer {
	    .LESS-BOX-SHADOW();
    }
    .tnl-less-shadow-outer-reset {
	    .LESS-BOX-SHADOW-RESET();
    }
    .tnl-less-shadow-popup-menu {
	    .LESS-SHADOW-POPUP-MENU();
    }
    .tnl-less-tnl-background {
	    .LESS-BACKGROUND();
    }
    .tnl-less-tnl-background-total {
	    background: @COLOR-TNL-BLUE3;
    }
    .tnl-less-background-menu {
	    .LESS-BACKGROUND-MENU();
    }
    .tnl-less-fix-ie7-inline-block {
	    .LESS-FIX-IE7-INLINE-BLOCK();
    }

    .tnl-main {
	    margin: 0;
    }

    .tnl-main h1 {
	    color: @COLOR-TNL-GRAY2;
        margin: 0;
        padding: 0;
    }

    .tnl-main h2 {
	    color: @COLOR-TNL-GRAY4;
        margin: 0 0 2em;
        padding: 0;
    }
    .tnl-utilities-panel {
	    margin: 1em;
    }
    .tnl-grid-result-text {
	    color: @COLOR-TNL-GRAY2;
    }
    .tnl-whole-page-layout {
	    margin: 0 auto;
	    max-width: 1400px;
    }
    .tnl-no-results,
    .tnl-no-results a {
	    color:  @COLOR-PM-GREY9;
	    font-size: @COS-FONT-SIZE-DEFAULT;
	    line-height: 14px;
	    text-align: center;
        padding: 15px !important;
    }
    .tnl-additional-note {
	    color: @COLOR-TNL-GRAY3;
	    font-size: @COS-FONT-SIZE-DEFAULT;
    }
    .tnl-hidden {
	    display:none;
    }
    .tnl-float-fix {
	    display: block;
	    clear: both;
	    visibility: hidden;
	    height: 0;
	    width: 0;
    }

    .tnl-panel > h2 {
        background: none repeat scroll 0 0 @COLOR-TNL-GRAY2;
        color: rgb(255, 255, 255);
        padding: 4px;
        font-size: @COS-FONT-SIZE-16;
        margin-bottom: 0;
        border-radius: 4px 4px 0 0;
    }
    .tnl-panel>div{
        border: 1px solid @COLOR-TNL-GRAY4;
        padding: 10px;
        border-radius: 0 0 4px 4px;
    }
    .tnl-panel {
        margin: 5px 5px 15px;
    }
    .left-navigation {
        padding: 0 2px;
        vertical-align: top;
    }
    #tnl-main-table {
        width: 99%;
        border-collapse: collapse;

        .ui-tabs {
            padding: 0 !important;
        }

    }
    #tnl-main-td {
        vertical-align: top;
        width: 100%;
        background: #fff;
    }
    .tnl-portal-column {
        padding: 15px 10px;
        vertical-align: top;
    }
    #tnl-layout-line {
        display: none;
    }
    #outerContainer {
        border-collapse: collapse;
    }
    .tnl-sub-heading-bar {
        background: @COLOR-TNL-GRAY2;
        color: #fff;
        padding: 4px;
        font-size: @COS-FONT-SIZE-DEFAULT;
    }
    .tnl-gp-inline-form-box {
        border: 1px solid @COLOR-TNL-GRAY2;
        background: @COLOR-TNL-GRAY6;
        padding: 10px;
    }
    .innactive {
	    cursor: not-allowed !important;
    }
    #tnl-right-sub-tabs {
        border:0;
    }
    .tabs {
        float: right !important;
    }
    #tnl-right-sub-tabs > ul {
        background:none;
        border:0;
        .LESS-ROUNDED-CORNERS-RESET();
        border-bottom: 1px solid @COLOR-TNL-GRAY3;
    }
    #tnl-right-sub-tabs li a {
        padding:4px 8px;
    }

    select.staffSiteSelect,
    .tnl-table-filter select,
    input.staffSiteSelect,
    .tnl-table-header input {
	    font-size: @COS-FONT-SIZE-DEFAULT;
        border-radius: 4px;
        border: 1px solid #a9a9a9;
        box-shadow: none !important;
        padding: 7px;

        &:focus {
            outline: none !important;
        }
    }

    .tnl-table-filter {
        select[name="operationSelect"] {
            margin: 0 5px 0 7px;
        }

        select[name="valueSelect"] {
            margin: 0 5px;
        }

        .pm-filter-input {
            input[type="text"] {
                min-height: 20px;
                padding-top: 8px;
                margin-left: 5px;
            }

            input.ui-autocomplete-input {
                margin-left: 1px !important;
            }
        }
    }

    .error,
    .tnl-error-text {
        color: @COLOR-REQUIRED;
    }
    .tnl-input-validation-error {
        border: 2px solid @COLOR-REQUIRED;
        background-color: @COLOR-REQUIRED-LT;
    }
    .rowheight32 {
        height: 32px;
    }
    .pdfModalDialog {
        font-size: @COS-FONT-SIZE-DEFAULT;
        white-space: nowrap;
    }
    div.size-checking {
        position: relative;

    }
    div.size-checking > div {
        position: absolute;
        top: -30000px;
    }
    .size-checking .tnl-form-table {
        width: auto;
    }
    .tnl-expand-link {
        cursor: pointer;
        margin-left: 3px;
        height: 18px;
        padding: 2px 0 2px 0;
    }
    .tnl-expand-link-plus {
        background: url(/images/plus-big.gif) no-repeat scroll 0 2px;
        cursor: pointer;
        margin-left: 3px;
        height: 18px;
        padding: 2px 0 2px 18px;
    }
    .tnl-expand-link-minus {
        background:url(/images/minus-big.gif) no-repeat scroll 0 2px ;
    }
    .tnl-loading-div {
        background: no-repeat 50% 50% #fff url(/images/loading/loading2.gif);
        border: 1px solid @COLOR-TNL-GRAY4;
        .PM-ROUNDED-CORNERS();
        height: 120px;
        margin: 10px;
    }
    .tnl-group-list {
        width: 250px;
        font-size: @COS-FONT-SIZE-DEFAULT;
        padding: 2px;
    }
    .tnl-hidden-submit {
        border: 0 none;
        height: 0;
        width: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }
    .tnl-summary-panel-group > .panel > .panel-heading .tnl-icon-md,
    .tnl-summary-panel-group > .panel > .panel-heading h4 {
        display: inline-block;
    }
    .tnl-summary-panel-group > .panel > .panel-heading h4 {
        position: relative;
        top: -5px;
        left: 5px;
    }
    td.section-list-avail {
        background: #fff;
        color: #000;
        font-size: @COS-FONT-SIZE-DEFAULT;
        .COS-OPEN-SANS-600();
    }
    td.section-list-wait {
        background: #fff;
        font-size: @COS-FONT-SIZE-DEFAULT
    }
    td.section-list-full {
        background: @COLOR-TNL-GRAY6;
        color: @COLOR-TNL-GRAY2;
        font-size: @COS-FONT-SIZE-DEFAULT;
    }
    td.section-list-onwait {
        background: @COLOR-WARNING;
        color: @COLOR-TNL-GRAY2;
        font-size: @COS-FONT-SIZE-DEFAULT;
        a:hover{
            color: #fff;
        }
    }
    .share-icon {
        background: url(/images/share.png) no-repeat;
        float: right;
        width: 40px;
        height: 20px;
        vertical-align: top;
        padding-left: 22px;
        padding-top: 2px;
        margin-top:-47px;
        margin-right: -100px;
    }
    .back-to-section{
        display: -moz-inline-box;
    }
    .tnl-calibration-intitle {
        width: 15%;
        vertical-align: top;
        background-color: @COLOR-TNL-GRAY6;
        padding: 5px;
        text-align: right;
    }
    .tnl-calibration-infield {
        width: 85%;
        vertical-align: top;
        background-color: #fff;
        padding: 5px;
    }
    .tnl-calibration-target .tnlif {
   	    color: #fff;
    }
}

// There appears to be an issue with wrapping to much CSS
.empari {
    .tnl-score-div {
        border: 1px solid @COLOR-TNL-GRAY2;
        color: #fff;
        display: inline-block;
        font-size: @COS-FONT-SIZE-DEFAULT;
        height: 11px;
        padding: 3px;
        text-align: center;
        width: 11px;
    }
    .tnl-target-score-div {
        background: @COLOR-DANGER;
    }
    .tnl-actual-score-div {
        background: @COLOR-TNL-BLUE2;
    }
    .enabledinput {
        background-color: #fff;
        color: #000;
        margin: 0;
    }
    .disabledinput {
        background-color: @COLOR-TNL-GRAY4;
        color: @COLOR-TNL-GRAY3;
        margin: 0;
    }
    .bgwhite {
        background: #fff;
    }
    div.header-bar {
        background-color: @COLOR-TNL-GRAY3;
        display: block;
        font-size: @COS-FONT-SIZE-DEFAULT;
        padding: .1em;
        text-align: right;
        .COS-OPEN-SANS-600();
    }
    div.header-bar.left {
        text-align: left;
    }
    div.header-bar.both {
        height: 2em;
    }
    div.header-bar.both div.left {
        float: left;
        padding: .4em;
        text-align: left;
    }
    div.header-bar.both div.right {
        float: right;
        padding: .6em;
        text-align: right;
    }
    div#leftcol h3, div#rightcol h3 {
        .COS-OPEN-SANS-600();
        margin-bottom: 3px;
        margin-left: 5px;
    }
    div#leftcol {
        float: left;
        margin-left: 2%;
        width: 57%;
    }
    div.channel {
        background-color: #fff;
        border: 1px solid @COLOR-TNL-GRAY3;
        padding: 2px;
    }
    div.channel div.topbar {
        .COS-OPEN-SANS-600();
        background-color: @COLOR-TNL-GRAY5;
        min-height: 14px;
        padding: 3px;
    }
    div.channel div.btnbar {
        background-color: @COLOR-TNL-BLUE2;
        min-height: 16px;
        padding: 3px;
    }
    table.channel {
        padding: 10px 0;
    }
    div#rightcol {
        float: right;
        margin-right: 1%;
        width: 38%;
    }
    div.clear {
        clear: both;
        padding: 10px;
    }
    .req,
    .tnl-required-text {
        color: @COLOR-REQUIRED !important;
        .COS-OPEN-SANS-600();
    }
    .tnl-negative-money-amount{color: @COLOR-PM-RED !important;}
    .teaser-announcements-bgcolor {
        background: #fff;
    }
    .header-bg {
        background: @COLOR-TNL-BLUE2;
    }
    .required-training {
        background: @COLOR-TNL-GRAY5;
    }
    .recommended-training {
        background: #fff;
    }
    .buttonlink {
        color: #000;
        text-decoration: none;
    }
    .home-box {
        background-color: @COLOR-TNL-GRAY5;
        border: 1px solid #ccc;
        font-size: @COS-FONT-SIZE-12;
        margin: 5px;
        padding: 3px;
        .COS-OPEN-SANS-600();
    }
    .home-link-text {
        #Fonts > .SansSerif;
        font-size: @COS-FONT-SIZE-DEFAULT;
        text-decoration: none;
        .COS-OPEN-SANS-600();
    }
    .focus-channel-header {
        background: @COLOR-TNL-GRAY2;
    }
    div.portfolio_list ul {
        border-left: 1px dashed @COLOR-TNL-GRAY3;
        display: none;
        list-style: none;
        margin-bottom: 0;
        margin-left: 10px;
        margin-top: 0;
        padding-left: 20px;
    }
    div.portfolio_list a {
        display: block;
        padding: 1px;
    }
    div.portfolio_list a:hover {
        background-color: @COLOR-TNL-GRAY5;
    }
    div.portfolio_list img {
        margin: 3px 5px 3px 2px;
    }
    div.portfolio_list ul li {
        padding: 2px;
    }
    .leftnavbar {
        background: @COLOR-TNL-GRAY2;
    }
    .channelbgcolor {
        background: @COLOR-TNL-GRAY5;
    }
    .channelbottombutton {
        background: #fff;
    }
    .div-display {
        // Do not put display: relative here to avoid drop down menu overflow issue
        background: white;
        //border: 1px solid @COLOR-TNL-GRAY4;
        //border-top: 1px solid @COLOR-TNL-GRAY4;
        margin-right: 5px;
        margin-top: 5px;
        text-align: left;
        vertical-align: top;
    }
    .sub-table-box {
        background-color: #efefef;
        background-position: 0 0;
        border-width: 1px 3px 3px 1px;
        border-style: solid;
        border-color: @COLOR-TNL-GRAY5;
        font-size: @COS-FONT-SIZE-16;
        margin: 5px;
        text-align: center;
    }
    .details {
        background: @COLOR-TNL-BLUE3;
    }
    .scroll-box {
        height: 250px;
        overflow: auto;
        -ms-scrollbar-3dlight-color: @COLOR-TNL-GRAY3;
        scrollbar-3dlight-color: @COLOR-TNL-GRAY3;
        -ms-scrollbar-arrow-color: @COLOR-TNL-GRAY2;
        scrollbar-arrow-color: @COLOR-TNL-GRAY2;
        -ms-scrollbar-darkshadow-color: @COLOR-TNL-GRAY2;
        scrollbar-darkshadow-color: @COLOR-TNL-GRAY2;
        -ms-scrollbar-face-color: @COLOR-TNL-GRAY5;
        scrollbar-face-color: @COLOR-TNL-GRAY5;
    }
    div.block {
        display: block;
        text-align: center;
    }
    .clearfix:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }
    .clearfix {
        display: block;
        height: 1%;
    }
    .float_menu_container {
        font-size: @COS-FONT-SIZE-DEFAULT;
        line-height: normal;
    }
    .feed_options {
        margin-top: -9px;
    }
    .feed_options a {
        background: url(/images/story_editor_icon.gif) no-repeat center 0;
        height: 18px;
        display: block;
        font-size: @COS-FONT-SIZE-10;
    }
    .feed_options a:hover {
        background-position: center -18px;
        visibility: visible;
    }
    .hover .feed_options a {
        visibility: visible;
    }
    .bg-center {
        min-width: 480px;
        padding-top: 15px;
        padding-bottom: 15px;
        *margin-top: 15px;
    }
    .bread-text {
        color: #000;
        font-size: @COS-FONT-SIZE-12;
    }
    .bread-text:hover {
        color: #000;
    }
    .bread-text:visited {
        color: #000;
    }
    .status-img {
        position: relative;
        margin: -8px 4px 0 0;
        *margin: -3px 0 0;
    }
    .activity-title {
        font-size: @COS-FONT-SIZE-16;
        .COS-OPEN-SANS-600();
    }
    .activity-text {
        margin: 4px;
    }
    .activity-count {
        font-size: @COS-FONT-SIZE-18;
        vertical-align: top;
        text-align: right;
    }
    .activity-arrow-right {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid @COLOR-TNL-GREEN1;
    }
    .tnl-add-attachment {
        cursor: pointer;
    }
    .tnl-app-menu-div {
        border: 1px solid @COLOR-TNL-GREEN1;
        border-top: 0;
        margin-left: 0;
        margin-top: 6px;
        z-index: 1;
        padding: 4px;
        display: none;
    }

    //reset for RTE contents. I don't want to break anything so make it pretty relaxed for now.
    .tnl-rich-text-read-only{
        span{
            all: unset
        }
    }

    .tnl-rich-text-read-only {
        min-height: 25px;
        max-height: 120px;
        overflow: auto;
    }
    .tnl-header {
        position: relative;
        z-index: 5;
        height: 33px;
        color: @COLOR-COS-DEFAULT;
        margin-bottom: 25px;
        background: transparent;

        .title {
            position: relative;
            top: -4px;
            margin: 0;
            color: @COLOR-COS-DEFAULT;
            font-size: @COS-FONT-SIZE-22;
            .COS-OPEN-SANS-600();
        }
        .title:first-child {
            top: 4px;
        }
        > .tnl-header-tools {
            float: right;
            padding: 10px 10px 0 0;

            span.fa {
                .PM-TRANSITION();

                &:hover {
                    color: @COLOR-PM-LINK-H;
                }

                &:active {
                    color: @COLOR-PM-LINK-A;
                }
            }
        }
        > .tnl-header-tools > span {
            margin-right: 5px;
        }

        .tnl-advanced-popup-panel {
            .tnl-icon-md {
                background: transparent !important;
                .PM-TRANSITION();

                &:hover {
                    color: @COLOR-PM-LINK-H;
                    .PM-TRANSITION();
                }

                &:active {
                    color: @COLOR-PM-LINK-A;
                }
            }

            &.menu-highlight {
                background: transparent !important;
            }
        }
    }

    html.lt-ie8 .tnl-header > .tnl-header-tools {
        position: relative;
        top: -10px;
        padding-top: 0;
    }
    .tnl-search-form {
        margin-bottom: 4em;
    }
    .review-panel {
        margin: 3em 0 1em;
        padding: 3em 0 0;
        border-top: 1px solid @COLOR-TNL-GRAY5;
    }
    .second-sell {
        margin: 2em 0 0;

        label {
            margin-right: 1em;
        }

    }
    div.tnl-dashboard-menu {
        float: right;
        margin: 4px 5px 0 0;
    }
    html.lt-ie8 .tnl-header .tnl-dashboard-menu {
        margin-top: -29px;
    }
    .tnl-activity-locked {
        color: @COLOR-WARNING;
        font-size: @COS-FONT-SIZE-DEFAULT;
    }
    .tnl-activity-details h1 {
        line-height: 30px;
    }
    .tnl-show-more {
        margin: 0;
        padding: 0;
        list-style-type:none;
    }
    .tnl-show-more:last-child {
        margin: 0;
        padding: 0;
        list-style-type:none;
    }
    .tnl-module-panel {
        border-collapse:collapse;
    }
    .tnl-gp-section-header {
        font-size: @COS-FONT-SIZE-16;
        font-weight:bold;
        padding: 0 5px 1px;
    }
    .tnl-gp-activity-details-panel {
	    margin-bottom: 20px;
    }
    .tnl-gp-resource-link {
        color: @COLOR-TNL-BLUE1 !important;
    }
    .tnl-evaluation-teaser .item {
	    margin-bottom: 10px;
    }
    .tnl-modal-header h1 {
	    margin: 10px 0 20px;
	    padding: 0;
        .COS-OPEN-SANS-600();
    }
    .tnl-1-4 {
        float: left;
        width: 25%;
    }
    .tnl-1-3 {
        float: left;
        width: 33%;
    }
    .tnl-1-2 {
        float: left;
        width: 50%;
    }
    .tnl-3-3 {
        float: left;
        width: 66%;
    }
    .tnl-3-4 {
        float: left;
        width: 75%;
    }
    .tnl-view-scroll {
        height: 400px;
        overflow: auto;
    }
    .tnl-two-column-text {
	    .LESS-TEXT-MULTI-COLUMN(2);
    }
    .tnl-three-column-text {
	    .LESS-TEXT-MULTI-COLUMN(3);
    }
    .tnl-activity-actions-bar {
        margin: 10px 0;
    }
    .tnl-activity-actions-bar .tnl-icon-text-link {
        margin: 0;
        padding: 0;
    }
    .dotted-line {
	    margin: 1em 0 3em;
	    padding: 0 0 3em;
    }
    .secondary-header th {
	    border: 0;
    }
    .tnlf-add-accounts {
	    cursor: pointer;
    }
    .tnl-status-dashboard h1 {
        color: @COLOR-TNL-GRAY2;
        margin: .25em 0 0;
        padding: 0;
    }
    .tnl-status-dashboard h2 {
   	    color: @COLOR-TNL-GRAY3;
        margin: 0 0 1em;
        padding: 0;
    }
    .tnl-editable[disabled],
    .tnl-editable[readonly] {
        color: @COLOR-TNL-GRAY3;
        background-color: transparent;
        border: 0 solid;
        outline: none;
        box-shadow: none;
    }
    .tnl-editable {
        background-color: transparent;
        border: none;
        border-radius: 0;
        box-shadow: none;
        color: @COLOR-TNL-GRAY2;
        width: 75%;
    }

    .tnl-editable:focus {
        background-color: #fff;
        border: 1px solid #ccc;
    }
    .tnl-status-dashboard caption {
        text-align: left;
        margin: .5em 0;
    }
    .tnl-reorder i {
        margin:1em 1em 0;
        cursor: pointer;
    }
    .dateSelectorClass {
      position: fixed;
      z-index: 9000;
      width: 380px;
      left: 50% !important;
      top: 50% !important;
      margin: -210px 0 0 -190px !important;
      display: none;
      border-radius: 4px;
      overflow: hidden;
      -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
      -moz-box-shadow:    0 5px 15px rgba(0,0,0,.5);
      box-shadow:         0 5px 15px rgba(0,0,0,.5);
    }
    .helpBoxClass {
        z-index: 5001;
        background: #fff;
        border: 1px solid black;
        position: absolute;
        top: 100px;
        left: 100px;
        display: none;
        padding: 1px;
        white-space: nowrap;
    }
    #col1 {
	    padding-right: 10px;
    }
    .tnl-actions-menu .btn {
	    padding: 5px;
    }
    .tnl-right {
        float:right !important;
    }
    div.order-container{
        background-color:white;
        margin: 0;
        padding: 2px;
        border: solid thin gray;
        min-height:20px;
    }
    div.order-item{
        cursor:move;
        position:relative;
    }
    div.order-item-hover{
        background-color: @COLOR-TNL-BLUE2;
        color: #fff;
    }
    html.lt-ie8 .modal-backdrop,
    html.lt-ie8 .modal-backdrop.in,
    html.lt-ie8 .modal-backdrop.fade {
	    display: none !important;
	    height: 1px !important;
	    width: 1px !important;
	    cursor: default !important;
	    background-color: transparent !important;
    }
    .tnl-meta-form {
	    -ms-word-wrap: break-word;
	    word-wrap: break-word;
        min-width: 160px;
        max-width: 160px;
    }
    .tnl-meta-form > select {
        width: 100%;
    }
    .tnl-sticky.stick {
        position: fixed;
        top: 10px;
        z-index: 10000;
    }
    .tnl-tfoot {
        font-weight: bold;
        td {
          padding: 5px 10px;
        }
    }

    .sign-off-buttons {
        padding: 10px;
        border: 1px solid @COLOR-TNL-GRAY3;
    }

     .tnl-icon-text-link > span {
        position: relative;
        top: 0;
        left: 10px;
    }
}
