@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .announcements span.tnlif {
        color: @COLOR-TNL-GRAY3;
    }

    .announcements-header-right {
        background: url(/images/red-box-left.png);
        width: 3px;
        height: 18px;
        position: relative;
        z-index: 90;
        float: right;
    }

    .announcements-header-center {
        background: url(/images/red-box-right.png);
        height: 18px;
        background-repeat: no-repeat;
        position: relative;
        float: right;
        background-position: top right;
        padding: 2px 5px 2px 1px;
        color: #fff;
        .COS-OPEN-SANS-600();
    }

    .tnl-message {
      .date,
      .from {
        font-size: 14px;
        margin-bottom: 5px;
      }

      .from {
        margin-bottom: 10px;
      }
    }

    .tnl-message > .tnl-message-body .tnl-container-header {
        font-weight: normal;
        margin: 0 0 0 10px;
    }

    .tnl-message > .tnl-message-body .tnl-container-content {
        color: @COLOR-TNL-GRAY3;
        font-size: 16px;
        margin: 0;
    }
}

.pm-announcement-list-item {
  padding: 7px 0 15px !important;

  &.selected a {
    color: #165fab !important;
    font-family: 'Open_Sans_Semibold', sans-serif !important;
    font-weight: normal !important;
  }

  .pm-message-unread,
  .pm-message-read {
      position: relative;
      top: 6px;
      margin-right: 10px;
  }

  .pm-message-read {
      color: #a4aaba;
  }

  .pm-message-unread {
      color: #008f07;
  }
}

.pm-announcement-legend {
  .legend-item {
    .pm-icon-font {
      top: 2px;
    }

    .pm-message-read {
        color: #a4aaba;
    }

    .pm-message-unread {
        color: #008f07;
    }
  }

  .legend-text {
    position: relative;
    top: -4px;
    color: #354247;
  }
}

.pm-message-content {
  h3 {
    font-family: 'Open_Sans_Semibold', sans-serif !important;
    font-weight: normal !important;
    color: #354247;
    margin-top: 20px;
  }


  .pm-container-content {
    p, span {
      color: #354247;
    }
  }
}
