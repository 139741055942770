@import (reference) "_global.less";

.empari {

    .tnl-popup {
        display: none;
        margin: 16px 0 0 75px;
        position: absolute;
        z-index: 100;
        min-width: 400px;
        color: @COLOR-TNL-GRAY2;
        padding: 15px;
        background: #fff;
        border: 1px solid @COLOR-TNL-GRAY4;
        .LESS-ROUNDED-CORNERS();
        .LESS-SHADOW-POPUP-MENU();
    }

    .tnl-popup-main {
        overflow: hidden;

        > h1 {
            margin: 0 0 10px 0;
            padding: 0;
            font-size: 16px;
            font-weight: normal;
            white-space: normal;
        }

        .tnl-ratings-item {
            float: left;
            width: 180px;
        }

        > div > span {
            display: block;
            margin: 0 0 5px 0;
            font-size: 14px;
        }

    }

}