@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

body > .container-fluid {
    padding: 0;
}

.tnl-structure {
    position: relative;
    @WIDTH-MD: 60px;
    @WIDTH-LG: 60px;
    @HEIGHT: 50px;
    background-color: #efefef;
    background: #efefef;
    padding: 0;

    .tnl-content {
        position: relative;
    }
}

// Container
.tnl-page-container {
    &.container {
        position: relative;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
    }
}

// Header
// TODO: This should be inside .tnl-page-container. However the DOM is not consistent across pages.
.tnl-page-header  {

    .tnl-page-header-icon {
        display: inline-block;
        margin-right: 5px;
        color: @COLOR-TNL-GRAY1;
        font-size: @FONT-SIZE-XXLG;
    }

    h1 {
        display: inline-block;
        margin: 0;
        padding: 14px 35px;
    }

    p {
        margin: 0 0 20px 0;
        color: @COLOR-TNL-GRAY2;
        font-size: @FONT-SIZE-SM;

        &:last-child {
            margin: 0;
        }
    }

}

// Nav
.tnl-page-nav {
    margin: 20px 10px 0;

    .nav-tabs {
        border-color: @COLOR-TNL-GRAY3;
    }
    .nav-tabs > li > a {
        border-bottom-color: transparent;
    }
    .nav-tabs > li > a:hover {
        border-bottom-color:  @COLOR-TNL-GRAY3;
    }
    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:hover,
    .nav-tabs > li.active > a:focus {
        color: @COLOR-TNL-GRAY1;
        .COS-OPEN-SANS-600();
        border-color: @COLOR-TNL-GRAY3;
        border-bottom-color: transparent;
    }
}

// Content
.tnl-page-content {
    margin: 0;

    .tnl-page-article {
        margin-bottom: 20px;

        .tnl-page-article-header {
            margin-bottom: 10px;

            h1, h2, h3, h4, h5, h6 {
                color: @COLOR-COS-DEFAULT;
                .COS-OPEN-SANS-600();
            }

            h4, h5, h6 {
                font-size: @COS-FONT-SIZE-DEFAULT;
            }
        }
        .tnl-page-article-content {
            > h1, > h2 { // To support screen readers, avoid using h1.
                color: @COLOR-TNL-ORANGE1;
                font-size: @COS-FONT-SIZE-18;
                margin-bottom: 10px;
            }
            > h3, > h4, > h5, >h6 {
                font-size: @COS-FONT-SIZE-DEFAULT;
                .COS-OPEN-SANS-600();
            }
            p {
                margin-bottom: 15px;
            }
        }
    }

    .tnl-page-sub-header {

        h2 {
            margin: 0;
            padding: 0;
            color: @COLOR-TNL-ORANGE1;
        }

        p {
            font-size: @FONT-SIZE-LG;
            color: @COLOR-TNL-GRAY2;
        }

    }

    .tnl-page-content-header {
        margin: 20px 0 0;
        padding: 0;
        color: #333;
    }

}

.pm-modus-body-padding {
    padding: 15px 25px;
}

// Footer
.tnl-page-footer {
    margin-top: 10px;
    padding: 20px;
    color: @COLOR-TNL-GRAY3;
    font-size: 13px;
    line-height: 20px;
    border-top: 1px solid @COLOR-TNL-GRAY4;

    a {
        color: @COLOR-TNL-GRAY3;
    }

    ul {
        list-style-type: none;
        margin: 10px 0 0;
        padding: 0;
    }

    ul li {
        margin: 0;
        padding: 0;
    }

    .cos-footer-logo {
        display: block;
        width: 180px;
        height: 36px;
        background: transparent 0 0 no-repeat url('../../Images/logo-UnifiedTalent-180x36.png');
        background-size: contain;
    }

    .cos-footer-copyright {
        li {
            font-size: @COS-FONT-SIZE-12;
            color: @COLOR-COS-DEFAULT;
            height: 17px;
            line-height: 17px;
        }
    }

    @media (min-width: 0px) {
        padding-bottom: 20px;
    }

    @media (min-width: 992px) {
        padding-bottom: 20px;
    }

    .smaller {
        font-size: 12px;
    }
}

.admin-panel {
    padding-bottom: 20px;
}
