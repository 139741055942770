@import (reference) "../Theme/Common/_common.less";

/* */


.admin-panel {
    a {
        font-size: @FONT-SIZE-MD;
    }
    .tnl-admin-description {
        display: block;
        color: @COLOR-TNL-GRAY2;
        font-size: @FONT-SIZE-SM;
    }
}