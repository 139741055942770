@import (reference) "../_pm-global.less";
@import (reference) "_global.less";

.empari {

  input.tnl-date {
    width: 76px;
  }

  .input-title-bar,
  .list-title-bar {
    margin-bottom: 12px;
    z-index: 60;
    background: #fbfbfb;
    border-top: 5px solid @COLOR-PM-BLUE2;
    border-bottom: 2px solid @COLOR-PM-GREY8;
    color: #36474f;
    padding: 15px 20px;
    font-size: 18px;
  }

  .inputgeneral {
    background:#FFF;
  }

  table.inputborder {
    width: 100%;
    padding: 0;
    background-color: #fff;
    border-spacing: 0 !important;

    th {
      color: #fff;
      font-size: @COS-FONT-SIZE-12;
      background-color: @COLOR-TNL-GRAY3;
      border-right: 1px solid @COLOR-TNL-GRAY4;
      .COS-OPEN-SANS-600();
    }

    th:last-child {
      border-right: none;
    }

    tr.rowcolor1 > td,
    tr.rowcolor2 > td {
      border-right: 1px dashed @COLOR-TNL-GRAY3;
    }

    tr.rowcolor1 > td:last-child,
    tr.rowcolor2 > td:last-child {
      border-right: none;
    }

    th.left {
      text-align: left;
    }

    th a {
      color: #fff;
      text-decoration: none;
    }

    table.legend td {
      padding: 2px;
    }

  }

  .inputborder td,
  .inputborder th {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  .inputsubtitledark {
    background: @COLOR-PM-BLUE1;
  }

  .inputsubtitlelight {
    background: @COLOR-TNL-GRAY6;
  }

  .inputtext {
    background: @COLOR-TNL-GRAY4;
  }

  .inputfield {
    background: @COLOR-TNL-GRAY6;
  }

  .inputbutton {
    background: @COLOR-TNL-GRAY6;
  }

  .inputtable {
    background: @COLOR-TNL-GRAY5;
    border-spacing: 1px;
    border-width: 0;
    padding: 0;
    width: 100%;
  }

  table {

    &.tnl-input-form {
      width: 100%;
    }

    &.inputborder td,
    td.intitle,
    td.intitle-left,
    td.infield {
      padding: 10px;
      vertical-align: top;
      border: none !important;
    }

    td.intitle,
    td.intitle-left {
      width: 20%;
      padding-top: 17px;
      padding-right: 10px;
      color: @COLOR-TNL-GRAY1;
      .COS-OPEN-SANS-600();
    }

    td.intitle {
      text-align: right;

      &.pm-required-note {
        font-size: @COS-FONT-SIZE-DEFAULT;
        color: @COLOR-PM-RED1 !important;
        .COS-OPEN-SANS-600i();
      }

      sup {
        top: -0.25em;
        font-size: @COS-FONT-SIZE-12;
        color: @COLOR-PM-RED1 !important;
        .COS-OPEN-SANS-700();
      }
    }

    td.intitle-left {
      text-align: left;
    }

    td.infield {
      width: 80%;
      padding-left: 10px;
      vertical-align: top;
    }
  }

  .form-group {
    table {
      td.infield {
        padding-left: 0 !important;

        table > tbody > tr > td {
          padding: 10px;
        }
      }
    }
  }
}
