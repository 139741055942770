@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    #tnl-page-title-bar {
        margin: 0 10px 0 0;
    }

    .layout-title {
        display: inline-block;
        margin: 0 0 10px;
        color: @COLOR-COS-DEFAULT;
        font-size: @COS-FONT-SIZE-22;
        .COS-OPEN-SANS-REGULAR();
    }

    .app-heading {
	    margin: 0 0 10px 0;
        color: @COLOR-COS-DEFAULT;
        font-size: @COS-FONT-SIZE-22;
        .COS-OPEN-SANS-600();
        padding: 15px 35px; 
        border-bottom: 1px solid #ccc;
    }

    .app-body {
	    margin: 0;
	    padding: 20px;
        background-color: #fff;
    }

    .pm-app-body {

        .pm-button-position {
            float: right;
        }
    
        .input-group label,
        .input-group input {
            margin-right: 5px !important;
        }
    
        .pm-button-position .pm-outline-btn.pm-btn-lg:last-child {
            margin-right: 0 !important;
        }
    
        @media only screen and (max-width : 1200px) {
            .pm-button-position {
                float: left !important;
                margin: 20px 0 0 55px;
            }
        }
    }

    .pm-build-layout {
        padding: 15px 35px; 
    }

}