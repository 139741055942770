@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    #feedbackBtn {
        vertical-align: top;
    }

    .note-attachment-record {
        text-align: center;
        white-space: nowrap;
    }

    .feedback-header {
        text-align: center;
        font-size: 18px;
        padding-bottom: 30px;
        .COS-OPEN-SANS-600();
    }

    .feedback-taking {
        .COS-OPEN-SANS-600();
    }

    .feedback-no-data-cell {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        .COS-OPEN-SANS-600();
    }

    .feedback-header-cell {
        height: 30px;
    }

    .feedback-answer-cell {
        vertical-align: top;
        text-align: left;
    }

    .feedback-summary-cell {
        vertical-align: bottom;
        padding-right: 15px;
    }

    .feedback-metadata-cell {
        vertical-align: top;
    }

}