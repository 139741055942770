@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    ul.tnl-builder-group {
        list-style-type: none;
    }

    .tnl-builder-group {
        margin: 0;
        padding: 0;

        &.start {
            margin-bottom: 20px;
        }
        &.tnl-box {
            padding: 15px;
            border: 1px solid @COLOR-TNL-GRAY4;
        }
    }

    .tnl-builder-item {
        margin: 0;
        padding: 0;

        &.parent {
            margin: 15px 0;
            padding: 5px;
            border: 1px solid @COLOR-TNL-GRAY4;
        }

        &.parent.top {
            font-size: 14px;
        }

        &.top > .tnl-admin-builder-text {
            margin-bottom: 10px;
            padding: 2px;
            border-radius: 2px;
        } 
          
        &.parent > .tnl-admin-builder-text {
            margin-bottom: 10px;
            padding: 10px;
            background: @COLOR-TNL-GRAY4;
            background-repeat: repeat-x;
            background-position: center bottom;
            border: 1px solid @COLOR-TNL-GRAY4;
            border-radius: 4px;
        }

    }

    .tnl-builder-item.parent:hover,
    ul.tnl-builder-group.top:hover, 
    .tnl-builder-item-td:hover {
        .LESS-BOX-SHADOW();
        border-color: @COLOR-TNL-BLUE1 !important;
    }

    .tnl-builder-manager-highlight {
        color: #fff !important;
        background: @COLOR-TNL-BLUE1 !important;
        border-color: @COLOR-TNL-BLUE1 !important;
        .LESS-BOX-SHADOW();
    }

    .tnl-builder-item-td:hover,
    th.tnl-builder-manager-highlight,
    .tnl-table-side-th {
        display: block;
        position: relative;
        border-color: #fff !important;
    }

    .tnl-builder-manager-highlight a {
        color: #fff !important;
    }

    .tnl-builder-item > div > span,
    .tnl-admin-builder-text > div > span,
    .tnl-builder-matrix th > div > span {
	    display: inline-block;
	    .LESS-FIX-IE7-INLINE-BLOCK();
	    margin-right: 5px;
        vertical-align: middle;     
    }

    .tnl-admin-builder-text {
        padding: 5px 0;

        > span:last-child {
	        margin-right: 0;  
        }
        > .tnl-icon-wrapper {
            margin: 0 5px 0 0;
            vertical-align: middle;
        }
    }

    .tnl-admin-builder-text-title {
	    display: inline-block;
	    .LESS-FIX-IE7-INLINE-BLOCK();
        .COS-OPEN-SANS-600();

        > a.tnl-text-link {
            margin: 0 5px 0 0;
            vertical-align: middle;    
            font-weight: normal; 
        }
        > b {
            vertical-align: middle;
        }
        > span {
            vertical-align: middle;
            font-size: 14px;
        }
        > .tnl-builder-abbrv {
	        color: @COLOR-TNL-GRAY2;
            font-size: 14px;
        }
    }

    .tnl-builder-group.cols {
        display: table;
        width: 100%;
        border-collapse: separate;
        border-spacing: 5px;

        > .tnl-builder-item {
            display: table-cell;
        }
        > .tnl-builder-item > .tnl-admin-builder-text {
            padding: 0 10px 0 0;
        }   
        > .tnl-builder-item.parent > .tnl-admin-builder-text {
            padding: 10px;
        }
        > .tnl-builder-item.parent {
            box-shadow: none;
        }
    }

    .tnl-builder-matrix {

        &.tnl-table th,
        &.tnl-table td {
            font-size: 14px;
        }
        &.tnl-table td {
            vertical-align: top;
        }

    }

    td.tnl-builder-item-td.empty,
    td.tnl-builder-item-td.empty span {
        vertical-align: middle;
        text-align: center;
    }

    .tnl-builder-group.start .tnl-admin-builder-text {
        margin: 0;
    }

    .tnl-builder-categories {
        margin: 20px auto;
        width: 50%;

        > .tnl-builder-categories-item {
            width: 40%;
            margin: 0 20px 0 0;
        }

    }

    .tnl-builder-manager {

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        li {
            padding: 15px;
            border-bottom: 1px solid @COLOR-TNL-GRAY3;
        }

        li:last-child {
            border: none;
        }

        textarea {
            resize: vertical;
        }

    }
}