@import (reference) "_global.less";
@import (reference) "../_button.less";

// Mimic the style of .btn-default
.empari {
    .LESS-BUTTON() {

    }
    .LESS-BUTTON-HOVER() {
        color: #fff;
        text-decoration: none !important;
        background-color: @COLOR-TNL-GRAY3;
        .LESS-BOX-SHADOW-INSET (0, -20PX, 20PX, rgba(0,0,0,0.15));        
	    border: 0;
    }
    .LESS-BUTTON-DISABLED() {
        color: @COLOR-TNL-GRAY4;
        font-weight: normal;
        text-decoration: none;
        background: @COLOR-TNL-GRAY5;
        border: 1px solid @COLOR-TNL-GRAY4;
        .LESS-BOX-SHADOW-RESET();
        cursor: not-allowed;
    }
    .LESS-EMPARI-BUTTON-STYLE() {

        // Normal state
        .btn {
            .tnlif.fa,
            .tnlif.im {
                color: #fff;
            }
            &:disabled,
            &[disabled],
            &.disabled {
                .tnlif.fa,
                .tnlif.im {
                    color: @COLOR-TNL-GRAY4;
                }
            }
        }
        .btn-big,
        a.btn-big,
        a.btn-big:active,
        a.btn-big:visited,
        .tnl-btn-icon,
        .tnl-btn-icon:active,
        .tnl-btn-icon:visited,
        .tnl-btn-text,
        .tnl-btn-text:active,
        .tnl-btn-text:visited,
        .tnl-btn-text.ui-state-default,
        .tnl-btn-text.ui-state-default:active,
        .tnl-btn-text.ui-state-default:visited,
        .tnl-btn-icon-text,
        .tnl-btn-icon-text:active,
        .tnl-btn-icon-text:visited,
        .tnl-btn-icon-text.ui-state-default,
        .tnl-btn-icon-text.ui-state-default:active,
        .tnl-btn-icon-text.ui-state-default:visited,
        .tnl-small-button {
	        display: inline-block;
            position: relative;
            padding: 4px 15px;
            padding-right: 12px;
            padding-left: 12px;
            color: #fff;
	        #Fonts > .SansSerif;
            line-height: 14px;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            text-align: center;
	        text-decoration: none;
            text-shadow: none;
            background: @COLOR-TNL-GRAY3;
            background-image: none;
            background-position: 0;
            border: 0;
            .LESS-ROUNDED-CORNERS();
            .LESS-BOX-SHADOW-RESET();
            cursor: pointer;
            white-space: nowrap;

            &:hover {
                .LESS-BUTTON-HOVER();
            }
            .tnlif.fa,
            .tnlif.im {
                color: #fff;
            }
        }

        .tnl-btn-icon {
            vertical-align: middle;
            height: 24px;
            width: 24px;

            > span.tnlif {
                position: relative;
                top: 1px;
            }
        }

        // Hover
        a.btn-big:hover,
        .tnl-btn-icon:hover,
        .tnl-btn-text:hover,
        .tnl-btn-text.ui-state-default:hover,
        .tnl-btn-icon-text:hover,
        .tnl-btn-icon-text.ui-state-default:hover {
            .LESS-BUTTON-HOVER();
        }

        // Disabled
        .tnl-btn-icon.disabled,
        .tnl-btn-icon.disabled:hover,
        .tnl-btn-text-disabled,
        .tnl-btn-text-disabled:hover,
        .tnl-btn-icon-text.disabled,
        .tnl-btn-icon-text.disabled:hover,  
        .btnoff,
        .btnoff:hover, 
        div.btnoff,
        div.btnoff:hover, 
        div.btn-big-off,
        div.btn-big-off:hover, 
        .btn-off,
        .btn-off:hover,
        .tnl-small-button-disabled,
        .tnl-small-button-disabled:hover {
            .LESS-BUTTON-DISABLED();
        }

        .tnl-small-button {
	        padding: 2px 8px;
	        font-size: 12px;

            &:hover {
	            .LESS-BUTTON-HOVER();
            }
        }

    }

    .LESS-EMPARI-BUTTON-STYLE();

    .ui-widget-content,
    div.ui-widget-content {
        .LESS-EMPARI-BUTTON-STYLE();
    }

    html.lt-ie9 {
        .btn-big,
        .tnl-btn-text,
        .tnl-btn-icon-text,
        .tnl-btn-icon,
        .tnl-btn-text-disabled,
        .tnl-btn-text.ui-state-default,
        .tnl-btn-icon-text.ui-state-default,
        .ui-widget-content .tnl-btn-icon-text,
        .ui-widget-content .tnl-btn-icon {
            border: 0;
        }
    }

    // .boot-btn is an Empari hack to avoid conflicts with jQuery UI.
    // .boot-btn should be removed as soon as the hack is no longer needed.
    .boot-btn,
    a.boot-btn,
    button.boot-btn {
        &:extend(.btn);
        //.btn();
    }
    // Handles unwanted CSS on buttons in the Fuel wizard.
    .fuelux .wizard > .actions a.boot-btn {
        line-height: 1.3334;
        font-size: 15px;
        margin-right: 10px;
    }

    .tnl-btn-toggle {
        border: 1px solid @COLOR-TNL-GRAY4;

        .btn.active {
            background-color: @COLOR-TNL-GREEN1;
        } 
        .active .tnlif,
        .active a {
            color: #fff;
        }
    }
}