@import (reference) "_global.less";

.empari {

    div.tnl-plan-summaries:first-child {
        border-top: 1px solid @COLOR-TNL-GRAY2;
        margin-top: 20px;
    }

    div.tnl-plan-summaries {
        padding: 40px 20px;
        border-bottom: 1px solid  @COLOR-TNL-GRAY2;
    }

    .tnl-plan-summary .alert {
	    margin: 0;
    }

    .tnl-plan-summary-header h1 {
	    margin: 0;
	    padding: 0;
	    .LESS-ELLIPSIS-ONE-LINE();
	    font-size: 24px;
	    font-weight: normal;
    }

    .tnl-plan-summaries .tnl-container-header h1 {
        font-size: 16px;
        font-weight: 600;
        margin: 0 0 20px 0;
    }

}