@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .tnl-content-box {
        .LESS-CONTAINER();
        padding: 15px;
        border: 1px solid @COLOR-TNL-GRAY4;
        margin-bottom: 20px;
        .LESS-ROUNDED-CORNERS();
        .LESS-BOX-SHADOW();
    }

    .tnl-content-box:last-child {
        margin-bottom: 0;
    }

    .tnl-content-box.simple {
        padding: 0;
        background: none;
        border: none;
        .LESS-ROUNDED-CORNERS-RESET();
        .LESS-BOX-SHADOW-RESET();
    }

    .tnl-content-box > .item > .main > .content > .tnl-container-header h1 {
        margin: 0 0 10px 0;
        color: @COLOR-TNL-GRAY2;
        font-size: 16px;
        font-weight: normal;
    }

    html.lt-ie9 .tnl-content-box {
        border: 1px solid @COLOR-TNL-GRAY3;
    }

    html.lt-ie9 .tnl-content-box.simple {
        border: none;
    }

    .tnl-content-box-closed,
    .tnl-content-box-innactive {
        background: #fff;
    }

    .tnl-content-box-innactive:hover {
        background: #fff !important;
        cursor: auto;
    }

    .tnl-content-box-closed:hover {
        cursor: pointer;
    }

    .tnl-content-box-status-complete {
        background: url(/images/bg-complete.png) #fff no-repeat center bottom;
    }

    .tnl-content-box-status-complete:hover {
        background: url(/images/bg-complete.png) #fff no-repeat center bottom;
    }

    .tnl-content-box .title,
    .tnl-content-box .title-innactive {
        position: relative;
        margin: 1px 0 5px 0;
        padding: 0;
        .LESS-ROUNDED-CORNERS();
        overflow: hidden;
    }

    .tnl-content-box .title .name,
    .tnl-content-box .title .name b,
    .tnl-content-box .title-innactive .name,
    .tnl-content-box .title-innactive .name b,
    .tnl-content-box .title .name-link,
    .tnl-content-box .title .name-link b {
        font-weight: normal;
    }

    .tnl-content-box .title .name,
    .tnl-content-box .title-innactive .name,
    .tnl-content-box .title .name-link {
        position: absolute;
        top: 1px;
        margin: 0 0 0 10px;
        padding: 0;
        font-size: 19px;
    }

    .tnl-content-box .title .name {
        color: #333;
    }

    .tnl-content-box .title .name .date {
        margin-left: 5px;
        color: @COLOR-TNL-GRAY2;
        font-size: 13px;
        font-weight: normal;
    }

    .tnl-content-box .title .name a {
        line-height: 100%;
        position: static;
        font-size: 14px;
    }

    .tnl-content-box .title .name a:hover {
        background: none;
        font-weight: bold;
    }

    .tnl-content-box .title:hover {
        cursor: pointer;
    }

    .tnl-title-highlight  {
        background-color: #efefef !important;
    }

    .tnl-content-box-innactive.tnl-title-highlight {
        background-color: #fff !important;
        cursor: auto;
    }

    .tnl-content-box .item .main,
    .tnl-content-box .description {
        display: table;
        width: 100%;
        border-collapse: collapse;
    }

    .tnl-content-box .item:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }

    .tnl-content-box .description {
        font-size: 13px;
    }

    .tnl-content-box .description b,
    .tnl-content-box .description strong {
        .COS-OPEN-SANS-600();
    }

    .tnl-content-box .item .main .content,
    .tnl-content-box .item .main .tnl-toolbar,
    .tnl-content-box .item .description .content,
    .tnl-content-box .item .description .tnl-toolbar {
        display: table-cell;
        vertical-align: top;
    }

    .tnl-content-box .description p {
        margin-bottom: 10px;
    }

    .tnl-content-box .course .description,
    .tnl-content-box .views,
    .tnl-content-box .course .description-smaller {
        padding: 10px 20px 0 0;
        color: @COLOR-TNL-GRAY2;
        font-size: 13px;
        line-height: 15px;
    }

    .tnl-content-box .status {
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .tnl-content-box .status a {
    }

    .tnl-content-box .item .main .content .icons {
        float: left;
        margin: 0 5px 0 0;
    }

    .tnl-content-box .item .main .content .icons .tnl-icon-md {
        position: relative;
        top: -6px;
        margin-right: 15px;
    }

    .tnl-content-box .item .main .content .icons .tnl-icon-md:last-child {
        top: 0;
        margin-right: 0;
    }

    .tnl-content-box .item .main div.content .title-link,
    .tnl-content-box .item .main div.content .opportunity-title {
        display: inline;
    }

    .tnl-content-box .item .main div.content .opportunity-title {
        .COS-OPEN-SANS-600();
    }

    .tnl-content-box .item .main .content .tnl-activity-link,
    .tnl-content-box .item .main .content .tnl-activity-link b,
    .tnl-content-box .item .main .content .title-link,
    .tnl-content-box .item .main .content .title-link b {
        font-size: @COS-FONT-SIZE-18;
        font-weight: normal;
    }

    .tnl-content-box .item .main .content .section-number {
        font-size: @COS-FONT-SIZE-18;
        font-weight: normal;
    }

    .tnl-content-box .item .main .content .tnl-activity-link {
        font-size: 16px;
    }

    .tnl-content-box .item .main .content hr {
        margin: 2px 10px 0 25px;
        padding: 0;
        background: none;
        border: none;
        border-bottom: 1px dashed @COLOR-TNL-GRAY4;
    }

    .tnl-content-box .item .main .tnl-toolbar span.tnlif.fa {
        color: #fff;
        font-size: 16px;
    }

    .tnl-cc-pdc-row {
        position: relative;
    }

    .tnl-cc-content-type {
        position: absolute;
        z-index: 101;
        top: 15px;
        left: 14px;
        padding: 2px 8px;
        background-color: #fff;
        .LESS-OPACITY(.65);
        font-size: 160%;
    }

    .tnl-course-image {
        position: relative;
        margin: 0 0 10px 0;
    }

    .tnl-supplier-logo {
        margin: 5px 0;
    }

    .tnl-catalog-view .tnl-catalog-vendor {
        margin-top: 10px;
    }

    .tnl-catalog-view .icons .tnlif {
        color: @COLOR-TNL-GRAY2;
        font-size: 24px;
    }

    .tnl-course-img {
        width: 20%;
    }

    .tnl-course-content {
        width: 80%;
        padding-left: 10px;
    }

    .content-active {
        background: #f3f5ea !important;
    }

    .content-finished {
        background: @COLOR-TNL-GREEN1 !important;
    }

    .tnl-current-step {
        width: 25%;
        margin: 5px auto;
        text-align: center;
    }

    .tnl-content-box .item {
        padding: 20px 10px;
        background: #fff;
        border-bottom: 1px solid @COLOR-TNL-GRAY4;
    
        &:last-child {
            border: none;
        }    
    }

    .tnl-content-box .item:last-child {
        margin-bottom: 0;
    }

    html.lt-ie9 .tnl-content-box .item {
        border: 1px solid @COLOR-TNL-GRAY3;
    }

    html.lt-ie8 .tnl-content-box .item {
        .LESS-FIX-CONTAIN-FLOAT();
    }

    .tnl-content-box.header .item .main .content > div {
        display: table;
        width: 100%;
        padding: 0;
    }

    .tnl-content-box.header .item .main .content > div > div {
        display: table-cell;
        vertical-align: top;
    }

    .tnl-content-box.header .item .main .content > div > div:last-child {
        text-align: right;
        vertical-align: middle;
    }

    .tnl-content-box .item .description .content > div {
        padding-left: 88px;
    }

    .tnl-content-box .item .main .content .detail-group {
        float: right;
    }

    .tnl-content-box .item .main .content .detail {
        display: inline-block;
        margin-right: 2px;
        font-size: 14px;
        font-weight: normal;
    }

    .tnl-content-box .item .main .content .detail:first-child {
        margin-left: 40px;
    }
    .tnl-content-box .main-full {
        margin: 10px 10px 25px;
    }
    .tnl-content-box .tnl-item-nav a {
        display: inline;
    }
    .tnl-content-box .tnl-item-nav ul {
        margin: 0;
        padding: 0;
        text-align: left;
        list-style-type: none;
    }
    .tnl-content-box .tnl-item-nav li {
        margin: 0 20px 20px 0;
    }
    .tnl-content-box .tnl-item-nav li.tnl-item-nav-headline {
        color: @COLOR-TNL-GRAY2;
        font-size: 14px;
        .COS-OPEN-SANS-600();
        margin-bottom: 2px;
        margin-top: 30px;
    }
    .tnl-content-box .tnl-item-nav .tnl-icon-sm {
        position: relative;
        top: 3px;
        margin-right: 5px;
    }
    .tnl-content-box .tnl-item-nav small {
        color: @COLOR-TNL-GRAY2;
        font-size: 14px;
    }
    .tnl-content-box li.tnl-item-nav-back {
        margin-bottom: 25px;
        font-size: 13px;

        .tnl-icon-text-link {
            padding: 0;
        }
    }
    .tnl-content-box .tnl-item-section > h1,
    .tnl-content-box .tnl-item-section > h2 {
        margin: 0;
        padding: 0;
    }
    .tnl-content-box .tnl-item-section > h1 {
	    margin: 0 0 5px 0;
	    color: @COLOR-TNL-GRAY2;
	    font-size: 18px;
	    font-weight: normal
    }
    .tnl-content-box .tnl-item-section > h2 {
        font-size: 15px;
        margin-bottom: 10px;
    }
    .tnl-content-box .tnl-item-section p {
        margin-bottom: 10px;
        color: #000;
    }
    .tnl-content-box .tnl-item-section-resource {
        margin: 20px 0;
    }
    .tnl-content-box .item .main .tnl-toolbar,
    .tnl-content-box .item .description .tnl-toolbar {
        width: 32px;
        text-align: center;
        padding-top: 5px;
    }

    .tnl-content-box .item .main .tnl-toolbar div,
    .tnl-content-box .item .description .tnl-toolbar div {
        padding: 2px 0;
        text-align: center;
        margin-right: 0;
    }

    .tnl-content-box .item .main .tnl-toolbar div span,
    .tnl-content-box .item .description .tnl-toolbar div span {
        margin: 5px 0;
        text-align: center;
    }

    .tnl-content-box .item .main .tnl-toolbar div:hover,
    .tnl-content-box .item .description .tnl-toolbar div:hover {
        cursor: pointer;
    }

    .tnl-content-box .title .tnl-toolbar {
        position: absolute;
        top: 4px;
        right: 0;
        margin: 0 10px 0 0;
    }

    .tnl-content-box .title .tnl-toolbar span {
        margin: 0 0 0 4px;
    }

    .tnl-content-box .title .tnl-toolbar div {
        display: inline-block;
    }

    .tnl-content-box .title .tnl-toolbar div:hover {
        cursor: pointer;
    }

    .tnl-content-box .item .main p {
        margin: 2px 0 0 0;
        color: @COLOR-TNL-GRAY2;
        font-size: 13px;
        line-height: 16px;
    }

    .tnl-content-box .item .start,
    .tnl-content-box .item .main.center {
        margin: 10px;
        text-align: center;
        overflow: visible;
    }

}
