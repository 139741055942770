@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {
    #save-page-black-bar #inlineFormBtns >a >span{
        color: #000;
    }
    div.save-page-bar,
    div.save-page-bar.both {
        padding: 10px;
    }

    .tnl-form .save-page-bar {
        height: 100px;
        margin-top: 10px 0;
        margin-bottom: 10px 0;
        padding: 0;
        text-align: left;
        background: #fff;
    }

    div.save-page-bar {
        input,
        input,
        a,
        a {
            margin-left: 10px;
        }

        input:first-child,
        input:first-child,
        a:first-child,
        a:first-child {
            margin-left: 0;
        }

        &.left {
            text-align: left;
        }

        &.both {

            div.left,
            div.right {
                padding: 0 5px;
            }

            div.left {
                float: left;
                text-align: left;
            }

            div.right {
                float: right;
                text-align: right;
            }

        }
    }

    .tnl-form {
        padding: 10px;
    }

    .tnl-form label {
	    color: @COLOR-TNL-GRAY2;
        .COS-OPEN-SANS-600();
    }

    .tnl-form-controls {
        position: relative;
    }

    .tnl-form-control-sm {
	    max-width: 100px;
    }

    .tnl-form-control-md {
	    max-width: 200px;
    }

    .tnl-form-control-lg {
	    max-width: 400px;
    }

    .tnl-form-control-date {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 2px;
    }

    .tnl-form-control-date:first-child {
        margin-left: 10px;
    }

    .tnl-form-controls textarea {
        width: 100%;
        resize: vertical;
    }

    .tnl-form-more-less {
	    padding-top: 5px;
        padding-bottom: 10px;
	    cursor: pointer;
    }

}
