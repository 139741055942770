@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari .plc-plan {

    .panel-title {
        color: @COLOR-COS-DEFAULT;
        .COS-OPEN-SANS-600();
        margin-bottom: 10px;
        margin-left: -24px;
        margin-top: 30px;

        &:hover {
            text-decoration: underline;
        }
    }
    .well, 
    .locdemo-selection {
        margin-left: 20px;
    }
    .plc-explanation {
        margin-bottom: 10px;
    }
    h2 {
        &.plc-select-results {
            border-top: 1px solid #ccc;
            color: @COLOR-COS-DEFAULT;
            margin-top: 48px;
            padding-top: 12px;
        }
    }
}

.empari {

    .well-in-progress .moderator_badge .icon-font {
            color: @COLOR-PM-ORANGE1;
    }
}