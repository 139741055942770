@import (reference) "_global.less";

.empari {

    .tnl-ratings {
        color: @COLOR-TNL-GRAY2;
        font-size: 13px;
        white-space: nowrap;

        span {
	        position: relative;
	        top: 2px;
            margin-right: 3px;
        }

        a {
            font-size: 13px;
            white-space: nowrap;
        }

        span.tnl-popup-tab {
            position: absolute;
            top: -16px;
            left: 10px;
            width: 37px;
            height: 17px;
            background: url(/images/tnl-ratings-tab.png) no-repeat;
        }

    }

    .tnl-ratings-ready {
        color: @COLOR-SUCCESS;
    }

    .tnl-ratings-ready span,
    .tnl-ratings-rated span,
    .tnl-ratings-ready label {
        cursor: pointer;
    }

}