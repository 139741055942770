@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    
.info-text         { margin: 5px 0px 20px 20px; }
.info-box          { background-color:efefef; color: #000; border: 1px solid #aaa; padding: 2px 6px 2px 6px; margin: 5px 5px 10px 0px; }
.step-box          { background-color: #E7E7E7; color: #000; border-color:#777; border-style:solid; border-width: 1px 3px 3px 1px; margin: 0px 5px 4px 0px; z-index: 1; position: relative; padding: 7px 0px 7px 7px; }
.step-box-tab      { position: relative; background-color: #E7E7E7; color: #000; border-color:#777; border-style:solid; border-width: 1px 1px 0px 1px; margin: 4px 0px 0px 0px; padding: 2px; width: 320px; z-index: 10; top: 1px; }
.view-hide         { font-size:11px; }


.step-box-tab-container { 
    height:auto; 
    margin:4px 0 2px 2px;
}
.step-box-tab-subcontainer {
    height:auto;
    float:left;
    border:1px solid #777;
    border-bottom:0px;
    background:#fffbf0;
    margin-right:15px;
    position:relative;
    z-index:0;
    margin-bottom:-1px;
}
.step-box-content {
    padding:2px 6px;
}
.step-box-head {
    padding-left:5px;
    font-weight:bold;
}
.step-box-desc {
    clear:both;
    font-size:9px;
    font-weight:normal;
    padding:2px 0px;
}

.big-status-table td div
{
    background-color: #ffffff;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #dddddd;
    border-right-color: #dddddd;
    border-bottom-color: #dddddd;
    border-left-color: #dddddd;
    color: #dddddd;
    height: 25px;
    line-height: 24px;
    text-align: center;
    width: 29px;
    font-size:18px;
}

.big-status-table td.big-in-progress div  { background-color: #1254ED; color: #fff; }
.big-status-table td.big-complete div     { background-color: #3EA00E; color: #fff; }
.big-status-table td.big-incomplete div   { background-color: #D80006; color: #fff; }


.status-table td div
{
    background-color: #ffffff;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #dddddd;
    border-right-color: #dddddd;
    border-bottom-color: #dddddd;
    border-left-color: #dddddd;
    color: #dddddd;
    height: 14px;
    line-height: 13px;
    text-align: center;
    width: 16px;
}

.status-table td.goal div      { background-color:#F4E790; color:#000; }
.status-table td.aggregate div { background-color:#97E151; color:#fff; }

.status-table td.color1 div    { background-color:#9866C0; color:#fff; }
.status-table td.color2 div    { background-color:#73A9E1; color:#fff; }

.status-table td.in-progress div  { background-color: #1254ED; color: #fff; }
.status-table td.complete div     { background-color: #3EA00E; color: #fff; }
.status-table td.incomplete div   { background-color: #D80006; color: #fff; }

#sub-tab
{
	height:25px;
	border-bottom:1px solid #999;
	margin-bottom:10px;
	z-index:1;
}

#sub-tab ul
{
	margin:0px;
	list-style:none;
	font-size:12px;
}

#sub-tab li
{
	margin-left:2px;
	background-color:#ccc;
	padding:5px;
	float:left;
	text-align:center;
	border:1px solid #999;
	position:relative;
	z-index:20;
}

#sub-tab li.on
{
	background-color:#fff;
	border-bottom:1px solid #fff;
	font-weight:bold;
}

#sub-tab a
{
	color:#000;
}

.ui-widget-header  { padding:3px; }
.ui-widget-content { padding:3px; }
.ui-icon { display:block; }

.system-icon-text a
{
	padding:5px 0px 3px 1px;
	font-size:12px;
	display:inline-block;
	color:#6CAAD4;
	font-weight:bold;
}

div.system-icon
{
	margin:1px;
	padding:0;
	float:left;
	text-align:center;
	width:17px;
	height:17px;
	cursor:pointer;
	*width:17px;
	*height:16px;
}

div.system-icon span.ui-icon
{
	margin:0px 0px 0px 0px;
	*margin:0px 0px 0px 0px;
}

div.system-icon a {cursor: pointer;}

div.system-icon-disabled
{
	margin:2;
	padding:0;
	float:left;
	text-align:center;
	width:17px;
	height:17px;
	*width:17px;
	*height:16px;
	border:1px solid #999;
	background:#f2f2f2;
}

div.system-icon-disabled span.ui-icon
{
	margin:0px 0px 0px 0px;
	*margin:0px 0px 0px 0px;
}

div.system-icon-off
{
	margin:0;
	padding:0;
	float:left;
	text-align:center;
	width:17px;
	height:17px;
	*width:17px;
	*height:16px;
}

div.system-icon-off span.ui-icon
{
	margin:0px 0px 0px 0px;
	*margin:0px 0px 0px 0px;
}

.action-menu-box {
	width:150px;
	margin:-2px;
	*margin:-2px 0px 0px -25px;
	_margin:-2px 0px 0px -23px;
	padding:0px;
	text-align:right;
	position:absolute;
	display: none;
    border-radius: 4px;
    overflow: hidden;
	z-index:100;
    box-shadow: 1px 2px 3px rgba(0,0,0,.5);
    -moz-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
    background-color: #fff;
}

table.inputborder .action-menu-box
{
	*margin:-2px 0px 0px -23px;
}

.action-menu ul
{
	*padding:0px 0px 1px 0px;
	display:block;
}

.action-menu li
{
	border-bottom:1px solid #ccc;
	margin:0px;
	padding:0px;
	list-style:none;
	text-align:left;
	position:relative;
}

.action-menu li a
{
	padding:5px;
	display:block;
	font-weight:normal;
	text-decoration:none;
}

.action-menu li a:hover
{
	background:#fff;
}

}