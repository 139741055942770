@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .ui-tabs {
        padding: 10px !important;
    }

    #tnl-gp-subtabs.ui-tabs {
        padding: 5px 0 0 0 !important;
    }

    .ui-tabs .ui-tabs-nav {
        .LESS-ROUNDED-CORNERS-RESET();
    }

    .ui-tabs .ui-tabs-panel {
        padding: 15px 15px !important;
        background: #fff;
        border-width: 0 1px 1px 1px !important;
        border-style: solid;
        border-color: #bbb !important;
    }

    .ui-widget {
        .COS-OPEN-SANS-REGULAR();
    }

    .ui-tabs.ui-widget-content {
	    border: none !important;
    }

    .ui-widget input,
    .ui-widget textarea {
        .COS-OPEN-SANS-REGULAR();
        font-size: 15px;
    }

    .ui-draggable-dragging {
        border: 1px solid @COLOR-TNL-GRAY2;
        background: #FFEE9A;
        width: 400px;
    }

    .ui-dialog-content {
        margin:auto;
    }

     &.ui-resizable .ui-dialog-content {
         border-bottom: 20px solid transparent;
     }

    &.ui-widget-content.ui-resizable { 
        padding:0;
        border: none;
        -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
        -moz-box-shadow:    rgba(0, 0, 0, 0.5) 0px 5px 15px;
        box-shadow:         rgba(0, 0, 0, 0.5) 0px 5px 15px;
    }

    .ui-widget-content .ui-tabs-panel a:hover {
	    color: @COLOR-PM-ORANGE1;
	    text-decoration: underline;
    }
    .ui-widget-content .ui-tabs-panel .tnl-btn-text:hover,
    .ui-widget-content .ui-tabs-panel .tnl-btn-icon-text:hover,
    .ui-widget-content .ui-tabs-panel .tnl-btn-icon:hover {
	    color: @COLOR-TNL-GRAY2;
    }

    .tnl-btn-icon-text span.ui-icon,
    .ui-widget-content .tnl-btn-icon-text span.ui-icon,
    .tnl-btn-icon span.ui-icon,
    .ui-widget-content .tnl-btn-icon span.ui-icon {
	    text-indent: 0;
	    display: inline-block;
    }

    .tnl-btn-icon-text.disabled .ui-icon,
    .ui-widget-content.disabled .tnl-btn-icon-text .ui-icon {
	    display: inline-block;
    }

    .tnl-btn-icon-text.ui-state-default {
	    padding-top: 0;
    }

    .tnl-btn-icon-text.ui-state-default .ui-icon  {
	    position: relative;
	    top: 3px;
    }

    div.ui-widget-overlay {
	    opacity: 0.5 !important;
	    filter: Alpha(Opacity=50) !important;
    }

    .ui-widget-header {
        margin: 0 !important;
        padding: 0 !important;
	    background: @COLOR-PM-GREY7 !important;
        border-radius: 0 !important;
        border-width: 0 0 1px 0 !important;
        border-style: solid;
        border-color: #e5e5e5 !important;

        .ui-dialog-title {
            .COS-OPEN-SANS-600();
            font-size: @COS-FONT-SIZE-16;
            color: #fff !important;
            text-transform: capitalize;
            line-height: 1.9rem;
            padding: 1em !important;
        }

        .ui-dialog-titlebar-close {
            position: absolute;
            top: 50%;
            right: 15px;
            width: 26px;
            height: 26px;
            margin: -13px 0 0 0;
            padding: 0;
            transition: none;

            span:first-child {
                font-size: 24px;
                color: #fff;
                .PM-TRANSITION();

                &:hover {
                    color: @COLOR-PM-GREEN1;
                    .PM-TRANSITION();
                }
            }

            &.ui-state-hover,
            &.ui-state-focus {
                border: none;
                background: none;
            }
        }
    }

    .ui-dialog-content {
        &.ui-widget-content {
            padding: 25px !important;
            background-color: #fff;
            background-image: none;
        }
    }

    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default {
        // Note: the jquery tabs do not work like the Bootstrap tabs. You must click the link.
        // I did not match to the Bootstrap tab hover affect since clicking the tab does nothing.
        position: relative;
        top: 1px;
        padding: 10px 15px 0;
        background: transparent;
        font-size: @PM-FONT-SIZE-DEFAULT;
        color: @COLOR-TNL-GRAY3;
        border-top: none;
        border-right: none;
        border-left: none;
        border-color: @COLOR-TNL-GRAY4;
        
        a,
        a:visited {
            margin: 0 0 10px 0;
            padding: 0;
            color: @COLOR-PM-WHITE1;
        }

        a:hover {
            color: @COLOR-PM-WHITE1;
            text-decoration: underline !important;
        }

        a:active,
        a:focus {
            color: @COLOR-PM-GREEN1;
            outline: none !important;
        }

        &.ui-state-active {
            background: #fff;
            border: 1px solid @COLOR-TNL-GRAY4;
            border-bottom-color: #fff;
            outline: none !important;

            span {
                outline: none !important;
            }

            a,
            a:visited {
                color: @COLOR-COS-DEFAULT !important;
            }
            a:hover {
                color: @COLOR-COS-DEFAULT !important;
                text-decoration: none !important;
            }
        }
    }
}