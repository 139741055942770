@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .tnl-portal-section {

        &.with-aside {
	        display: table;
        }

        &.with-aside .tnl-portal-aside {
	        display: table-cell !important;
	        float: none !important;
        }

        > .tnl-portal-header {
	        margin: 0;
	        padding: 20px 20px 10px;
	        .LESS-BACKGROUND();
	        border-bottom: 1px solid @COLOR-TNL-GRAY3;
        }

        > .tnl-portal-header h1 {
	        margin: 0;
	        color: @COLOR-TNL-GRAY2;
	        font-size: 20px;
	        font-weight: normal;
        }

        > .tnl-portal-actions {
	        margin: 0;
	        padding: 10px;
	        background:  @COLOR-TNL-GRAY5;
        }

        > .tnl-portal-actions > span {
	        margin-left: 5px;
        }

    }
    
    .tnl-portal-article {
	    margin-top: 10px;

        &.with-aside {
	        float: left;
	        width: 74%;
        }

        > .tnl-container-header {
	        margin: 0;
	        padding: 0;
        }

        > .tnl-container-header h1 {
	        font-size: 18px;
	        font-weight: normal;
	        color: @COLOR-TNL-GRAY2;
        }

        &.with-facet-nav {
	        position: relative;
	        margin: 0 0 0 232px;
	        padding-left: 10px;
	        vertical-align: top;
        }

    }


    .tnl-portal-aside-bs {
        
        .tnl-nav  {
	        margin: 0;
            max-width: none;
        }

    }

    .tnl-portal-aside,
    .tnl-portal-aside-bs {

        .tnl-nav  {
            min-width: 150px;
            max-width: 200px;
            margin: 0 25px 0 0;

        }

        &.responsive {
            .tnl-nav {
                min-width: 0;
                max-width: none;
                margin: 0;
            }
        }

        .tnl-nav h1 {
            margin: 0;
            padding: 10px 0 0;
            color: @COLOR-TNL-GRAY2;
            border-bottom: 4px double @COLOR-TNL-GRAY4;
            .LESS-ELLIPSIS-ONE-LINE();
            .COS-OPEN-SANS-600();

        }

        .tnl-nav ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .tnl-nav ul li a {
            display: block;
            padding: 15px 0 0 0;
            font-size: @FONT-SIZE-SM;
        }

        .tnl-nav ul li.selected a {
	        color: @COLOR-TNL-ORANGE1;
            .COS-OPEN-SANS-600();
        }

        .tnl-nav .disabled {
            color: @COLOR-TNL-GRAY2 !important;
        }

        .tnl-nav .disabled:hover {
            color: @COLOR-TNL-GRAY2 !important;
            background: none !important;
            .LESS-BOX-SHADOW-RESET();
            cursor:not-allowed;
            border: none !important;
        }

        > .tnl-nav > .tnl-nav-article {
	        margin: 0 0 15px 0;
        }

        > .tnl-nav > .tnl-nav-article > .tnl-container-header {
	        margin: 0 0 5px 0;
	        border-bottom: 1px dotted @COLOR-TNL-GRAY4;
        }

        > .tnl-nav > .tnl-nav-article > .tnl-container-header h1 {
	        font-size: 13px;
	        color: @COLOR-TNL-GRAY2;
	        font-weight: normal;
        }

        > .tnl-nav > .tnl-nav-article .tnl-nav-article {
	        margin: 11px 0;
        }

        > .tnl-nav > .tnl-nav-article .tnl-nav-article > .tnl-container-header {
	        margin: 0 0 5px 0;
        }

        > .tnl-nav > .tnl-nav-article .tnl-nav-article > .tnl-container-header h1 {
	        font-size: 14px;
	        color: @COLOR-TNL-GRAY2;
	        border-bottom: 1px dotted @COLOR-TNL-GRAY4;
        }

        > .tnl-nav > .tnl-nav-article .tnl-container-assessment {
	        margin: -22px 0 0;
	        padding-left: 4px;
	        width: 67px;
	        font-size: 12px;
	        font-weight: bold;
	        color: @COLOR-TNL-GRAY2;
	        background: #FFF;
        }

        > .tnl-nav > .tnl-nav-article .tnl-display > .tnl-display {
	        padding: 10px 10px 5px;
	        border: 1px solid @COLOR-TNL-GRAY4;
        }

    }

}