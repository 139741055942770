@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .tnl-student-selection .course {
	    margin-bottom: 30px;
    }

    div.tnl-student-selection-header {
        padding-bottom: 5px;
    }

    .tnl-student-selection-header h1 {
	    margin: 0;
	    color: @COLOR-TNL-GRAY2;
	    font-size: 20px;
        .COS-OPEN-SANS-600();
        .LESS-ELLIPSIS-ONE-LINE();
    }

    .tnl-student-selection-aligned {
	    font-size: 12px;
	    color: @COLOR-SUCCESS;
    }

    .tnl-student-selection-aligned span.tnlif.fa {
        color: @COLOR-SUCCESS;
    }

    .tnl-student-selection .panel-group {
	    margin: 0;
    }

    .tnl-student-selection.tnl-not-selected .content,
    .tnl-student-selection.tnl-not-selected .content a,
    .tnl-student-selection.tnl-not-selected .content a:hover {
	    color: @COLOR-TNL-GRAY5;
    }

    .tnl-student-selection .course .header h1 {
        margin: 0 0 20px 0;
    }

    .tnl-student-selection a.tnl-icon-text-link {
        padding-left: 0;
    }

    .tnl-student-selection .panel {
        border: 1px solid @COLOR-TNL-GRAY3;
    }

    .tnl-student-selection .student h3,
    .tnl-view-assessment-selection .tnl-item-list .title {
        margin: 2px 0;
        font-size: 18px;
        font-weight: 600;
    }

    .tnl-view-assessment-selection .tnl-item-list .student-details {
	    margin-top: 5px;
    }

    .tnl-student-selection .student .panel-heading {
        padding-top: 10px;
        padding-bottom: 0;
    }

    .tnl-student-selection .student .panel-heading h4 {
        .COS-OPEN-SANS-600();
    }

    .tnl-student-selection .section > .panel-heading h4 span.tnlif.fa {
        color: @COLOR-COMPLETE;
    }

    .tnl-student-selection .student .actionbar .btn:last-child {
        margin-right: 0;
     }

     .tnl-student-selection .panel-body .actionbar .tnlf-un-align-section {
        display: none;
     }

    .tnl-student-selection .student .btn-group .btn {
        padding: 3px 8px;
        font-size: 14px;
        .LESS-BOX-SHADOW()
    }

    .tnl-student-selection .student .btn-group .btn.included,
    .tnl-student-selection .student .btn-group .btn.excluded {
        color: #fff;
        .COS-OPEN-SANS-600();
    }

    .tnl-student-selection .student .btn-group .btn.included {
        background: @COLOR-SUCCESS;
        border: 1px solid @COLOR-SUCCESS;
    }

    .tnl-student-selection .student .btn-group .btn.excluded {
        background: @COLOR-DANGER;
        border: 1px solid @COLOR-DANGER;
    }

    .tnl-student-selection .student .panel-body {
        margin: 0;
        padding-top: 0;
        padding-bottom: 10px;
        clear: both;
        color: @COLOR-TNL-GRAY2;
    }

    .tnl-student-selection .panel-body .actionbar .btn,
    .tnl-student-selection .panel-body .actionbar a {
        margin-right: 10px;
     }

    .tnl-student-selection .student .panel-body .col {
        padding-top: 0;
        padding-bottom: 0;
    }

    .tnl-student-selection .student p {
        margin-bottom: 2px;
        font-size: 14px;
    }
 
    .tnl-student-selection .student .panel-body dl {
        margin: 0;
        color: @COLOR-TNL-GRAY2;
     }
 
    .tnl-student-selection .student .panel-body dt {
        width: auto;
        margin-right: 5px;
     }
 
    .tnl-student-selection .student .panel-body dd {
        margin-left: 0;
        font-size: 14px;
     }
 
    .tnl-student-selection.container {
        width: auto;
     }
 
    .tnl-student-selection .student.excluded h3 {
        color: @COLOR-DANGER;
        text-decoration: line-through;
    }

    .tnl-student-selection .course .section {
        margin-right: 20px;
        margin-left: 20px;
    }

    .tnl-student-selection > .title h1 {
        margin: 0;
    }

    .tnl-student-selection.list-view .student {
        border-bottom: 1px solid @COLOR-TNL-GRAY4;
    }

    .tnl-student-selection .student aside {
        text-align: center;
    }

    .tnl-student-selection.list-view .student .panel {
        margin: 0;
        padding: 15px;
        border: 0;
        .LESS-ROUNDED-CORNERS-RESET();
        .LESS-BOX-SHADOW-RESET();
    }

    .tnl-student-selection.list-view .student-details .row > div {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .tnlf-column-configuration-container select {
	    width: 90%;
    }

    .input-sm, .form-horizontal .form-group-sm .form-control {
	    padding: 2px 10px;
    }
   
    .tnl-student-selection.list-view .student .panel-heading,
    .tnl-student-selection.list-view .student .panel-body {
        padding: 0;
    }

    .tnl-student-selection.grid-view .student .student-details .row > div {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .tnl-student-selection.grid-view .student aside {
        margin-top: 10px;
    }

    .tnl-student-selection .add-section,
    .tnl-student-selection .add-student {
	    padding: 15px 0 10px;
    }

}