@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {
    #tnl-filter-menu {
	height: 18px;
    }
    .tnl-popup-menu-body {
	    position: absolute;
	    z-index: 100;
        padding: 15px 0 0;
        width: 350px;
        font-size: @COS-FONT-SIZE-DEFAULT;
        line-height: 18px;
        background: #fff;
        border: 1px solid @COLOR-TNL-GRAY4;
        border-radius: 0 0 4px 4px;
        margin: 0;

        .tnl-popup-menu-heading {
            display: block;
            margin: 0 0 2px 10px;
            padding: 0;
            font-size: 13px;
            color: @COLOR-TNL-GRAY3;
            .COS-OPEN-SANS-600();
        }

        ul {
            margin: 0 15px 15px;
            padding: 0;
            list-style-type: none;
            color: @COLOR-TNL-GRAY2;
        }

        ul li {
            margin: 0;
            padding: 5px 0;
        }

        ul .tnl-popup-menu-selected {
	        color: @COLOR-COS-DEFAULT;
            .COS-OPEN-SANS-600();
        }

        .tnl-popup-menu-top-panel {
	        margin-bottom: 15px;
	        border-bottom: 1px solid @COLOR-TNL-GRAY6;
        }

        .tnl-popup-menu-top-panel ul {
            margin-bottom: 5px;
        }

        .tnl-popup-menu-bottom-panel ul {
	        margin: 0 0 10px;
	        padding-top: 4px;
	        font-size: @COS-FONT-SIZE-DEFAULT;
	        border-top: 1px solid @COLOR-TNL-GRAY6;
	        text-align: center;
        }

        a {
	        color: @COLOR-COS-DEFAULT;
        }

        a:hover {
	        color: @COLOR-TNL-ORANGE1;
        }

    }

    .tnl-popup-row-number {
	    display: inline-block;
	    margin: 0 5px 0 0;
	    min-width: 18px;
	    text-align:right;
	    color: @COLOR-TNL-GRAY3;
	    font-size: @COS-FONT-SIZE-DEFAULT;
    }

    .tnl-header > .tnl-popup-menu {
        position: relative;
        display: inline-block;
	    .LESS-FIX-IE7-INLINE-BLOCK();
        height: 28px;
        width: 28px;
        padding: 4px 0 0;
        border: none;
        top: 0;
        left: 0;
    }

    html.lt-ie8 .tnl-header > .tnl-popup-menu {
	    .LESS-FIX-IE7-INLINE-BLOCK();
    }

    .tnl-header > .menu-highlight {
        background: #fff;
    }

    .tnl-header > .tnl-popup-menu .tnl-popup-menu-body {
        margin: 12px 0 0;
        border: 1px solid @COLOR-TNL-GRAY3;
        border-top: none;    
        max-height: 300px;
        overflow: auto;
    }

    .tnl-popup-menu {
        cursor: pointer;
    }

    .tnl-teacher-indicator > .tnl-popup-menu .tnl-popup-menu-body {
        margin: 16px 0 0 0;
        background: #fff;
        border: 1px solid @COLOR-TNL-GRAY3;
    }

    .tnl-teacher-indicator {
        margin: 0 0 10px 0;
        padding: 0;
        border: 1px solid @COLOR-TNL-GRAY3;
        background: @COLOR-TNL-GRAY6;

        .tnl-popup-menu {
            margin: 10px;
            vertical-align: top;

            &.tnl-person-menu {
                .fa-users {
                    position: relative;
                    top: 13px;
                    width: 15px;
                    height: 15px;
                    padding-left: 5px;
                }
            }
        }
    }

    .tnl-teacher-indicator > .menu-highlight {
        border-radius: 4px 0 0 0;
    }

    .tnl-popup-menu-default > .tnl-popup-menu .tnl-popup-menu-body {
        margin: 4px 0 0 -2px;
        background: #fff;
        border: 1px solid @COLOR-TNL-GRAY2;
    }

    .tnl-popup-menu-default > .tnl-popup-menu {
	    padding: 2px;
	    border: none;
        .PM-ROUNDED-CORNERS();
    }

    .tnl-popup-menu-default > .menu-highlight {
	    padding: 2px;
	    background: @COLOR-TNL-GRAY2;
        border-radius: 4px 4px 0 0;
    }

    .tnl-icon-menu ul {
        cursor: auto;
    }

    .tnl-teacher-indicator .evaluation-title,
    .tnl-teacher-indicator .evaluation-person-name {
        display: inline-block;
    }
    .tnl-teacher-indicator .tnl-person {
        display: inline-block;
        vertical-align: top;
        margin: 12px 0 10px 0;
        font-size: 15px;
        z-index: 1;
    }

    html.lt-ie8 .tnl-teacher-indicator > div {
        zoom: 1;
        display: inline;
    }

    .tnl-teacher-indicator > span {
	    display: inline-block;
	    position: relative;
	    top: -7px;
	    font-size: @COS-FONT-SIZE-DEFAULT;
    }

    .tnl-person {
	    padding: 8px 0;
	    vertical-align: top;
    }

    .evaluation-title {
	    display: inline-block;
    }

    .evaluation-name {
	    display: inline-block;
    }

    .tnl-member-navigation-menu-loader {
        border: 1px dashed @COLOR-TNL-GRAY3;
        margin: 0 0 10px;
        overflow: hidden;
        padding: 11px;
    }
}