@import (reference) "_global.less";

.empari {

    .tnl-outer-shadow {
        .LESS-BOX-SHADOW();
    }

    .tnl-drop-shadow {
        .LESS-SHADOW-POPUP-MENU();
    }

    .tnl-channel {
        margin: 0 0 20px 0;
        padding: 0;
    }

    .tnl-channel-header2 {
        overflow: hidden;
    }

    .tnl-channel-header2,
    .tnl-channel-content2,
    .tnl-channel-footer2 {
        .LESS-BOX-SHADOW();
    }

    .tnl-channel-content2 > .tnl-channel-content2-container {
        .LESS-CONTAINER();
        padding: 15px;
        border: none;
        background-image: none;
        background: #fff;
    }

    .tnl-channel:last-child {
        margin: 0;
    }

    .tnl-channel-content2 {

            .tnl-container-article .tnl-container-header h1 {
            margin: 0 0 10px;
            padding: 0;
            color: @COLOR-TNL-GRAY2;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
        }

        .tnl-container-article .tnl-teaser-paginate {
            overflow: auto;
            padding: 0;
        }

        .tnl-two-column-text > span {
            text-align: center;
        }

        a.tnl-icon-text-link {
            margin: 0;
            padding: 0;
        }

        .tnl-container-article {
            margin-bottom: 10px;
            background-color: #fff;
        }

        .tnl-container-article:last-child {
            margin-bottom: 0;
        }

        div.tnl-message-warning {
            margin-bottom: 10px;
            padding: 10px 25px;
            font-size: 12px;
        }

        .tnl-item-list > li.show-all {
            text-align: center;
            border: none;
        }

        .courses a.tnl-icon-text-link {
            display: block;
            padding: 0;
            margin: 0;
        }

        .tnl-item-list > li.show-all a,
        .tnl-item-list > li.show-all a:hover {
            display: block;
            padding: 2px 5px;
            .LESS-ROUNDED-CORNERS();
        }

        .tnl-item-list > li.show-all a:hover {
            color: #fff;
            text-decoration: none;
            background: @COLOR-TNL-ORANGE1;
            .LESS-BOX-SHADOW-INSET();
        }

        .generic {
            line-height: 18px;
            background: #fff;
        }

    }
    
    .tnl-generic-container .tnl-container-header h1 {
        font-size: 16px;
        color: #666;
        font-weight: 600;
    }
}