@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .tnl-toggle-nav-off {
        background: @COLOR-TNL-GRAY2;
        padding: 5px;
        border-right: 1px solid @COLOR-TNL-GRAY2;
        color: #fff;
        cursor: pointer;
    }

    .tnl-toggle-nav-on {
        background: #fff;
        border-right: 0;
        color: @COLOR-TNL-GRAY1;
    }

    .tnl-toggle-nav-hover {
        background: @COLOR-TNL-GRAY4;
        color: #000;
    }

    .tnl-toggle-nav-border-bottom {
        border-bottom: 1px solid @COLOR-TNL-GRAY2;
    }

    .tnl-toggle-nav-border-top {
        border-top:1px solid @COLOR-TNL-GRAY2;
    }

    .tnl-toggle-box {
        display: inline-block;
        background: #fff;
        border: 1px solid @COLOR-TNL-GRAY3;
        padding: 2px;
        font-size: 14px;
        width: auto;
        border-radius: 2px;
    }

    .tnl-toggle {
        height: 18px;
        width: 42px;
        text-align: center;
        padding-top: 1px;
        cursor: pointer;
        font-size: 13px;
    }

    .tnl-toggle-off-off {
        float: left;
        display: inline;
        color: #fff;
        background: @COLOR-TNL-GRAY3;
        border: 1px solid @COLOR-TNL-GRAY3;
        margin-right: -1px;
        border-radius: 2px 0 0 2px;
    }

    .tnl-toggle-off-on {
        float: left;
        display: inline;
        color: @COLOR-TNL-GRAY2;
        background: @COLOR-TNL-GRAY6;
        border: 1px solid @COLOR-TNL-GRAY4;
        margin-right: -1px;
        border-radius: 2px 0 0 2px;
    }

    .tnl-toggle-on-on {
        display: inline-block;
        color: #fff;
        background: @COLOR-PM-RED1;
        border: 1px solid @COLOR-PM-RED1;
         border-radius: 0 2px 2px 0;
    }

    .tnl-toggle-on-off {
        display: inline-block;
        color: @COLOR-TNL-GRAY2;
        background: @COLOR-TNL-GRAY6;
        border: 1px solid @COLOR-TNL-GRAY3;
        border-radius: 0 2px 2px 0;
    }

}