@import (reference) "_global.less";

.empari {

    .tnl-popup-panel,
    .tnl-advanced-popup-panel {
	    position: relative;
	    display: inline-block;
	    .LESS-FIX-IE7-INLINE-BLOCK;
	    z-index: 4;
	    cursor: pointer;
    }

    html.lt-ie8 .tnl-advanced-popup-panel {
	    z-index: 4000;
    }

    .tnl-popup-panel > a, .tnl-advanced-popup-panel > a {
        display: inline-block;
    }

    .tnl-popup-panel > div, .tnl-advanced-popup-panel > div {
        display:none;
        position:absolute;
        top:0;
        left:25px;
        z-index:2;
    }

    .tnl-popup-panel.down > div, .tnl-advanced-popup-panel.down > div {
        top:20px;
        left:-1px;
    }

    .tnl-popup-panel.down > div.left,
    .tnl-advanced-popup-panel.down > div.left {
        top:20px;
        left:auto;
        right:-1px;
    }

    .tnl-popup-panel .tnl-btn-icon {
        color: #fff;
        background: @COLOR-TNL-GRAY3;
        margin: 1px;
    }

    .tnl-popup-panel .tnl-btn-icon:hover {
        background: @COLOR-TNL-GRAY4;
        .LESS-BOX-SHADOW-RESET();
        border: none;
        margin: 1px;
    }
}