@import (reference) "_global.less";

.empari {

    .tnl-social-feed.well .tnl-social-feed-header {
        margin-bottom: 10px;
    }

    .tnl-social-feed.well .tnl-social-feed-header h1 {
	    position: relative;
	    left: 48px;
	    padding-right: 48px;
	    color: @COLOR-TNL-GRAY1;
	    font-weight: normal;	
    }

    .tnl-social-feed-icon {
        position: absolute;
        top: -1px;
        left: 16px;
    }

    .tnl-social-feed-icon span.tnlif {
        font-size: 24px;
        color: @COLOR-TNL-GRAY1;
    }

    .tnl-social-feed-item-avatar {
	    position: absolute;
	    width: 40px;
	    height: 40px;
	    text-align: center;
	    background: ;
    }

    .tnl-social-feed-item-avatar span {
	    position: relative;
	    top: 10px;
        color: #fff;
        font-size: 20px;
    }

    .tnl-social-feed-item-title,
    .tnl-social-feed-item-timestamp,
    .tnl-social-feed-item-body {
        position: relative;
        left: 48px;
        padding-right: 58px;
    }

    .tnl-social-feed-item-title,
    .tnl-social-feed-item-timestamp {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1.3;
    }

    .tnl-social-feed.well .tnl-social-feed-item-title {
        color: @COLOR-TNL-GRAY2;
        font-size: 18px;
        font-weight: normal;
    }

    .tnl-social-feed-item-timestamp {
        color: @COLOR-TNL-GRAY2;
        font-size: 13px;
    }

    .tnl-social-feed-item-body {
	    padding-top: 5px;
        font-size: 15px;
    }

    .tnl-social-feed-footer .btn {
        position: relative;
        top: 1px;
    }

}