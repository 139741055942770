@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .tnl-box {
	    .LESS-BOX;
	    #Fonts > .SansSerif;
	    line-height: 15px;

        &.tnl-box-as-channel {
	        min-width: 0;
        }
        &.tnl-box-as-page-header .tnl-box-header > h1 {
            .COS-OPEN-SANS-600();
        }
        &.tnl-box-as-page-header .tnl-box-header > h1 a {
	        font-weight: normal;
        }
    }

    .tnl-box-header {
	    position: relative;
	    margin: 0 0 20px;
	    padding: 0;

        > h1 {
	        margin: 0;
	        padding: 0;
	        color: #000;
	        font-size: 15px;
	        font-weight: normal;
        }

        > p {
	        margin: 0 0 5px;
	        padding: 0;
	        font-size: 14px;
        }

    }

    .tnl-box-header-prefix {
	    margin: 0 5px 0 0;
	    font-size: 14px;
        .COS-OPEN-SANS-600();
    }

    .tnl-box-header-details {
	    margin: 5px 60px 0 0;
	    padding: 0;
	    color: @COLOR-TNL-GRAY2;
	    font-size: 14px;
    }

    .tnl-box-header-aside {
	    width: 50px;
	    float: right;
	    position: relative;
    } 

    .tnl-box-article {
	    margin: 0 0 20px;
	    padding: 0;
    }

    .tnl-box-article-section {
	    margin: 0 0 10px;
	    padding: 0;
    }

    .tnl-box-column {
	    float: left;
        
        > div {
	        margin-right: 15px;
        }

        > div:last-child {
	        margin-right: 0;
        }

        &.tnl-box-column-1-3 {
	        width: 33%;
        }

        &.tnl-box-column-1-2 {
	        width: 50%;	
        }

        &.tnl-box-column-2-3 {
	        width: 66%;
        }

        &:last-child {
	        margin-right: 0;
        }

    }

    .tnl-box-clear-floats {
	    clear: both;
	    display: block;
	    height: 1px;
	    margin: 0;
	    padding: 0;
    }
    

    html.lt-ie9 {

        .tnl-box {
            .LESS-BOX-IE-9;
        }

    }

    html.lt-ie8 {

        .tnl-box {
	        .LESS-BOX-IE-8;
        }

    }
}