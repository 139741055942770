@import (reference) "_global.less";

.empari {
    /* action btn */
    .tnl-action-btn { width: 79px; height: 20px; background-image: url("/images/sprites/tnl-action-btn.png"); background-repeat: no-repeat; display: block; text-indent: -99999px; overflow: hidden; }
    .tnl-action-btn-on { background-position: 0 0; }
    .tnl-action-btn-off { background-position: 0 -20px; }

    /* system admin */
    .tnl-system-admin { 
        width: 32px; height: 32px; background-image: url("/images/sprites/tnl-system-admin.png"); background-repeat: no-repeat; display: block; text-indent: -99999px; overflow: hidden; margin: 10px; 
        &.tnl-gears { background-position: 0 0; }
        &.tnl-cube { background-position: -32px 0; }
        &.tnl-alert-box { background-position: 0px -32px; }
        &.tnl-graph { background-position: -32px -32px; }
    }

    /* admin icons */
    .tnl-admin-icon { width: 24px; height: 24px; background-image: url("/images/sprites/tnl-admin-icons.png"); background-repeat: no-repeat; display: block; text-indent: -99999px; overflow: hidden; }
    .tnl-admin-box { background-position: 0 0; }
    .tnl-admin-e-arrow-paper  { background-position: -25px 0; }
    .tnl-admin-pencil  { background-position: -50px 0; }
    .tnl-admin-webbing  { background-position: -75px 3px; }
    .tnl-admin-key  { background-position: -105px 0; }
    .tnl-admin-horn  { background-position: -129px 0; }
    .tnl-admin-scantron-pencil  { background-position: 0 -28px; }
    .tnl-admin-scantron-hat  { background-position: -25px -28px; }
    .tnl-admin-scantron-glasses  { background-position: -50px -28px; }
    .tnl-admin-people  { background-position: -76px -26px; }
    .tnl-admin-login  { background-position: -100px -20px; }
    .tnl-admin-moodle  { background-position: -130px -29px; }
    .tnl-admin-3-boxes  { background-position: 0 -53px; }
    .tnl-admin-lightbulb  { background-position: -25px -52px; }
    .tnl-admin-house  { background-position: -52px -54px; }
    .tnl-admin-target-arrow  { background-position: -78px -54px; }
    .tnl-admin-dollar-sign  { background-position: -100px -40px; }
    .tnl-admin-present  { background-position: -130px -55px; }
    .tnl-admin-treasure-map  { background-position: 0 -78px; }
    .tnl-admin-calendar-day  { background-position: -25px -80px; }
    .tnl-admin-calendar-arrow  { background-position: -52px -80px; }
    .tnl-admin-calendar-date  { background-position: -78px -80px; }
    .tnl-admin-ruler  { background-position: -104px -78px; }
    .tnl-admin-scales  { background-position: -130px -80px; }
    .tnl-admin-checkbox  { background-position: -78px -129px }
    .tnl-admin-green-check  { background-position: -50px -129px; }
    .tnl-admin-green-plus  { background-position: -50px -80px; }
    .tnl-admin-lightning  { background-position: -24px -130px; }
    .tnl-admin-speaker  { background-position: -100px -80px; }
    .tnl-admin-blank  { background-position: -125px -80px; }
    .tnl-admin-books  { background-position: 0px -104px; }
    .tnl-admin-open-book  { background-position: -25px -104px; }
    .tnl-admin-notepad  { background-position: -52px -104px; }
    .tnl-admin-pie-chart  { background-position: -78px -107px; }
    .tnl-admin-globe  { background-position: -104px -104px; }
    .tnl-admin-stairs  { background-position: -130px -100px; }
    .tnl-admin-pdconnect  { background-position: 0 0; }

    /* arrows */
    .tnl-arrows { width: 13px; height: 13px; background-image: url("/images/sprites/tnl-arrows.png"); background-repeat: no-repeat; display: block; text-indent: -99999px; overflow: hidden; }
    .tnl-arrows-boxed-right { background-position: 0 0; }
    .tnl-arrows-boxed-left { background-position: -13px 0; }
    .tnl-arrows-arrow-down { background-position: -26px 0; }
    .tnl-arrows-arrow-right { background-position: -39px 0; }
    .tnl-arrows-boxed-right-dis { background-position: 0 -13px; }
    .tnl-arrows-boxed-left-dis { background-position: -13px -13px; }
    .tnl-arrows-arrow-down-dis { background-position: -26px -13px; }
    .tnl-arrows-arrow-right-dis { background-position: -39px -13px; }

    /* double arrows */
    .tnl-double-arrow { width: 26px; height: 13px; background-image: url("/images/sprites/tnl-double-arrow.png"); background-repeat: no-repeat; display: block; text-indent: -99999px; overflow: hidden; }
    .tnl-double-arrow-right { background-position: 0 0; }
    .tnl-double-arrow-left { background-position: -26px 0; }
    .tnl-double-arrow-right-dis { background-position:  0 -13px; }
    .tnl-double-arrow-left-dis { background-position: -26px -13px; }

    /* round icons lg */
    .tnl-round-lg { width: 32px; height: 32px; background-image: url("/images/sprites/tnl-round-icons-lg.png"); background-repeat: no-repeat; display: block; text-indent: -99999px; overflow: hidden; }
    .tnl-round-lg-green-check { background-position: 0 0; }
    .tnl-round-lg-yellow-minus { background-position: -32px 0; }
    .tnl-round-lg-orange-circle { background-position: -64px 0; }
    .tnl-round-lg-blue-circle { background-position: 0 -32px; }
    .tnl-round-lg-dis-x { background-position: -32px -32px; }
    .tnl-round-lg-red-x { background-position: -64px -32px; }

    /* round icons md */
    .tnl-round-md { width: 18px; height: 18px; background-image: url("/images/sprites/tnl-round-icons-md.png"); background-repeat: no-repeat; display: inline-block; text-indent: -99999px; overflow: hidden; }
    .tnl-round-md-green-check { background-position: 0 0; }
    .tnl-round-md-yellow-minus { background-position: -18px 0; }
    .tnl-round-md-orange-circle { background-position: -36px 0; }
    .tnl-round-md-red-x { background-position: -54px 0; }
    .tnl-round-md-red-1 { background-position: 0 -18px; }
    .tnl-round-md-red-2 { background-position: -18px -18px; }
    .tnl-round-md-red-3 { background-position: -36px -18px; }
    .tnl-round-md-blue-1 { background-position: 0 -36px; }
    .tnl-round-md-blue-2 { background-position: -18px -36px; }
    .tnl-round-md-blue-3 { background-position: -36px -36px; }
    .tnl-round-md-blue-circle { background-position: -54px -36px; }
    .tnl-round-md-blue-check { background-position: -72px -36px; }
    .tnl-round-md-purple-question { background-position: 0 -54px; }
    .tnl-round-md-orange-n { background-position: -18px -54px; }
    .tnl-round-md-dis-circle { background-position: 0 -72px; }
    .tnl-round-md-dis-plus { background-position: -18px -72px; }
    .tnl-round-md-dis-x { background-position: -36px -72px; }
    .tnl-round-md-grey-x { background-position: -54px -72px; }

    /* round icons sm */
    .tnl-round-sm { width: 14px; height: 14px; background-image: url("/images/sprites/tnl-round-icons-sm.png"); background-repeat: no-repeat; display: block; text-indent: -99999px; overflow: hidden; }
    .tnl-round-sm-green-circle { background-position: 0 0; }
    .tnl-round-sm-orange-circle { background-position: -14px 0; }
    .tnl-round-sm-yellow-circle { background-position: 0 -14px; }
    .tnl-round-sm-red-circle { background-position: -14px -14px; }

    /* boxes sm */
    .tnl-boxes-sm { width: 12px; height: 12px; background-image: url("/images/sprites/tnl-boxes-sm.png"); background-repeat: no-repeat; display: block; text-indent: -99999px; overflow: hidden; }
    .tnl-boxes-sm-green { background-position: 0 0; }
    .tnl-boxes-sm-orange { background-position: -12px 0; }
    .tnl-boxes-sm-yellow { background-position: -24px 0; }
    .tnl-boxes-sm-red { background-position: 0 -12px; }
    .tnl-boxes-sm-blue { background-position: -12px -12px; }
    .tnl-boxes-sm-grey { background-position: -24px -12px; }

    /* subject icons */
    .tnl-subject-icons { width: 38px; height: 38px; background-image: url("/images/sprites/tnl-subject-icons.png"); background-repeat: no-repeat; display: block; text-indent: -99999px; overflow: hidden; }
    .tnl-subject-icons-art { background-position: 0 0; }
    .tnl-subject-icons-desk { background-position: -38px 0; }
    .tnl-subject-icons-apple { background-position: -76px 0; }
    .tnl-subject-icons-brain { background-position: -114px 0; }
    .tnl-subject-icons-instruction { background-position: 0 -38px; }
    .tnl-subject-icons-language { background-position: -38px -38px; }
    .tnl-subject-icons-literacy { background-position: -76px -38px; }
    .tnl-subject-icons-math { background-position: -114px -38px; }
    .tnl-subject-icons-science { background-position: 0 -76px; }
    .tnl-subject-icons-globe { background-position: -38px -76px; }
    .tnl-subject-icons-lightbulb { background-position: -76px -76px; }
    .tnl-subject-icons-computer { background-position: -114px -76px; }

}