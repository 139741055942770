@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .assessment-help-text-title {
        display: none;
        font-size: 14px;
        text-align: left;
        padding: 5px;
        background-color: @COLOR-TNL-GRAY5;
        margin: 0 0 4px 0;
        .COS-OPEN-SANS-600();
    }

    .assessment-question-text {
        font-size: 14px;
        text-align: left;
        padding-bottom: 5px;
        .COS-OPEN-SANS-600();
    }

    .assessment-questions {
        width: 90%;
        padding: 10px 0 20px 0;
    }

    .assessment-header {
        border: 1px solid @COLOR-TNL-GRAY3;
        background-color: #ffffe9;
        width: 90%;
        margin: 0 0 15px 0;
    }

    .assessment-help-text {
        padding: 5px;
        background-color: #ffffe9;
    }

    .question-table {
        background-color: @COLOR-TNL-GRAY5;
    }

    .assess-icon-1 {
        background: url(/images/icons/assessment-not-started-sml.png);
        width: 18px;
        height: 18px;
    }

    .assess-icon-2 {
        background: url(/images/icons/assessment-incomplete-sml.png);
        width: 18px;
        height: 18px;
    }

    .assess-icon-3 {
        background: url(/images/icons/assessment-current-sml.png);
        width: 18px;
        height: 18px;
    }

    .assess-icon-4 {
        background: url(/images/icons/assessment-out-of-date-sml.png);
        width: 18px;
        height: 18px;
    }

    .assess-teaser-icon-1 {
        background: url(/images/icons/assess_teaser_red_light.gif);
        width: 14px;
        height: 14px;
    }

    .assess-teaser-icon-2 {
        background: url(/images/icons/assess_teaser_yellow_light.gif);
        width: 14px;
        height: 14px;
    }

    .assess-teaser-icon-3 {
        background: url(/images/icons/assess_teaser_green_light.gif);
        width: 14px;
        height: 14px;
    }

    .assess-teaser-icon-4 {
        background: url(/images/icons/assess_teaser_orange_light.gif);
        width: 14px;
        height: 14px;
    }

    .tech-assess-profile {
        background: url(/images/btns/take_tech_assess_profile_btn.gif);
        width: 250px;
        height: 50px;
        cursor: pointer;
        margin: 0 5px;
    }

    .assessments-teaser-table1 {
        width: 100%;
    }

    .assessments-teaser-table2 {
        width: 100%;
        background: @COLOR-TNL-GRAY5;
        border-right: 1px solid @COLOR-TNL-GRAY5;
    }

    .assessments-teaser-table2 th {
        padding: 3px;
    }

    .assessments-teaser-table2 td {
        padding: 3px;
        border: 1px solid @COLOR-TNL-GRAY5;
        border-width: 0 0 1px 1px;
    }

    .assessments-teaser-key {
        margin: 4px 2px 2px 0;
        border: 1px solid @COLOR-TNL-GRAY5;
        border-left: 0;
        background: @COLOR-TNL-GRAY5;
        float: right;
    }

    .assessments-teaser-key .row1 {
        font-size: 14px;
        text-align: center;
        .COS-OPEN-SANS-600();
    }

    .assessments-teaser-key div {
        padding: 3px;
        *padding: 2px;
        text-align: left;
        border-width: 0 0 1px 1px;
        border-style: solid;
        border-color: @COLOR-TNL-GRAY5;

    }

    .assessments-teaser-key .key-img {
        padding: 0;
        display: inline;
        *padding-right: 2px;
        border: 0;
    }

    .assessments-teaser-key .key-img img {
        position: relative;
        margin-top: -8px;
        *margin-top: 0;
        *padding: 2px;
    }

    .assessments-teaser-key .key-text {
        *vertical-align: middle;
        padding-bottom: 5px;
        *padding: 2px 2px 3px 2px;
        white-space: nowrap;
        font-weight: normal;
        display: inline;
        border-bottom: 0;
    }

    .assessment-help {
        display: none;
        text-align: left;
        margin: 0 0 9px;
        border: 1px solid @COLOR-TNL-GRAY5;
        background-color: #ffffe9;
    }

}