@import (reference) "_global.less";

.empari {

    .tnl-channel-content2 .courses .list-icon .tnl-learning-status-icon {
	    float: none;
	    margin: 2px 0 0 0;
    }

    .tnl-course-demographics ul {
	    list-style-type: none;
    }

    .tnl-sectionexpand {
        margin: 10px 0;
    }

    .row.tnl-sectionrow {
       border-bottom: 1px dashed #bbb;
       margin: 0 15px 10px 15px;
       padding-bottom: 6px;
    }

    .tnl-course-demographics ul,
    .tnl-course-demographics li {
        margin: 0;
        padding: 0;
    }

    .tnl-course-demographics ul ul {
        margin-left: 5px;
    }

    //NOTE: This is being used in a teaser and outside a teaser.
	//NOTE: Legacy Search is handled below.

    .tnl-pinned-course {
        color: #8ec641 !important;
        
        &:before {
            font-size: 25px;
        }
    }

    .tnl-not-pinned-course {
        color: #a4aaba !important;
        
        &:before {
            font-size: 25px;
        }
    }

    .tnl-1-5 {
        font-size: 1.5em !important;
    }

    .tnl-show-all-courses {
        color: #67696b !important;
    }


    .tnl-search-results,
    .tnl-search {

        .tnl-container-content,
        .tnl-container-content {
            .LESS-CONTAINER();
            margin-bottom: 10px;
            .LESS-ROUNDED-CORNERS();
            .LESS-BOX-SHADOW();
        }

        .tnl-container-article,
        .tnl-container-article {
            .LESS-BOX();
            margin: 0;
        }

    }

    .tnl-search .tnl-container-article {
        position: relative;
        padding: 10px;
    }

    .tnl-search .tnl-container-content .tnl-container-header {
	    margin-bottom: 5px;
    }
    
    .tnl-bottom-margin-20 {
        margin-bottom: 20px;
    }


    .tnl-search .tnl-container-header h1 {
	    margin: 0;
	    padding: 0;
	    color: @COLOR-TNL-GRAY2;
	    font-size: 20px;
	    font-weight: normal;
    }

    .tnl-search .sort-by select {
        margin-left: 5px;
    }

    .tnl-search-bar .tnl-search-links {
        margin-top: 5px;
    }

    .tnl-search-bar .tnl-search-links a {
        margin-right: 10px;
    }

    .tnl-search-bar .tnl-search-info {
        position: relative;
        top: 6px;
    }

    .tnlf-course-search-results-panel {
        padding-top: 0;
    }

    html.lt-ie9 .tnl-search-results .tnl-container-article,
    html.lt-ie9 .tnl-search .tnl-container-article {
        .LESS-BOX-IE-9;
    }

    html.lt-ie8 .tnl-search-results .tnl-container-article html.lt-ie8 .tnl-search .tnl-container-article {
        .LESS-BOX-IE-8;
    }

}

.tnl-teaser {
    .empari {
        .tnl-search {
            .tnl-page-header,
            .tnl-container-header {
                display: none;
            }
        }
    }
}