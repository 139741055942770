@import (reference) "_global.less";

.empari {

    .tnl-category-default-dark1 {
        background-color: #D0D8E8;
    }

    .tnl-category-default-light1 {
        background-color: #E9EDF4;
    }

    .tnl-category-default-dark2 {
        background-color: #EDC6C6;
    }

    .tnl-category-default-light2 {
        background-color: #F9ECEC;
    }

    .tnl-category-default-dark3 {
        background-color: #C3E3C3;
    }

    .tnl-category-default-light3 {
        background-color: #EBF5EB;
    }

    .tnl-category-default-dark4 {
        background-color: #F1EBF3;
    }

    .tnl-category-default-light4 {
        background-color: #FFF7E7;
    }

    .tnl-category-default-dark5 {
        background-color: #FFFFFF;
    }

    .tnl-category-default-light5 {
        background-color: #D6C3DB;
    }

    .tnl-category-default-dark6 {
        background-color: #FFFFFF;
    }

    .tnl-category-default-light6 {
        background-color: #FFFFFF;
    }

    .tnl-category-default-dark7 {
        background-color: #FFFFFF;
    }

    .tnl-category-default-light7 {
        background-color: #FFFFFF;
    }

    .tnl-category-default-dark8 {
        background-color: #FFFFFF;
    }

    .tnl-category-default-light8 {
        background-color: #FFFFFF;
    }

    .tnl-category-default-dark9 {
        background-color: #FFFFFF;
    }

    .tnl-category-default-light9 {
        background-color: #FFFFFF;
    }

    .tnl-category-default-dark10 {
        background-color: #FFFFFF;
    }

    .tnl-category-default-light10 {
        background-color: #FFFFFF;
    }
}