@import (reference) "_global.less";

.empari {

    .student-survey legend {
	    color: @COLOR-TNL-GRAY1;
	    margin-top: 2em;
	    border: 0;
    }

    .tnl-channel-content2 .survey .tnl-popup {
	    margin: 0 0 25px 0;
	    top: 25px;
	    left: 80px;
	    text-align: left;
    }

    .tnl-channel-content2 .survey .tnl-popup-tab-right {
        position: absolute;
        top: -16px;
        left: 375px;
        width: 37px;
        height: 17px;
        background: url(/images/tnl-ratings-tab.png) no-repeat;
    }

    .tnl-survey-person {
	    color: @COLOR-TNL-GRAY2;
	    font-size: 20px;
	    line-height: 28px;
    }

    .tnl-anonymous-accounts-form {
	    margin: 3em 0;
    }

    .tnl-survey-teaser-title {
        font-size: 14px;
    }

    .tnl-survey-teaser-desc {
        color: @COLOR-TNL-GRAY2;
    }

    .tnl-survey-teaser-links {
        width: 500px;
        float: right;
        text-align: right;
    }

    .tnl-survey-teaser-link {
        margin-left: 10px;
    }

    .tnl-survey-teaser-count {
        float: right;
        color: @COLOR-TNL-GRAY2;
    }

    .tnl-survey-teaser-item {
        margin-bottom:10px;
    }

    .tnl-survey-teaser-footer {
        padding: 0;
    }

    .tnl-hidden-surveys {
        display:none;
    }

    .tnl-survey-teaser-links .tnl-popup-main {
        text-align:left;
    }

    .tnl-survey-details img {
        float:right;
    }

    span.tnl-popup-tab-right {
        position: absolute;
        top: -16px;
        left: 385px;
        width: 37px;
        height: 17px;
        background: url(/images/tnl-ratings-tab.png) no-repeat;
    }

    .tnl-survey-account-list {
	    height: 185px;
	    overflow: auto;
    }

    .tnl-survey-account-list ul {
	    margin: 0;
	    padding: 0;
    }

    .tnl-survey-comment {
	    font-size: 12px;
    }

    .tnl-survey-header:not(:first-child) {
	    height: 100px;
    }

    .tnl-survey-category {
	    font-size: 12pt;
    }

    .tnl-survey-question {
	    font-size: 12pt;
    }

    .survey-success h1 {
	    color: @COLOR-SUCCESS;
	    font-size: 72pt;
	    font-weight: normal;
	    text-align: center;
    }

    .survey-success h2 {
	    color: @COLOR-TNL-GRAY2;
	    font-size: 22px;
	    font-weight: normal;
	    text-align: center;
    }

    .survey-success h3 {
	    color: @COLOR-TNL-GRAY2;
	    font-size: 14px;
	    font-weight: normal;
	    text-align: center;
    }

    .survey-success dl {
	    color: @COLOR-TNL-GRAY2;
	    font-size: 12pt;
    }

    .survey-success dl dt,
    .survey-success dl dd {
	    line-height: 200%;
    }

     td.tnl-anonymous-survey {
        vertical-align: top !important;
    }

    td.tnl-anonymous-survey .fa-plus {
	    margin-right: .75em;
    }

    td.tnl-anonymous-survey ul li,
    .tnl-anonymous-accounts-form ul li {
	    list-style: none;
    }
}