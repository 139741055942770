@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

// This file is specifically for renaming of the bootstrap .btn class to .boot-btn to avoid
// the overlap or conflict with it and the TNL .btn class. The classes in here are specifically button
// related and were copied from bootstrap.css and then .btn references were changed to .boot-btn.
// This file can be removed when the web essentials extension is functioning and
// the static css file can be generated from the bootstrap.css file automatically and make
// this adaptation on the fly.

.empari {

    .boot-btn {
	    display: inline-block;
	    margin-bottom: 0;
	    font-weight: normal;
	    text-align: center;
	    vertical-align: middle;
	    cursor: pointer;
	    border-width: 1px;
	    border-style: solid;
	    white-space: nowrap;
	    padding: 6px 12px;
	    font-size: 15px;
	    line-height: 1.3334;
	    border-radius: 2px;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
    }
    .boot-btn:focus,
    .boot-btn:active:focus,
    .boot-btn.active:focus {
	    outline: thin dotted;
	    outline: 5px auto -webkit-focus-ring-color;
	    outline-offset: -2px;
    }
    .boot-btn:hover,
    .boot-btn:focus {
	    color: #fff;
	    text-decoration: none;
    }
    .boot-btn.btn-primary span.tnlif.fa,
    .boot-btn.btn-primary:hover span,
    .boot-btn.btn-success span.tnlif.fa,
    .boot-btn.btn-success:hover span,
    .boot-btn.btn-danger span.tnlif.fa,
    .boot-btn.btn-danger:hover span,
    .boot-btn.btn-warning span.tnlif.fa,
    .boot-btn.btn-warning:hover span {
        color: #fff;
    }
    .boot-btn:active {
	    outline: 0;
	    background-image: none;
	    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	    background-image: none;
    }
    .boot-btn.disabled,
    .boot-btn[disabled],
	fieldset[disabled] .boot-btn {
	    cursor: not-allowed;
	    pointer-events: none;
	    .LESS-OPACITY(.65);
	    .LESS-BOX-SHADOW-RESET
    }
    .btn-group > .boot-btn,
    .btn-group-vertical > .boot-btn {
	    position: relative;
	    float: left;
    }
    .btn-group > .boot-btn:hover,
    .btn-group-vertical > .boot-btn:hover,
    .btn-group > .boot-btn:focus,
    .btn-group-vertical > .boot-btn:focus,
    .btn-group > .btn:active,
    .btn-group-vertical > .boot-btn:active,
    .btn-group > .boot-btn.active,
    .btn-group-vertical > .boot-btn.active {
	    z-index: 2;
    }
    .btn-group > .boot-btn:focus,
    .btn-group-vertical > .boot-btn:focus {
	    outline: 0;
    }
    .btn-group .boot-btn + .boot-btn,
    .btn-group .boot-btn + .btn-group,
    .btn-group .btn-group + .boot-btn,
    .btn-group .btn-group + .btn-group {
	    margin-left: -1px;
    }
    .btn-toolbar > .boot-btn,
    .btn-toolbar > .btn-group,
    .btn-toolbar > .input-group {
	    margin-left: 5px;
    }
    .btn-group > .boot-btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
	    border-radius: 0;
    }
    .btn-group > .boot-btn:first-child {
	    margin-left: 0;
    }
    .btn-group > .boot-btn:first-child:not(:last-child):not(.dropdown-toggle) {
	    border-bottom-right-radius: 0;
	    border-top-right-radius: 0;
    }
    .btn-group > .boot-btn:last-child:not(:first-child),
    .btn-group > .dropdown-toggle:not(:first-child) {
	    border-bottom-left-radius: 0;
	    border-top-left-radius: 0;
    }
    .btn-group > .btn-group:not(:first-child):not(:last-child) > .boot-btn {
	    border-radius: 0;
    }
    .btn-group > .btn-group:first-child > .boot-btn:last-child,
    .btn-group > .btn-group:first-child > .dropdown-toggle {
	    border-bottom-right-radius: 0;
	    border-top-right-radius: 0;
    }
    .btn-group > .btn-group:last-child > .boot-btn:first-child {
	    border-bottom-left-radius: 0;
	    border-top-left-radius: 0;
    }
    .btn-group > .boot-btn + .dropdown-toggle {
	    padding-left: 8px;
	    padding-right: 8px;
    }

    .btn .caret {
	    margin-left: 0;
    }
    .btn-group-vertical > .boot-btn,
    .btn-group-vertical > .btn-group,
    .btn-group-vertical > .btn-group > .boot-btn {
	    display: block;
	    float: none;
	    width: 100%;
	    max-width: 100%;
    }
    .btn-group-vertical > .btn-group > .boot-btn {
	    float: none;
    }
    .btn-group-vertical > .boot-btn + .boot-btn,
    .btn-group-vertical > .boot-btn + .btn-group,
    .btn-group-vertical > .btn-group + .boot-btn,
    .btn-group-vertical > .btn-group + .btn-group {
	    margin-top: -1px;
	    margin-left: 0;
    }
    .btn-group-vertical > .boot-btn:not(:first-child):not(:last-child) {
	    border-radius: 0;
    }
    .btn-group-vertical > .boot-btn:first-child:not(:last-child) {
	    border-top-right-radius: 2px;
	    border-bottom-right-radius: 0;
	    border-bottom-left-radius: 0;
    }
    .btn-group-vertical > .boot-btn:last-child:not(:first-child) {
	    border-bottom-left-radius: 2px;
	    border-top-right-radius: 0;
	    border-top-left-radius: 0;
    }
    .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .boot-btn {
	    border-radius: 0;
    }
    .btn-group-vertical > .btn-group:first-child:not(:last-child) > .boot-btn:last-child,
    .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
	    border-bottom-right-radius: 0;
	    border-bottom-left-radius: 0;
    }
    .btn-group-vertical > .btn-group:last-child:not(:first-child) > .boot-btn:first-child {
	    border-top-right-radius: 0;
	    border-top-left-radius: 0;
    }
    .btn-group-justified > .boot-btn,
    .btn-group-justified > .btn-group {
	    float: none;
	    display: table-cell;
	    width: 1%;
    }
    .btn-group-justified > .btn-group .boot-btn {
	    width: 100%;
    }
    [data-toggle="buttons"] > .boot-btn > input[type="radio"],
    [data-toggle="buttons"] > .boot-btn > input[type="checkbox"] {
	    position: absolute;
	    z-index: -1;
	    .LESS-OPACITY(0);
    }
    .input-group-lg > .form-control,
    .input-group-lg > .input-group-addon,
    .input-group-lg > .input-group-btn > .boot-btn {
	    height: 45px;
	    padding: 10px 15px;
	    font-size: 17px;
	    line-height: 1.33;
	    border-radius: 2px;
    }
    select.input-group-lg > .form-control,
    select.input-group-lg > .input-group-addon,
    select.input-group-lg > .input-group-btn > .boot-btn {
	    height: 45px;
	    line-height: 45px;
    }
    textarea.input-group-lg > .form-control,
    textarea.input-group-lg > .input-group-addon,
    textarea.input-group-lg > .input-group-btn > .boot-btn,
    select[multiple].input-group-lg > .form-control,
    select[multiple].input-group-lg > .input-group-addon,
    select[multiple].input-group-lg > .input-group-btn > .boot-btn {
	    height: auto;
    }
    .input-group-sm > .form-control,
    .input-group-sm > .input-group-addon,
    .input-group-sm > .input-group-btn > .boot-btn {
	    height: 31px;
	    padding: 5px 10px;
	    font-size: 13px;
	    line-height: 1.5;
	    border-radius: 2px;
    }
    select.input-group-sm > .form-control,
    select.input-group-sm > .input-group-addon,
    select.input-group-sm > .input-group-btn > .boot-btn {
	    height: 31px;
	    line-height: 31px;
    }
    textarea.input-group-sm > .form-control,
    textarea.input-group-sm > .input-group-addon,
    textarea.input-group-sm > .input-group-btn > .boot-btn,
    select[multiple].input-group-sm > .form-control,
    select[multiple].input-group-sm > .input-group-addon,
    select[multiple].input-group-sm > .input-group-btn > .boot-btn {
	    height: auto;
    }
    .input-group .form-control:first-child,
    .input-group-addon:first-child,
    .input-group-btn:first-child > .boot-btn,
    .input-group-btn:first-child > .btn-group > .boot-btn,
    .input-group-btn:first-child > .dropdown-toggle,
    .input-group-btn:last-child > .boot-btn:not(:last-child):not(.dropdown-toggle),
    .input-group-btn:last-child > .btn-group:not(:last-child) > .boot-btn {
	    border-bottom-right-radius: 4px;
	    border-top-right-radius: 4px;
    }
    .input-group .form-control:last-child,
    .input-group-addon:last-child,
    .input-group-btn:last-child > .boot-btn,
    .input-group-btn:last-child > .btn-group > .boot-btn,
    .input-group-btn:last-child > .dropdown-toggle,
    .input-group-btn:first-child > .boot-btn:not(:first-child),
    .input-group-btn:first-child > .btn-group:not(:first-child) > .boot-btn {
        .PM-BTN-RADIUS;
	    /*border-bottom-left-radius: 0;
	    border-top-left-radius: 0;*/
    }
    .input-group-btn > .boot-btn {
	    position: relative;
    }
    .input-group-btn > .boot-btn + .boot-btn {
	    margin-left: -1px;
    }
    .input-group-btn > .boot-btn:hover,
    .input-group-btn > .boot-btn:focus,
    .input-group-btn > .boot-btn:active {
	    z-index: 2;
    }
    .input-group-btn:first-child > .boot-btn,
    .input-group-btn:first-child > .btn-group {
	    margin-right: -1px;
    }
    .input-group-btn:last-child > .boot-btn,
    .input-group-btn:last-child > .btn-group {
	    margin-left: -1px;
    }
    .boot-btn .label {
	    position: relative;
	    top: -1px;
    }
    .boot-btn .badge {
	    position: relative;
	    top: -1px;
    }
    .modal-footer .boot-btn + .boot-btn {
	    margin-left: 5px;
	    margin-bottom: 0;
    }
    .modal-footer .btn-group .boot-btn + .boot-btn {
	    margin-left: -1px;
    }
    .carousel-caption .boot-btn {
	    text-shadow: none;
    }
    .boot-btn.btn-lg {
	    font-size: 17px;
	    line-height: 1.33;
    }
    .boot-btn.btn-sm {
	    font-size: @FONT-SIZE-DEFAULT;
	    line-height: 1.5;
    }
    .boot-btn.btn-xs {
	    font-size: 13px;
	    line-height: 1.5;
    }

}