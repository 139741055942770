@import (reference) "_global.less";
@import (reference) "../_pm-global.less";
// Use fore displaying label/value pairs.
// Should be replaced with Bootstrap forms

.empari {

    .tnl-display {
	    margin: 25px 0;
	    
	    input[type=checkbox] {
	       margin: 0;
	    }

        > .tnl-container-header {
	        margin: 0 0 15px 0;
            
            h1 {
                margin: 0 0 5px 0;
	            color: @COLOR-TNL-ORANGE1;
	            font-size: 18px;
	            font-weight: normal;
            } 
        }

        .tnl-label,
        .tnl-value {
	        display: inline-block;
	        .LESS-FIX-IE7-INLINE-BLOCK;
	        margin: 0 0 2px 0;
	        vertical-align: top;
	        line-height: 18px;
	        text-align: left;
        }

        .tnl-label {
	        width: 25%;
	        padding-right: 20px;
            .COS-OPEN-SANS-600();
	        text-align: left;
        }

        .tnl-value {
	        width: 70%;
        }

        .tnl-pair {
	        margin: 0;
	        padding: 0;
	        width: 100%;

            &:last-child {
	            margin-bottom: 0;
            }

            > div {
                position: relative;
                padding-top: 10px;
                padding-bottom: 10px;
	            vertical-align: top;
            }

            &.stacked .tnl-label, 
            &.stacked .tnl-value {
	            display: block;
	            text-align: left;
	            width: auto;
            }

        }

        .tnl-pair-top > div {
            vertical-align: top;
        }

        .tnl-container-header > .actions {
	        padding: 10px;
	        text-align: right;
        }

        .tnl-container-footer > .actions {
	        text-align: center;
        }

        .tnl-container-header > .actions .tnl-btn-text,
        .tnl-container-footer > .actions .tnl-btn-text {
	        margin: 0 10px;
        }

        .tnl-note {
            margin-left: 10px;
	        color: @COLOR-TNL-GRAY2;
	        font-size: 14px;
        }

        .tnl-value .tnl-item-list {
            display: inline-block;
        }

        .tnl-value .tnl-item-list li {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .tnl-value .actions {
            display: inline-block;
            position: absolute;
            right: 0;
            top: 11px;
            font-size: 13px;
            text-align: center;
        }

        .tnl-container-footer {
            padding: 15px;

            .actions {
                text-align: center;
            }

            .actions a {
                margin-right: 10px;
            }

            .actions a:last-child {
                margin-right: 0;
            }

        }
        
        textarea {
            resize: vertical;
        }

        .tnl-value .tnl-users {
            display: inline-block;
            margin-right: 100px;
            line-height: 25px;
        }

        // Overrides for tnl-pair display issues in GpFormAjaxUtil
        .tnl-grouped-row {
	        border-bottom: none;
        }
        .tnl-grouped-row .tnl-pair {
	        border-bottom: 0;
        }
        .tnl-grouped-row .tnl-pair:first-child {
	        border-top: 0;
        }
        .tnl-grouped-row:first-child {
	        border-top: none;
        }

    }

    .tnl-popup {
        .tnl-pair {

            > div {
                padding-top: 5px;
                padding-bottom: 5px;
            }

        }
    }

    .tnl-display-separator {
	    margin: 5px 0 15px;
	    border-bottom: 1px dashed @COLOR-TNL-GRAY3;
    }
    
    .tnl-popup-main {

        .tnl-display {
	        margin: 0;
        }

    } 

    @media screen and (max-width: @BOOTSTRAP-MD) {	
	
	    .tnl-display {
            .tnl-label,
	        .tnl-value {
		        display: block;
		        width: 95%;
		        text-align: left;
	        }
	        div.tnl-label {
		        margin-bottom: 0;
                padding-bottom: 0;
	        }
	        div.tnl-value {
                margin-top: 0;
                padding-top: 0;
            }
	        .tnl-display {
		        margin-top: 5px;
	        }
        }
	
    }
}