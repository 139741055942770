@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .tnl-opportunity {
        
        .tnl-opportunity-info {
            margin-bottom: 0;

            .tnl-opportunity-number {
                font-size: @COS-FONT-SIZE-22;
                padding-right: 5px;
            }

            .tnl-opportunity-title {
                font-size: @COS-FONT-SIZE-22;
                font-weight: normal;

                a {
                    display: inline;
                }
            }
        }
        
        .tnl-opportunity-icon {
            .tnlif.fa,
            .tnlif.im {
                font-size: 28px;
                height: auto;
                margin-top: 4px;
                width: auto;
            }
            :hover {
                cursor: help;
            }
        }

        .tnl-opportunity-extras {

            .tnl-opportunity-extra {
	            display: inline-block;
	            margin: 0 10px 10px 0;
	            white-space: nowrap;

                .tnl-icon-sm {
	                position: relative;
	                top: 3px;
                }
            }
            .tnl-opportunity-extras-group {
	            white-space: nowrap;
            }
        }

        .tnl-opportunity-description {
            color: @COLOR-TNL-GRAY2;
            font-size: @COS-FONT-SIZE-12;
            line-height: 18px;
            margin-bottom: 10px;
        }


        .tnl-resources  {
        
            > div  {
                border: 1px solid @COLOR-TNL-GRAY4;
                .PM-ROUNDED-CORNERS();
            }
            .tnl-resource-content {
                color: @COLOR-TNL-GRAY2;
                font-size: @COS-FONT-SIZE-12;
                min-height: 70px;
                text-align: center;

                .tnl-no-results {
	                display: block;
	                margin: 20px 40px 0;
	                text-align: center;
                }
                .tnl-resource-count {
                    display: block;
                    font-size: @COS-FONT-SIZE-10;
                    margin-bottom: 5px;
                    text-align: center;
                    .COS-OPEN-SANS-600();

                    a {
                        font-weight: normal;
                    }
                }
                .tnl-resource-item {
                    display: inline-block;
                    margin-bottom: 4px;
                    text-align: center;
                    .LESS-ELLIPSIS-ONE-LINE();
                    width: 90%;

                    a {
                        .LESS-ELLIPSIS-ONE-LINE();
                        width: 90%;
                    }
                    .tnl-popup-toggler {
                        position: relative;
                        top: -3px;
                    }
                }
                .tnl-resource-action {
                    display: block;
                    text-align: center;
                }
                .tnl-popup {
	                margin: 0;
	                right: 10px;
	                top: 65px;
                    text-align: left;
                }
            }

            &.tnl-resources-many {

                > div {
                    vertical-align: middle;
                }
                .tnl-resource-content {
                    display: inline-block;
                    margin: 10px 0;
                    vertical-align: middle;
                    width: 72%;
                }
                .tnl-resource-previous,
                .tnl-resource-next {
                    cursor: pointer;
                    display: inline-block;
                    margin: 10px 2%;
                    text-align: center;
                    vertical-align: middle;
                    width: 10%;

                    .tnlif.fa {
                        font-size: @COS-FONT-SIZE-24;
                    }
                    &:hover {
                        .tnlif.fa {
                            color: @COLOR-LINK-HOVER;
                        }
                    }
                }
                .tnl-resource-previous-inactive,
                .tnl-resource-next-inactive {
                    cursor: default;
                }
            }

            &.tnl-resources-one {
                .tnl-resource-content {
                    border: none;
                    margin: 10px 2%;
                    width: 96%;
                    .PM-ROUNDED-CORNERS();
                }
                .tnl-resource-previous,
                .tnl-resource-next {
                    display: none; //TODO: Remove from code not from CSS.
                }
            }

            &.tnl-resources-none {
                > div {
                    border: none;
                }
            }

            .tnl-icons-resources-prev,
            .tnl-icons-resources-next,
            .tnl-icons-resources-prev-inactive,
            .tnl-icons-resources-next-inactive {
                display: block;
                width: 12px;
                height: 12px;
            }
        }

        //TODO: What is this for?
        .tnl-icon-info-02-sm,
        .tnl-icon-compare-02-sm {
	        bottom: 8px;
	        position: absolute;
        }
        //TODO: What is this for?
        .tnl-icon-info-02-sm {
	        right: 10px;
        }
        //TODO: What is this for?
        .tnl-icon-compare-02-sm {
	        right: 34px;
        }

        .tnl-view-all-sections {
            display: block;

	        #Fonts > .SansSerif;
	        font-size: 14px;
	        white-space: nowrap;
        }

        .tnl-item-nav .view-section-details,
        .tnl-item-nav .view-resource {
	        display: inline;
	        margin-right: 20px;
	        line-height: 18px;
        }

        .tnl-item-nav span.view-section-id,
        .tnl-item-nav span.view-resource-id {
	        margin-right: 5px;
        }
    

        .tnl-item-nav-headline a {
	        font-size: 13px;
	        font-weight: normal;
        }

        .tnl-btn-text {
	        font-size: 13px;
	        padding-right: 5px;
	        padding-left: 5px;
        }

        &.tnl-opportunity-full-page {
            .tnl-opportunity-title,
            .tnl-opportunity-number {
                color: @COLOR-TNL-ORANGE1;
                font-size: @COS-FONT-SIZE-24;
                .COS-OPEN-SANS-REGULAR();
            }
            .tnl-content-box.tnl-opportunity,
            .item {
                border: none;
            }
            .tnl-opportunity-icon .tnlif.fa {
                height: auto;
                font-size: 40px;
                width: auto;
            }
        }
    }


    .tnl-course-search-results-panel {
	    margin: 25px 0 0;
    }

    .tnl-content-box {
        &.tnl-opportunity {
            border-bottom: 1px solid @COLOR-TNL-GRAY4;
            margin-bottom: 10px;

            .item {
                padding-bottom: 30px;
            }
        }
    }

    .tnl-content-box.tnl-opportunity .tnl-register {
	    position: absolute;
	    top: 4px;
	    right: 0;
    }
}