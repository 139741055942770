@import (reference) "_global.less";

.empari {

    .ui-widget.ui-autocomplete {
        #Fonts > .SansSerif;
        font-size: 15px;
    }

    .ui-widget.ui-autocomplete .ui-menu-item .ui-state-hover {
        color: #fff;
        background: @COLOR-TNL-ORANGE1;
        .LESS-ROUNDED-CORNERS-RESET;
        border: 1px solid #fff;
    }

}