@import (reference) "../../Theme/Common/_common.less";

/* */
// This file is reserved for common .LESS variables and mixins.

.LESS-BACKGROUND() {
    background: #fefefe;
    background-repeat: repeat-x;
    background-position: center bottom;
    background-image: url(/images/bg-channel-item.jpg);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#f3f3f2));
    background-image: -webkit-linear-gradient(top, #fff, #f3f3f2);
    background-image: -moz-linear-gradient(top, #fff, #f3f3f2);
    background-image: -ms-linear-gradient(top, #fff, #f3f3f2);
    background-image: -o-linear-gradient(top, #fff, #f3f3f2);
    background-image: linear-gradient(top, #fff, #f3f3f2);
}
.LESS-BACKGROUND-CUSTOM (@top: #fff, @bottom: #f2f2f2, @fallback: url() repeat-x bottom) {
    background: @fallback;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@top), to(@bottom));
    background-image: -webkit-linear-gradient(top, @top, @bottom);
    background-image: -moz-linear-gradient(top, @top, @bottom);
    background-image: -ms-linear-gradient(top, @top, @bottom);
    background-image: -o-linear-gradient(top, @top, @bottom);
    background-image: linear-gradient(top, @top, @bottom);
}
.LESS-BACKGROUND-MENU() {
    background-image: url(/images/menu-bg-fallback.png);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#ccc));
    background-image: -webkit-linear-gradient(top, #fff, #ccc);
    background-image: -moz-linear-gradient(top, #fff, #ccc);
    background-image: -ms-linear-gradient(top, #fff, #ccc);
    background-image: -o-linear-gradient(top, #fff, #ccc);
    background-image: linear-gradient(top, #fff, #ccc);
}
.LESS-BOX() {
    margin: 0 0 20px;
    padding: 15px 10px 10px;
    .LESS-BACKGROUND;
    border: 2px solid #fff;
    .LESS-BOX-SHADOW;
}
.LESS-BOX-SIZING() {
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
}
.LESS-BOX-IE-9() {
    border: 1px solid @COLOR-TNL-GRAY2;
}
.LESS-BOX-IE-8() {
    background-image: url(/images/bg-channel-item.jpg);
    border: 1px solid @COLOR-TNL-GRAY2;
    .LESS-BOX-SHADOW-RESET();
}
.LESS-CONTAINER() {
    padding: 5px;
    border: 2px solid white;
    background-image: url(/images/tnl-bg.jpg);
    background-image: url(/images/tnl-bg.png), -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#f2f2f2));
    background-image: url(/images/tnl-bg.png), -webkit-linear-gradient(top, #fff, #f2f2f2);
    background-image: url(/images/tnl-bg.png), -moz-linear-gradient(top, #fff, #f2f2f2);
    background-image: url(/images/tnl-bg.png), -ms-linear-gradient(top, #fff, #f2f2f2);
    background-image: url(/images/tnl-bg.png), -o-linear-gradient(top, #fff, #f2f2f2);
    background-image: url(/images/tnl-bg.png), linear-gradient(top, #fff, #f2f2f2);
}
.LESS-FIX-CONTAIN-FLOAT() {
    // Resolves an issue with IE7 not recognizing clear: both; as a means of containing floats within a container.
    zoom: 1;
}
.LESS-FIX-IE7-INLINE-BLOCK() {
    // Resolves an issue with IE7 not recognizing display: inline-block;
    zoom: 1;
    *display: inline;
}
.LESS-SHADOW-POPUP-MENU (@x: 0, @y: 0, @blur: 10px, @color: rgba(0,0,0,0.3)) {
    -moz-box-shadow: @arguments;
    -webkit-box-shadow: @arguments;
    box-shadow: @arguments;
}
.LESS-TEXT-MULTI-COLUMN (@count) {
    -webkit-column-count: @count;
    -moz-column-count: @count;
    column-count: @count;
}