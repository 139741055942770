@import (reference) "_global.less";

.empari {

    .tnl-users {

        > .tnl-user {
            margin: 1px;
            padding: 2px;
            color: @COLOR-TNL-GRAY2;
            white-space: nowrap;
        }

        > .tnl-user:hover  {
            margin: 0;
            .LESS-BACKGROUND();
            border: 1px solid @COLOR-TNL-GRAY3;
            .LESS-ROUNDED-CORNERS();
        }

        > .tnl-user:after {
            content: ";";
        }

        > .tnl-user:last-child:after {
            content: "";
        }

        > .tnl-user .address {
            margin-left: 5px;
            font-size: 13px;
        }

    }
 
}