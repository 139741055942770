@import (reference) "../Theme/Common/_common.less";
@import (reference) "_pm-global.less";

// This file should contain very little CSS. Only CSS that is global to the site and does not have a specific extacted file like, _form.less and _pill.less.

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: 'FontAwesome';
        src: url('../Icon/FontAwesome/fonts/fontawesome-webfont.svg?v=4.0.3#fontawesomeregular') format('svg');
    }
    @font-face {
        font-family: 'icon-moon';
        src: url('../Icon/IconMoon/fonts/icon-moon.svg#icon-moon') format('svg');
    }
    @font-face {
        font-family: 'tnl-custom-icons';
        src: url('../Icon/TnlCustomIconFonts/fonts/tnl-custom-icons.svg#tnl-custom-icons') format('svg');
    }
    @font-face {
        font-family: 'pm-font-icons';
        src: url('../Fonts/Font-Icons/PM-Custom-Icons-v2/Icons/pm-font-icons.svg') format('svg');
    }
}

 /* make keyframes that tell the start state and the end state of our object */
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-ms-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.non-bold {
    .COS-OPEN-SANS-REGULAR();
}

.semi-bold {
    .COS-OPEN-SANS-600();
}

// Messages
// These are the messages that appear for actions like "Field saved."
.messages {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: fixed;
    right: 0;
    top: 4px;
    text-align: center;
    z-index: 20000;

    li {
        background: @COLOR-TNL-GRAY6;
        border: 1px solid @COLOR-TNL-GRAY4;
        color: @COLOR-TNL-GRAY2;
        display: inline-block;
        font-size: @FONT-SIZE-XSM;
        list-style: none;
        margin: 0 0 4px 0;
        padding: 5px 15px;
        .PM-ROUNDED-CORNERS();
        .LESS-BOX-SHADOW();

        &:last-child {
            margin-bottom: 0;
        }
    }
    a.btn {
        cursor: pointer;
    }
}
.portal-ajax-loading {

}

//TODO: Refactor these tnl-states of ouf this file and document in the design guide.
.success,
.tnl-success,
.tnl-success a {
	color: @COLOR-SUCCESS;

    .icon-font.fa {
        color: @COLOR-SUCCESS;
    }
}

.tnl-alert-message {
    display: block;
    margin: 10px;
    padding: 0;
    color: #993333;
    font-size: 12px;
}

.tnl-alert,
.tnl-alert a {
	color: @COLOR-DANGER;
}
.tnl-warning,
.tnl-warning a {
	vertical-align: middle;
	color: @COLOR-WARNING;
}
.tnl-overdue {
   color: @COLOR-DANGER;
}
.tnl-registered,
.tnl-viewed {
    color: @COLOR-PM-GREEN-DARK;
    font-size: @FONT-SIZE-XSM;

    .tnlif.fa {
        color: @COLOR-COMPLETE;
        font-size: 13px;
    }
}
.tnl-warning {
    color: @COLOR-WARNING;
    font-size: @FONT-SIZE-XSM;

    .tnlif.fa {
        color: @COLOR-WARNING;
        font-size: 13px;
    }
}
.tnl-requested {
    color: @COLOR-WARNING;
    font-size: @FONT-SIZE-XSM;
}

.sticky {
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 1000;
}


.tnl-display-head {
    margin: 50px 0 0 0;
    padding: 25px 0 25px;
    color: @COLOR-TNL-ORANGE1;
    font-size: @COS-FONT-SIZE-DEFAULT;
    font-weight: normal;
}

.tnl-display-row {
    display: table-row;
}

.tnl-display-col {
    vertical-align: top;
    border-right: 1px solid @COLOR-TNL-GRAY4;
    border-bottom: 1px solid @COLOR-TNL-GRAY4;
    padding: 15px;
}

.tnl-display-col:last-child {
    border-right: none;
}

.tnl-section h1 {
    margin: 0;
    color: @COLOR-TNL-GRAY1;
    font-size: @COS-FONT-SIZE-30;
    font-weight: normal;
}

.tnl-choice-panel {
    padding: 10px 20px;
}

.tnl-choice-panel h2 {
    margin: 20px 0 5px;
    color: @COLOR-TNL-GRAY1;
    font-size: @COS-FONT-SIZE-24;
    font-weight: normal;
}

.tnl-choice-panel .btn {
    margin: 20px 0;
}

.tnl-choice-panel ul li {
    margin: 10px 0;
}

.step-content h3 {
    margin: 20px 0 5px;
    color: @COLOR-TNL-GRAY1;
    font-size: @COS-FONT-SIZE-24;
    font-weight: normal;
}


.tnl-summary .panel-title > a {
    font-size: 12px;
    font-weight: normal;
    margin: 4px 0 0 0;
}

.tnl-request-side-nav ol {
    margin: 5px 0 0 12px;
    padding: 0;
}

.tnl-request-side-nav ol li {
    margin: 8px 0;
}

.tnl-display-col-section {
    h2 {
        margin-bottom: 10px;
        color: @COLOR-TNL-GRAY2;
        font-size: @COS-FONT-SIZE-12;
        font-weight: bold;
    }
    .checkbox input[type=checkbox] {
        position: unset;
    }
}

.transaction-comments-wrapper
{
    .add-comment-btn-wrapper {
        margin: 0 0 10px 0;
        text-align: right;
    }

    .add-comment-inputs-wrapper {
        margin-bottom: 10px;
        display: none;
    }

    .add-comment-input {
        width: 100%;
        margin-right: -10px;
    }

    /* Includes IE7 scrollbar fix */
    .comment-list {
        position: relative;
        overflow-y: auto;
        max-height: 400px;
    }

    .comment-row {
        position: relative;
        list-style: none;
        border-top: 1px solid @COLOR-TNL-GRAY4;
        padding: .5em;
        text-align: left;
    }

    .comment-icons {
        position: absolute;
    }

    .comment {
        margin-left: 25px;
    }

    .comment-signature-line {
        display: block;
        text-align: right;
    }

    .comment-signature {
        color: @COLOR-TNL-GRAY3;
        font-size: @COS-FONT-SIZE-10;
        white-space: nowrap;
    }

    .comment-text {
        display: inline-block;
    }

    .comment-name {
        font-style: italic;
    }

    .comment-modified-indicator {
        display: inline-block;
        top: 0;
        position: absolute;
        right: -18px;
    }

    .comment-icon {
        position: relative;
    }
}


/* Transaction history */
.transaction-history-list {
    display: block;
    position: relative;
    overflow: auto;
    max-height: 400px;
}

.transaction-history-row {
    border-top: 1px solid @COLOR-TNL-GRAY4;
}

.transaction-history-description {
    vertical-align: top;
}

.transaction-history-actor,
.transaction-history-date {
    color: @COLOR-TNL-GRAY3;
    font-size: @COS-FONT-SIZE-10;
}

.transaction-history-date {
    display: block;
}

.transaction-history-filters th {
    font-size: @COS-FONT-SIZE-12;
    font-style: normal;
    font-weight: normal;
}

.readonly-text-area {
    margin: 0;
    font-size: @COS-FONT-SIZE-DEFAULT;
    color: @COLOR-TNL-GRAY1;
    background-color: transparent;
    max-height: 120px;
    padding: 0;
    display: block;
    overflow: auto;
}

ul.tnl-nested-tree-list-node > li > a.tnl-btn-icon-text {
    display: inline-block;
    margin: 5px 0;
}

.tnl-multi-answer-container {
    display: inline-block;
    padding: 2px 5px;
    margin: 2px;
    font-size: 13px;
    background-color: @COLOR-TNL-GRAY5;
    border: 1px solid @COLOR-TNL-GRAY4;
    .PM-ROUNDED-CORNERS();

    .icon-font{
        display: inline;
        margin-right: 3px;
    }
}

span.tnl-multi-answer-value {
    margin: 0 2px 0 0;
    padding: 0 4px 0 0;
    color: @COLOR-TNL-GRAY2;
    font-size: @COS-FONT-SIZE-12;
    white-space: nowrap;
    border-right: 1px solid @COLOR-TNL-GRAY4;
    cursor: text;
}
span.tnl-multi-answer-value:last-child {
    margin: 0;
    padding: 0;
    border: none;
}

.tnl-header-label {
    .COS-OPEN-SANS-600();
    font-size: @COS-FONT-SIZE-18;
    color: @COLOR-TNL-BLUE1;
    padding: 3px;
    white-space: nowrap;
}

.tnl-details-value {
    font-size: @COS-FONT-SIZE-12;
}

.tnl-details-label {
    text-align: right;
    white-space: nowrap;
    font-size: @COS-FONT-SIZE-12;
    font-weight: bold;
}

.tnl-details-panel {
    vertical-align: text-top;
    border-right: 1px solid @COLOR-TNL-GRAY4;
    background-color: @COLOR-TNL-GRAY6;
    width: 30%;
    & table > tbody > tr > td {
        padding: .5em;
    }
}

.help-dialog li {
    margin-left: 2em;
}

.help-dialog ul > li {
    list-style-type: disc;
}

.help-dialog ol > li {
    list-style-type: decimal;
}

.document-content ul > li {
    list-style: disc;
    list-style-position: inside;
}

.document-content ol > li {
    list-style: decimal;
    list-style-position: inside;
}

.document-content ul,
.document-content ol {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0.5em;
}

.document-content pre,
.document-content code {
    white-space: pre-wrap;
    /* IE7 doesnt support pre-wrap: we have to choose between losing the preformattedness
     * of this text and having the page blow up to gigantic sizes. Bryan thinks losing the
     * preformatting will be better, so here goes: */
    *white-space: normal;
}

.document-content {
    h1, h2, h3 {
        white-space: normal;
    }
}

.popup-container {
    max-width: 800px;
}

// tnl-popup-panel is a deprecated menu often used in table headers
.tnl-popup-panel {
    position: relative;
    display: inline-block; /*IE6-7 hacks*/
    zoom: 1;
    *display: inline;
    _height: 1em;
}

.tnl-popup-panel > a {
    display: inline-block;
}

.tnl-popup-panel > div {
    display: none;
    position: absolute;
    top: 0;
    left: 25px;
    z-index: 2;
}

.tnl-popup-panel.down > div {
    top: 25px;
    left: -1px;
}

.tnl-popup-panel.down > div.left {
    top: 25px;
    left: auto;
    right: 0;
}
th > .tnl-popup-panel > .btn {
    background: @COLOR-TNL-GRAY5 !important;
    border: 1px solid @COLOR-TNL-GRAY4 !important;

    &:hover {
        background-color: @COLOR-TNL-GRAY4 !important;
    }
}

/* Popup Widget */
.tnl-popup-widget {
    position: relative;
    display: inline-block;
    .LESS-DISPLAY-INLINE-BLOCK;
    z-index: 0;
}

.tnl-popup-widget.open {
    z-index: 10;
}

.tnl-popup-widget > a {
    .LESS-DISPLAY-INLINE-BLOCK;
}

.tnl-popup-widget > div {
    display: none;
    position: absolute;
    top: 0;
    left: 25px;
    z-index: 2;
    overflow: hidden;
    width: auto;
    min-width: 400px;
    *min-width: 500px; /* IE7 cant do the auto-width thing */
}

div.tnl-popup-widget > div > .ui-widget-header {
    cursor: default;
    text-align: left;
    padding: 3px 2px 2px 10px;
}

.tnl-popup-widget > div.left {
    left: auto;
    right: 25px;
}

.tnl-popup-widget > div.up {
    top: auto;
    bottom: 25px;
}

a.tnl-popup-widget-popout-button {
    float: right;
}

a.tnl-popup-widget-popout-button:hover {
    background-color: @COLOR-TNL-GRAY6;
}

.tnl-app-item {
    display: block;
}

.tnl-app-item > span,
.tnl-app-item > div {
    display: block;
    color: @COLOR-TNL-GRAY3;
    background: none;
}

.tnl-app-item > a:hover,
.tnl-app-item > * > a:hover {
    background: @COLOR-TNL-GRAY3;
}

.ui-state-default .tnl-app-item > a, .ui-state-default .tnl-app-item > * > a {
    font-weight: normal;
}

.tnl-app-item > .tnl-popup-panel {
    padding: 0 0 0 0;
}

.tnl-app-item > .tnl-popup-panel.down > div {
    top: 22px;
    background: @COLOR-TNL-GRAY3;
    border-color: #000;
    border-top-style: none;
}

.tnl-page-heading-div {
    margin: 0;
    padding: 20px 20px 5px 20px;
    border-bottom: 1px solid @COLOR-TNL-GRAY4;
}

.pdconnect-sort {
    cursor: default;
}

.tnl-sort.ui-state-active {
    background: @COLOR-TNL-GRAY6;
    border: none;
}

.tnl-clickable {
    text-align: center;
    cursor: pointer;
}

.tnl-clickable-hover {
    cursor: pointer;
}

/* links with both icons and text need to have some space between the two.*/
.tnl-text > .icon-font{
    margin-right: 5px;
}



.tnl-progress-bar-txt {
    padding: 1px 0;
    vertical-align: middle;
    position: absolute;
    text-align: center;
    font-size: @COS-FONT-SIZE-12;
}

.tnl-progress-bar {
    height: 18px;
}


/* MVC Permissions Page */
.tnl-type-table {
    border-width: 0 dotted @COLOR-DANGER-LT;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;

    td {
        padding: 0;
    }

    th {
        text-align: center;
        padding: .5em;
        font-weight: normal;
    }
}

.tnl-tr-option {
    color: @COLOR-TNL-BLUE1;
    background: #fff;
    cursor: pointer;
}

.tnl-tr-option-hot {
    background: @COLOR-TNL-GRAY3;
    color: #fff;
}

.tnl-tr-option-hover {
    background: @COLOR-TNL-GRAY6;
    cursor: pointer;
}

.tnl-role-option {
    color: @COLOR-TNL-BLUE1;
    background: #fff;
    cursor: pointer;
}

.tnl-role-option-hot {
    background: @COLOR-TNL-GRAY3 !important;
    color: #fff;
}

.tnl-role-option-hover {
    background: @COLOR-TNL-GRAY6;
    cursor: pointer;
}

.tnl-nested-tree-list-node > li {
    margin: 0 0 5px 10px;
}

.tnl-nested-tree-list-header {
    font-weight: bold;
    margin-bottom: 5px;
}

.nested-list-unstyled{
    list-style-type: none;
}

.tnl-section-heading {
    padding: 5px;
    font-size: @COS-FONT-SIZE-22;
    border: 1px solid @COLOR-TNL-GRAY3;
    background: @COLOR-TNL-GRAY6;
    margin-right: -1px;
    margin-left: -1px;
    border-top: 0;
}

.tnl-rbac-op-icon {
    white-space: nowrap;
}

.tnl-tile {
    display:inline-block;
    vertical-align: top;
    .LESS-BOX;
    padding: 10px;
    width: 250px;
}
.tnl-tile > header {
    position:relative;
}
.tnl-tile > header > aside {
    margin:0 10px 0 0;
}
.tnl-tile > header > h1 {
    margin-right: 50px;
    font-size: @COS-FONT-SIZE-12;
}
.tnl-tile > header > h2 {
    margin-right: 50px;
    font-size:13px;
    font-weight: normal;
}
.tnl-tile > dl{
    font-size: @COS-FONT-SIZE-10;
}
.tnl-tile > dl > dt{
    color: @COLOR-TNL-GRAY4;
    float:left;
    text-align: right;
    width: 80px;
    line-height: normal;
    margin-top: 4px;
}
.tnl-tile > dl > dd{
    margin-left: 85px;
    line-height: normal;
    margin-top: 4px;
}


.text-disabled,
.text-disabled:hover {
    color: @COLOR-TNL-GRAY3;
}
.tnl-toggle {

    .btn {
        padding-right: 10px;
        padding-left: 10px;
    }
}

// Clearfix
.tnl-tile > dl > dd:after {
   content: " "; /* Older browser do not support empty content */
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}


/* Upload progress styles */
div.upload-progress {
    display: none;
}

div.upload-progress-meter {
    position: relative;
}
/* Brent:It would be nice to find a color for this that shows up well against both the standard background (white) and the
   progress bar color for each theme. Also, this line-height needs to follow the height of the progress bar based on the theme.*/
span.upload-progress-meter-percent {
    position: absolute;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    line-height: 30px;
    cursor: default;
}

span.upload-progress-byte-count {
    float: left;
}

span.upload-progress-timer {
    float: right;
}

/*Make the file upload input invisible, so that clicking the "Upload" button will cause the file selector to open.
  These styles are pulled from http://aquantum-demo.appspot.com/file-upload and appear to be roughly consistent with
  http://valums.com/ajax-upload/ */

.upload-form {
    .form-submit{
        display: none;

    }
    .upload-button {
        overflow: hidden;
        position: relative;

        & > input {
            position: absolute;
            top: 0;
            right: 0;
            float: left;
            border: 0 solid transparent;
            opacity: 0;
            filter: alpha(opacity= 0);
            -o-transform: translate(250px,-50px) scale(1);
            -moz-transform: translate(-300px,0) scale(4);
            -ms-transform: translate(-300px, 0) scale(4);
            -webkit-transform: translate(-300px, 0) scale(4);
            transform: translate(-300px, 0) scale(4);
            direction: ltr;
            cursor: pointer;
            margin: 0;
        }
        .icon-font {
            margin-right: 5px;
        }
    }
}

#tnl-nav-pane {
    padding: 10px 5px 10px 10px;
    vertical-align: top;
}

#tnl-main-pane {
    padding: 10px 10px 10px 5px;
    vertical-align: top;
    width: 100%;
}


/* Login */
.tnl-login-box {
    padding: 100px 0 0 0;
}


/* Positions */
.tnl-position
{
    padding-right:15px;
}

.tnl-position > h2, .tnl-position > h3  {
    margin-bottom: .5em;
    padding: 0;
    color: black;
}

.tnl-position > div {
    padding-bottom: 10px;
}

.tnl-position .tnl-career-path-panel{
    margin-top:20px;
}

.tnl-position-code > label, .tnl-position-description > label {
    font-weight: bold;
}

.tnl-no-results {
    color: @COLOR-TNL-GRAY3;
    font-size: @COS-FONT-SIZE-10;
    text-align: center;
}

.file-set li, .file-set > a,  .file-set > a.ui-state-hover {
    margin-bottom: 5px;
}

/* Display Tab Usage */
.tnl-display-tabs {
    width: 200px;
    float: left;
    margin-right: 10px;
    margin-top: 15px;
}

.tnl-display-tab {
    margin-bottom: 7px;
}

.tnl-display-tab-selected  {
    color: @COLOR-TNL-GRAY3;
    font-weight: bold;
    cursor:default;
}

.tnl-display-tab-selected:hover  {
   text-decoration:none;
   color: @COLOR-TNL-GRAY3;
}

/*Grid Actions*/
.tnl-grid-column-actions > span.ui-state-active {
    font-size: @COS-FONT-SIZE-10;
}

div.tnl-grid-bulk-menu {
    white-space: nowrap;
}

div.tnl-grid-bulk-checkbox {
    display: inline;
}


/* Permission Manager */
.tnl-permissions-grid {
    margin: 0;
    padding: 0;
    width: 100%;
}
.tnl-permissions-grid > tbody > tr > td {
    margin: 0;
    padding: 0;
    vertical-align: top;
    border: 1px solid @COLOR-TNL-GRAY3;
}
.tnl-permissions-grid > tbody > tr > td > div > ul {
    margin: 5px;
}
.tnl-permissions-grid > tbody > tr > td > div > ul > li {
    margin-bottom: 20px;
}
.tnl-permissions-global-operations table > thead > tr > th {
    padding-right: 15px;
}

/* Sort */
.tnl-sort {
    color: @COLOR-TNL-GRAY3;
    font-size: @COS-FONT-SIZE-12;
    overflow: hidden;
}


.tnl-layout-with-nav {
    overflow: hidden;
}

.tnl-nav-selected {
    font-weight: bold;
}

.tnl-layout-with-nav > nav {
    float: left;
    width: 20%;
}
.tnl-layout-with-nav > section {
    float: left;
    width: 80%;
}

.tnl-help-text-icon {
    cursor: help;
}

/*
    Career Teaser
*/

.tnl-career-teaser .tnl-list-simple li {
    position: relative;
}

.tnl-career-teaser .tnl-list-simple li a {
    position: relative;
    left: 20px;
    margin-right: 22px;
}
.tnl-career-teaser .icon-font {
    cursor: pointer;
}


.separator {
    display: block;
    clear: both;
    height: 1px;
    padding-top: 10px;
    border-bottom: 1px solid @COLOR-TNL-GRAY4;
}

/*
    Bootstrap description
*/
// TODO: Look into these styles.
.tnl-description-1 dt,
.tnl-description-2 dt {
    color: @COLOR-TNL-GRAY3;
    font-size: @COS-FONT-SIZE-10;
    .COS-OPEN-SANS-600();
}
.tnl-description-1 dd,
.tnl-description-2 dd {
    margin-bottom: 10px;
    font-size: @COS-FONT-SIZE-12;
}
.tnl-description-1 dd {
    margin-left: 0;
}

.row-padded {
    margin-bottom: 3em;
}

.tnl-sort-grid {
    > div.row:nth-child(even) {
        background-color: @COLOR-TNL-GRAY6;
    }

    > div.row > div {
        padding-top: 5px;
    }
}

.sort-button {
    padding: 0 5px;
}

.sort-button-pushed {
    padding-left: 18px;
}

.tnl-manage-events-mapping-segment{
    margin: 20px;
}

.tnl-rule-mapping{
    margin-top: 15px;
}

.border-right-sm {
	border-right: 1px solid #ccc;

    @media (max-width:767px){
	    border-right: none;
    }
}

.container {
    padding: 1em 0;
}
/*
    Tab pane adjustments
*/

.tab-content > .tab-pane {
    margin: 10px;
}

/* This is a class that we intend to wrap around pages that we want to give our "new"
    (as of July 2014) bootstrap look. That way, we can define new styles for those pages
    that make their look internally consistent, without adversely affecting other pages
    that were not built with these new styles in mind. These rules will sometimes
    directly contradict other rules for the same selectors.
    */
.tnl-bs-ui {
    h2,
    .tnl-display-col-section h2 {
        font-size: 24px;
        font-weight: normal;
        margin: 1em 0 .5em 0;
        color: #000;
    }
}

.arrow {
  visibility: hidden;
}

.tnl-design-doc .alert-danger .fa {
  float: left;
  margin: 5px 5px 0 0;
}
.disabled-with-tooltip {
  pointer-events: all !important;
}

// Super Modal Stuff
// TODO: BJJ - Modal stuff should be put together.
.pm-modal-xl {
    width: 80% !important;
}
.pm-super-modal {
    width: 98% !important;
    margin-top: -16px !important;
}
.pm-super-modal .modal-title {
    font-size: 22px !important;
    color: #fff;
}
.pm-super-modal .modal-header {
    border: 0 !important;
    height: 58px !important;
    background-color :#2A333D;
}

.pm-super-modal .modal-body {
    padding: 0 !important;
}
.pm-super-modal .modal-content {
    border: 0 !important;
    border-radius: 0 !important;
}
.pm-super-modal .modal-footer {
    display: none;
}

.pm-form-label {
    padding: 6px;
    font-weight: bold;
    max-width: 220px;
}
.pm-form-label-value-divider {
    padding: 6px;
    max-width: 50px;
}
.pm-form-value {
    padding: 6px;
}
.pm-form-row {
    margin-bottom: 6px;
}

@media (max-width: 767px) {
   .tnl-cart-header {
       bottom: 10px;
   }
}
