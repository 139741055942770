@import (reference) "_global.less";

.empari {

    .tnl-callout-box { 
        padding: 1.5em;
        line-height: 15px;
        .LESS-ROUNDED-CORNERS();

	    h1 {
	        font-size: 32px;
	        padding: 0;
	    }

	    h2 {
	        font-size: 24px;
	        padding: 0;
	    }

	    h3 {
	        font-size: 20px;
	        padding: 0;
	    }

	    h4 {
	        font-size: 18px;
	        padding: 0;
	    }

	    h5 {
	        font-size: 16px;
	        padding: 0;
	    }

	    h1, h2, h3 {
            font-weight: normal;
	    }

	    h1, h2, h3, h4, h5 {
            margin: 0 0 5px 0;
	    }

	    h1, h2, h3, h4, h5, p, ul, ol, dl {
	        &:last-child {
	            margin: 0;
	        }
	    }

	    &.default {
	       color: @COLOR-TNL-GRAY1;
	       background: @COLOR-TNL-GRAY5;
	    }

    }

}