@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .tnl-message {
        padding: 20px;

        .tnl-portal-article {
            margin: 0;
        }

        > .tnl-container-header {
            margin: 10px 0;
            padding: 10px 10px 0;
            border-bottom: 1px solid #bbb;
        }

        > .tnl-container-header .actions {
            float: right;
        }

        > .tnl-container-header .actions span {
            margin: 0 0 0 5px;
        }

        > .tnl-message-body .actions span:first-child {
            margin-left: 0;
        }

        > .tnl-message-body .tnl-container-content {
            padding: 15px 10px;
            border-bottom: 1px solid #bbb;
        }

        > .tnl-message-body .tnl-container-header h1 {
            margin: 0;
            color: @COLOR-TNL-GRAY2;
            font-size: 16px;
            .COS-OPEN-SANS-600();
        }

        > .tnl-message-body .tnl-container-header div.date,
        > .tnl-message-body .tnl-container-header div.from {
            color: @COLOR-TNL-GRAY3;
            font-size: 14px;
        }

        > .tnl-message-body .tnl-container-header div.from {
            display: inline-block;
            float: left;
        }

        > .tnl-message-body .tnl-container-header div.date {
            display: inline-block;
            float: right;
        }

    }

    .tnl-message-warning,
    .tnl-message-success {
          margin-bottom: 20px;
          padding: 20px;
          text-align: center;
          .PM-ROUNDED-CORNERS();
          .LESS-BOX-SHADOW;
    }

    .tnl-message-warning {
          color: @COLOR-WARNING;
          .LESS-GRADIENT-BG (#fff, @COLOR-WARNING-LT, @COLOR-WARNING-LT);
          border: 1px solid @COLOR-WARNING;
    }

    .tnl-message-success {
          color: @COLOR-SUCCESS;
          .LESS-GRADIENT-BG (#fff, #d9eddd, #d9eddd);
          border: 1px solid @COLOR-SUCCESS;
    }

}