@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .tnl-notes {

        > .tnl-container-header h1 {
            margin: 0;
            color: @COLOR-TNL-GRAY2;
            font-size: 20px;
            font-weight: normal;
        }

        > .tnl-container-article {
            padding: 10px 10px 0 10px;
            border-bottom: 1px solid @COLOR-TNL-GRAY4;

            > .tnl-container-header .actions {
                float: right;
            }

            > .tnl-container-header .actions span {
                margin: 0 0 0 5px;
            }

            > .tnl-container-header h1 {
                margin: 0;
                color: @COLOR-TNL-GRAY2;
                font-size: 16px;
                .COS-OPEN-SANS-600();
            }

            > .tnl-container-header .date,
            > .tnl-container-header .from,
            > .tnl-container-header .tags,
            > .tnl-container-header .description {
                color: @COLOR-TNL-GRAY3;
                font-size: 14px;
                line-height: 16px;
            }

            > .tnl-container-header .from {
                display: inline-block;
                float: left;
            }

            > .tnl-container-header .date {
                display: inline-block;
                float: right;
            }

             > .tnl-container-header .tags {
                clear: both;
                margin: 0;
                padding: 0;
                list-style-type: none;
            }

            > .tnl-container-header .tags li {
                display: inline-block;
                margin-right: 5px;
            }

            > .tnl-container-header .tags li:last-child {
                margin-right: 0;
            }

            > .tnl-container-content {
                margin: 10px 0;
            }

            > .tnl-container-content p,
            > .tnl-container-content ul,
            > .tnl-container-content span,
            > .tnl-container-content div {
                margin: 0 0 5px;
                color: #000;
                font-size: 15px;
            }
        }
    }
}