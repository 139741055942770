@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

/* */
// Namespace deprecated empari CSS so it does not affect non-Empari pages.
.empari {

    // Bootstrap Overrides
    * {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }
    .boot-btn, .bnt-group,
    .container, .container .row, .container .row > div,
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1,
    .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2,
    .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3,
    .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4,
    .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5,
    .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6,
    .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7,
    .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8,
    .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9,
    .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10,
    .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11,
    .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12,
    .form-control {
        .LESS-BOX-SIZING;
    }

    // HTML
    input[type="date"], 
    input[type="time"], 
    input[type="datetime-local"], 
    input[type="month"] {
        font-size: @FONT-SIZE-DEFAULT;
        height: 33px;
        line-height: 1.4666;
        padding: 0 5px;
    }

    // Prevent drop down menus from wrapping
    .tnl-button-menu.btn-group {
        white-space: nowrap;

        button {
            &:active, &:focus {
                .tnl-icon-wrapper {
                    margin-left: -2px;
                }
            }
        }
    }
    .tnl-button-menu.btn-group span.tnlif.fa,
    .btn-group.tnl-button-menu span.tnlif.fa {
        width: auto;
        height: auto;
        color: @COLOR-TNL-GRAY2;
        font-size: @FONT-SIZE-DEFAULT;
        line-height: 1.3334;
    }
    .tnl-button-menu.btn-group span.tnlif.im,
    .btn-group.tnl-button-menu span.tnlif.im {
        width: auto;
        height: auto;
        color: @COLOR-TNL-GRAY2;
        font-size: @FONT-SIZE-SM;
        line-height: 1.3334;
    }
    .tnl-button-menu.btn-group > .boot-btn:hover,
    .tnl-button-menu.btn-group > .boot-btn:focus,
    .tnl-button-menu.btn-group > .boot-btn:active,
    .tnl-button-menu.btn-group.open > .dropdown-toggle.btn-default {
        color: #67696b;
        background-color: @COLOR-TNL-GRAY6;
        border-color: transparent;
        border-width: 1px;
    }

    .tnl-button-menu.btn-group {
        button.boot-btn,
        button.boot-btn:active {
            font-size: 17px;        
            background: @COLOR-PM-WHITE1;
            position: relative;
            left: -2px;
            margin-right: -2px;
            margin-left: -2px;
            min-width: 30px !important;
            border-right: none !important;
            border-radius: 0 3px 3px 0;
            overflow: hidden;

            .tnl-icon-wrapper {
                padding: 0 2px;
            }

            &:hover {
                background-color: @COLOR-PM-GREY3;
            }
        }
    }

    .tnl-button-menu.btn-group > .btn-link.boot-btn:hover,
    .tnl-button-menu.btn-group > .btn-link.boot-btn:focus,
    .tnl-button-menu.btn-group > .btn-link.boot-btn:active,
    .tnl-button-menu.btn-group.open > .dropdown-toggle.btn-link {
      background: none;
      border-color: transparent;
    }

    // Address jQueryUi icons in drop down menu
    .tnl-button-menu.btn-group .ui-icon {
        position: static; // Fix position of jQueryUI icon
        margin: 2px 0; // Compensates for lack of line-height recognition on images
    }

    .btn-group a span:nth-child(2) {
        padding-left: 5px;
    }
    .btn-group .dropdown-menu a span:only-child {
        padding-left: 16px;
    }

    // Container
    .container {
        width: 100%;
    }

     //
    .CodeMirror pre {
        overflow: visible;
    }
    .tnl-opportunity h3:first-child {
        margin-top: 0;
    }

    .modal-backdrop {
        background-color: @COLOR-TNL-GRAY1;
    }

    .caret-up {
        border-bottom: 4px solid;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        display: inline-block;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        width: 0;
    }
}