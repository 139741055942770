@import (reference) "_global.less";
@import (reference) "../_pm-global.less";

.empari {

    .tnl-grid-table,
    table.tnl-grid-table {
        border-collapse:collapse;
        border: 1px solid @COLOR-TNL-GRAY4;
        color: #000;
        font-size: 14px;
        width: 100%;

        > thead > tr > th {
            background: none repeat scroll 0 0 @COLOR-TNL-GRAY4;
            border: 1px solid @COLOR-TNL-GRAY1;
            color: #fff;
            height: 27px;
            padding: 0;
        }

        > thead > tr > th > table {
            margin: 0;
            padding: 0;
            width: 100%;
        }

        > tbody > tr {
            height: 30px;
        }

        > tbody > tr > td > div.readonly-text-area {
            max-height: 40px;
            padding: 0;
        }

        > tbody > tr > td {
            border-right: 1px dashed @COLOR-TNL-GRAY4;
            padding: 0 4px;
        }

        > tfoot {
            background: none repeat scroll 0 0 @COLOR-TNL-GRAY4;
            border: 1px solid @COLOR-TNL-GRAY1;
        }

        .no-column-lines tbody > tr > td {
	        border-right: none;
        }

        .header-column-lines tbody > tr > th {
	        border: 0;
	        padding: 5px 3px;
            color: #fff;
            font-size: 14px;
            text-align: center;
            vertical-align: bottom;
            background: @COLOR-TNL-GRAY3;
            border-right: 1px dashed @COLOR-TNL-GRAY4;
            .COS-OPEN-SANS-600();
        }
    }

    .tnl-border-table,
    table.tnl-border-table {
        border-collapse:collapse !important;
        border: 1px solid @COLOR-TNL-GRAY4 !important;
        > thead > tr > th {
            border: 1px solid @COLOR-TNL-GRAY1 !important;
        }
        > tbody > tr > td {
            border: 1px solid @COLOR-TNL-GRAY1 !important;
        }

    }

    div.tnl-grid-menu {
        background: none repeat scroll 0 0 #fff;
        padding: 5px 5px 8px;
        text-align: left;
        font-weight: normal;

        .tnl-icon-text-link {
            padding: 2px 8px 2px 22px;
        }

        > div > ul {
            padding :0 8px;
            margin: 0;
        }

        > div > ul > li {
            background:none repeat scroll 0 0 @COLOR-TNL-GRAY6;
            border-color: @COLOR-TNL-GRAY4;
            border-style: none none solid;
            border-width: 1px;
            padding: 4px;
            margin: 0;
            list-style: none;
        }

        > div > ul > li > .tnl-icon-text-link {
            color: inherit;
            border: 0 none;
            display: block;
            width: auto;
        }

        > div > div {
            padding:4px;
        }

        > div > div > .filter {
            float:right;
        }
    }

    div.tnl-grid-menu-section {
        margin-bottom: 8px;
        white-space: nowrap;
    }

    .tnl-grid-num-results {
	    font-size: 13px;
	    color: @COLOR-TNL-GRAY2;
    }

    .tnl-grid-num-results b {
	    color: @COLOR-TNL-GRAY4;
    }

    .tnl-grid-result-number {
        background: @COLOR-TNL-GRAY3;
        border-radius: 10px;
	    color: #fff;
	    padding: 0 5px;
    }
    .tnl-table caption {
	    text-align: left;
	    padding-bottom: 10px;
    }

    .tnl-table-none-found {
        text-align: center;
        background: #fff;
        padding: 25px;
    }

    table.clear .btn,
    table.clear div.btnoff {
        -moz-border-radius: 0;
        font-size: 13px;
        padding: .2em .5em;
    }

    table.list {
        background-color: @COLOR-TNL-GRAY4;
    }

    table tr.rowcolor1,
    .rowcolor1 {
        background-color: #fff;
    }

    table tr.rowcolor2,
    .rowcolor2 {
        background-color: @COLOR-TNL-GRAY6;
    }

    td.table-title {
        background: @COLOR-TNL-GRAY3;
        font-size: 14px;
        padding: 5px;
        .COS-OPEN-SANS-600();
    }

    td.subtitle {
       background: #fff;
       padding:15px;
       padding-top:24px;
       font-size:18px;
       font-family: 'Montserrat', sans-serif;
    }

    th.heading {
        background-color: @COLOR-TNL-GRAY2;
        color: #fff;
        #Fonts > .SansSerif;
        font-size: 12px;
    }

    th.sub-title {
        background: @COLOR-TNL-GRAY4;
        font-size: 16px;
        padding: 5px;
        text-align: left;
        .COS-OPEN-SANS-600();
    }
    table.sub-table {
        border-color: #000;
        border-spacing: 1px;
        border-width: 0;
        width: 100%;
    }

    table.sub-table th {
        background-color: @COLOR-TNL-GRAY1;
        color: #fff;
        font-size: 12px;
        .COS-OPEN-SANS-600();
    }

    table.sub-table td {
        padding: 3px;
    }

    .table-display {
        background: #fff;
        border: 0;
        border-collapse: collapse;
        width: 100%;
        margin-top: 10px;

        table {
            border-collapse: collapse;
        }

        th {
            padding: 10px;
            text-align: right;
            vertical-align: top;
        }

        td {
            padding: 10px 10px 10px 0;
            vertical-align: top;
        }

    }

    td.display-heading {
        background: #fbfbfb !important;
        border-top: 5px solid #4b8ccf;
        border-bottom: 2px solid #e7e9ed;
        color:#36474f;
        padding:15px 20px;
        font-size:18px;
    }

    .tnl-table-nav-pills {
	    margin: 15px 0;
	    padding: 5px;
	    background: #fff;
	    .LESS-BOX-SHADOW-INSET();
	    border: 1px solid @COLOR-TNL-GRAY4;
        .PM-ROUNDED-CORNERS();
    }

    .tnl-table-toggleable {
	    margin: 10px 0;
    }

    #rosterTable_edit_buttons .btn,
    #rosterTable_buttons .btn,
    #rosterTable_save_buttons .btn {
        margin: 0 5px 5px 0;
    }

    .tnl-table-container {
	    padding-bottom: 0;
        color: @COLOR-TNL-GRAY2;

        > .tnl-container-header h1 {
            margin: 0;
            padding: 0;
            font-size: 16px;
            .COS-OPEN-SANS-600();
        }

        > .tnl-container-header .description {
            margin: 0;
            padding: 0;
        }

    }

    .tnl-table,
    .tnl-table thead,
    .tnl-table > tbody,
    .tnl-table tr,
    .tnl-table th,
    .tnl-table td,
    .tnl-table-header,
    .tnl-table-footer {
        margin: 0;
        padding: 0;
        border: none;
    }

    .tnl-table {
	    clear: both;
        width: 100%;
        margin-bottom: 10px;
        line-height: 18px;
        font-size: 14px;
        border-spacing: 0;

        thead {
            tr {
                &:first-child {
                    background-color: #36474f;
                }
            }
        }

        th,
        .tnl-table-side-th {
	        margin-bottom: 2px;
            padding: 15px 10px;
            vertical-align: middle;
            background: none;
            color: @COLOR-PM-WHITE1;
            font-size: @COS-FONT-SIZE-DEFAULT;
            .COS-OPEN-SANS-REGULAR();

            label {
                color: inherit;
            }
        }

        th {
            background-color: #36474f !important;

            h1, h2 {
                color: #FFF !important;
            }
        }

        th a,
        th a:visited,
        th a:active,
        .tnl-table-side-th a,
        .tnl-table-side-th th a:visited,
        .tnl-table-side-th th a:active {
            color: @COLOR-TNL-GRAY2;
        }

        th span,
        .tnl-table-side-th span {
	        display: inline-block;
            vertical-align: middle;
            float: none; // In IE7, the icon is vertically aligned incorrectly when floated right. Looks fine not floated. sstanley
            color: #fff;
        }

        .tnl-table-side-th {
	        text-align: left;
        }

        .tnl-head-2 {
	        text-align: left;
	        color: @COLOR-TNL-GRAY2;
	        background: @COLOR-TNL-GRAY5;
	        border-right: none;
        }

        .tnl-sort-col {
            .fa {
                position: relative;
                color: @COLOR-PM-GREEN1;
            }

            .fa-sort-down:before,
            .fa-sort-desc:before {
                position: absolute !important;
                top: -4px !important;
            }

            .fa-sort-up:before,
            .fa-sort-asc:before {
                position: absolute !important;
                top: 3px !important;
            }
        }

        th.tnl-sort-col:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        > tbody,
        tr.rowcolor1 {
            background: #fff;
        }

        tr.rowcolor2 {
            background: @COLOR-PM-GREY3;
        }

        > tbody > tr > td,
        > tr > td {
            padding: 10px 15px;
            vertical-align: middle;
        }

        .tnl-text-link {
	        padding: 0;
        }

        .tnl-table-count {
	        width: 24px;
	        padding-right: 4px;
	        padding-left: 4px;
            color: @COLOR-COS-DEFAULT;
            font-size: @COS-FONT-SIZE-DEFAULT;
	        text-align: right;
        }

        .tnl-left {
            text-align: left;
        }

        .tnl-right {
            text-align: right;
        }

        .tnl-center {
            text-align: center;
        }

        tr.tnl-table-delete a {
            color: #fff;
        }

        .tnl-delete-row:hover {
            cursor: pointer;
            background: @COLOR-DANGER;
        }

        th.tnl-toolbar,
        td.tnl-toolbar {
            margin: 0;
            padding: 0;
            width: 32px;
            text-align: center;
            background:@COLOR-TNL-GRAY3;
        }

        th.tnl-toolbar,
        th.tnl-toolbar:hover {
            background: @COLOR-TNL-GRAY3;
        }

        td.tnl-toolbar .tnl-icon-wrapper {
	        margin-top: 4px;
        }

        tr.tnl-table-delete,
        td.tnl-toolbar:hover {
            background: @COLOR-DANGER;
            color: #fff;
        }

        th.tnl-toolbar-empty:hover,
        td.tnl-toolbar-empty:hover {
            background: @COLOR-TNL-GRAY3;
            cursor: auto;
        }
        &.no-column-lines tbody > tr > td,
        &.no-column-lines > tr > td {
	        border-right: none;
        }

        &.header-column-lines tbody > tr > th,
        &.header-column-lines > tr > th {
	        border-right: 1px dashed @COLOR-TNL-GRAY3;
        }

        tr.sub-title {
	        font-weight: bold;
	        color: @COLOR-TNL-GRAY1;
	        .LESS-BACKGROUND;
        }

        tr.sub-title td {
            padding-top: 10px;
            padding-bottom: 10px;
	        border: none;
	        border-top: 1px solid @COLOR-TNL-GRAY3;
	        border-bottom: 1px solid @COLOR-TNL-GRAY5;
        }

        tr.sub-title:first-child td {
	        border-top: none;
        }

        .not-selected td,
        .not-selected td a {
            color: @COLOR-TNL-GRAY4;
        }

        .deselected,
        .deselected a,
        .deselected a:active,
        .deselected a:visited,
        .deselected a:hover {
	        color: @COLOR-TNL-GRAY4;
	        text-decoration: line-through;
        }

        .deselected .tnl-no-results,
        .deselected .tnl-no-results a,
        .deselected .tnl-no-results a:active,
        .deselected .tnl-no-results a:visited,
        .deselected .tnl-no-results a:hover {
            text-decoration: none;
        }

    }

    .tnl-table-actions {
	    clear: both;
	    margin: 20px 0;

        button,
        a {
            margin-right: 10px;
        }
    }

    #editCourse .tnl-table.simple td:nth-child(2),
    .app-body .tnl-table.simple td:nth-child(2) {
	    width:100%;
    }

    .tnl-table-header,
    .tnl-table-footer {
        width: 100%;
	    color: @COLOR-TNL-GRAY2;
        font-size: @COS-FONT-SIZE-DEFAULT;
        padding: 10px 0 20px;
    }

    .tnl-table-footer {
        padding: 0;
        font-size: 13px;
	    overflow: hidden;
    }

    .tnl-table-header {

        a.boot-btn,
        a.boot-btn:active {
            background: @COLOR-PM-WHITE1;
            border-radius: 3px 0 0 3px;
            overflow: hidden;
            border-right: 1px solid #a9a9a9;
            z-index: 2;

            .tnl-icon-wrapper {
                padding: 0 2px;
            }

            &:hover {
                background-color: @COLOR-PM-GREY3;
            }
        }

        .tnl-table-search {
	        float: right;
	        margin-right: 20px;

          > span {
            position: relative;
            top: 3px;
          }

          label,
          input {
            margin-right: 5px;
          }

          input {
            border-radius: 4px;
            border: 1px solid #a9a9a9;
            box-shadow: none !important;
            padding: 7px;

            &:focus {
              outline: none !important;
            }
          }
        }

        .tnl-table-filter-desc,
        .tnl-action-menu,
        .tnl-table-show   {
            float: right;
            margin: 0 4px 0 0;
        }

        .tnl-table-filter-desc span {
            margin: 0 2px;
            padding: 0 4px;
            border: 1px dashed @COLOR-TNL-GRAY4;
        }

        .tnl-table-filter,
        .tnl-table-filter-desc {
            float: left;
        }

        .tnl-table-filter {
	        position: relative;
	        clear: both;
	        width: 100%;
	        margin: 15px 0 0;
	        padding: 0 0 30px;
	        border: 1px solid @COLOR-PM-GREY4;
            overflow: hidden;
            .PM-ROUNDED-CORNERS();
        }

        .tnl-table-filter > div {
	        position: relative;
	        padding: 5px 10px;
	        color: @COLOR-TNL-GRAY2;
	        background: @COLOR-PM-WHITE1;
	        border-bottom: 1px solid @COLOR-PM-GREY4;
        }

        .tnl-table-filter select {
	        font-size: @COS-FONT-SIZE-DEFAULT;
        }

        .tnl-table-filter div label {
	        margin-right: 10px;
	        font-size: @COS-FONT-SIZE-DEFAULT;
            .COS-OPEN-SANS-REGULAR();
        }

        .tnl-table-filter span.apply {
	        position: absolute;
	        bottom: 0;
	        width: 100%;
	        text-align: center;
	        background: #fff;
          display: block;
          height: 30px;
        }

        .tnl-table-filter div img {
	        position: absolute;
	        top: 8px;
	        right: 10px;
	        cursor: pointer;
        }

        .btn-group > .boot-btn + .dropdown-toggle {
            margin-left: -3px;
            border-radius: 0 4px 4px 0;
            z-index: 1;

            &:hover {
                background-color: @COLOR-PM-GREY3;
            }

            &:active, &:focus {
                .tnl-icon-wrapper {
                    margin-left: -2px;
                }
            }
        }

        .tnl-button-menu {
          &.btn-group {
              margin-top: 0;
          }
        }

        .pm-grid-header-buttons {
          display: inline-block;
          position: relative;
          top: 1px;
        }

        .pm-my-saved-users {
          margin-top: 5px;

          .pm-pin-resize {
            margin-right: 5px;

            .pm-icon-sm {
              .pm-icon-font {
                top: 4px;
                color: @COLOR-PM-GREEN1;

                &:before {
                  font-size: 17px;
                }
              }
            }
          }
        }
    }

    html.lt-ie8 .tnl-table-header .tnl-table-search > span {
	    position: relative;
	    top: -1px;
    }

    .tnl-table-notes {
       margin-top: 15px;
       color: @COLOR-TNL-GRAY3;
       font-size: 14px;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        li {
           margin: 5px 0 0;
           padding: 0;
        }

    }

    html.lt-ie8 .tnl-table-header li.tnl-action-menu {
	    float: none;
    }

    .tnl-table-header .tnl-table-filter-desc,
    .tnl-table-show   {
        color: @COLOR-TNL-GRAY2;
    }

    .tnl-table-status {
        width: 40px;
        text-align: center;
    }

    .tnl-table-show {
        float: right;
    }
    td.tnl-table-footer {
        padding: 10px 0;
    }
    .tnl-table-show select {
	    padding: 5px;
        font-size: @COS-FONT-SIZE-DEFAULT;
        border-radius: 4px;

        &:focus {
            outline: none !important;
        }
    }

    .tnl-table-paginate {
        float: left;

        input {
            margin: 0 4px;
        }

        input.page {
            width: 20px;
            font-size: 14px;
            text-align: center;
        }
    }

    .tnl-table.simple {
	    border-collapse: separate;
	    border-spacing: 4px;
	    border: none;
	    .LESS-BOX-SHADOW-RESET();

         th,
         td {
	        border: none;
	        .LESS-BOX-SHADOW-RESET();
        }

        th {
	        color: @COLOR-TNL-GRAY3;
	        background: #fff;
	        border-bottom: 1px solid @COLOR-TNL-GRAY3;
        }

        tr.rowcolor1,
        tr.rowcolor2 {
	        background: #fff;
        }
    }

    .tnl-table.full-bleed,
    .full-bleed .tnl-table {
	    margin: 0;
	    border: none;
	    .LESS-BOX-SHADOW-RESET();
    }

    .tnl-table > tbody > tr > td.last-line,
    .tnl-table > tr > td.last-line {
	    border-bottom: 0;
    }

    .tnl-table-actions a {
	    margin-right: 10px;
    }

    table.tnl-table,
    table.tnl-table a {
	    font-size: 14px;
    }

    table.tnl-table {

        a,
        a > span {
            color: @COLOR-PM-LINK;
            .PM-TRANSITION();

            &:hover,
            &:hover > span {
                color: @COLOR-PM-LINK-H;
                text-decoration: none !important;
                .PM-TRANSITION();
            }

            &:active,
            &:active > span {
                color: @COLOR-PM-LINK-A;
                .PM-TRANSITION();
            }
        }
    }

    table.tnl-table.simple,
    table.tnl-table.simple td,
    table.tnl-table.simple th {
	    background: none;
    }

    table.tnl-table.simple {
	    margin: 0;
	    background: @COLOR-TNL-GRAY6;
	    border: 1px solid @COLOR-TNL-GRAY4;
        .PM-ROUNDED-CORNERS();
    }

    tr.subheader {
        background: @COLOR-TNL-GRAY3;
        padding: 3px;
        .COS-OPEN-SANS-600();
    }

    .tnlf-saved-user-icon > div.tnl-icon-wrapper > span {
        margin-right:5px;
        color: #000000;
        opacity: .2;
        display: inline-block;
    }

    .tnlf-saved-user-exists-icon > div.tnl-icon-wrapper > span {
        margin-right:5px;
        color: @COLOR-TNL-GREEN1;
        display: inline-block;
    }

    .tnlf-saved-user-anchor {
        display: inline-block;
    }

    .tnlf-saved-users-show {
        height:30px;
        top: 6px;
        position: relative;
    }

    .tnlf-saved-users-show-all {
        margin-right:5px;
        color: @COLOR-TNL-GREEN1;
    }

    @media screen and (max-width: @PM-BOOTSTRAP-SM) {
      .tnl-table-header {
        .pm-grid-button {
          width: 100%;
          margin-bottom: 15px;

          &.tnl-table-search {
            margin-right: 0 !important;
          }

          &:first-child {
            margin-top: 15px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .pm-my-saved-users,
        .pm-all-users {
          float: left !important;
        }
      }
    }
}

.modal-content {

    .pagination {
        float: left;
    }

    .tnl-table-show {
        float: right;
    }

}

.form-horizontal .form-group {
    padding-bottom: 10px;
}
