@import (reference) "_global.less";

.empari {

    
    .tnl-multi-selector {
        display: block;
        min-width: 900px;
        text-align: left;

        .tnl-multi-selector-left,
        .tnl-multi-selector-right {
            display: inline-block;
            vertical-align: top;
        }

        .tnl-multi-selector-left {
            text-align: right;
        }

        .tnl-multi-selector-right {
            text-align: left;
        }

        .tnl-multi-selector-left > div,
        .tnl-multi-selector-right > div {
            overflow: scroll;
            display: block;
            height: 450px;
            margin-top: 10px;
            padding: 10px;
            vertical-align: top;
            text-align: left;
            border: 1px solid @COLOR-TNL-GRAY4;
        }

        .tnl-multi-selector-items {
            display: block;
            margin: 0;
            padding: 0;
            list-style-type: none;

            > li  {
                position: relative;
                display: block;
            }
        }

        .tnl-multi-selector-item  {
            padding-bottom: 10px;
            .LESS-ELLIPSIS-ONE-LINE();

            > span  {
                display: block;
                position: absolute;
                top: 0;
                width: 50px;
            }
        }

        .tnl-multi-selector-left .tnl-multi-selector-item  {
            padding-right: 50px;
        }

        .tnl-multi-selector-right .tnl-multi-selector-item {
            padding-left: 50px;
        }

        .tnl-multi-selector-left .tnl-multi-selector-item > span  {
            right: 0;
            text-align: right;
        }

        .tnl-multi-selector-right .tnl-multi-selector-item > span {
            left: 0;
            text-align: left;
        }

    }

}